<template>
    <div v-if="content" class="streaming-preview">
        <div class="preview-header">
            Generating Documentation...
        </div>
        <div ref="previewContent" class="preview-content">
            ```markdown
            {{ content }}
            ```
        </div>
    </div>
</template>

<script>
export default {
    name: 'StreamingPreview',
    props: {
        content: {
            type: String,
            default: ''
        }
    },
    watch: {
        content: {
            handler() {
                this.$nextTick(() => {
                    if (this.$refs.previewContent) {
                        const container = this.$refs.previewContent;
                        // Smooth scroll to bottom
                        container.scrollTo({
                            top: container.scrollHeight,
                            behavior: 'smooth'
                        });
                    }
                });
            }
        }
    }
}
</script>

<style scoped>
.streaming-preview {
    background-color: #1C1C1C;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
}

.preview-header {
    padding: 0.75rem 1rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 0.875rem;
    font-weight: 500;
}

.preview-content {
    padding: 1rem;
    min-height: 200px;
    max-height: 600px;
    overflow-y: auto;
    color: #fff;
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    white-space: pre-wrap;
    word-break: break-word;
}
</style> 
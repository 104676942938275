import rehypeStringify from "rehype-stringify";
import remarkParse from "remark-parse";
import remarkRehype from "remark-rehype";
import { unified } from "unified";
import rehypeParse from "rehype-parse";
import rehypeRemark from "rehype-remark";
import remarkStringify from "remark-stringify";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { compile } from "@mdx-js/mdx";
import { visit } from "unist-util-visit";
import { Buffer } from "buffer";
import { checkAndParseHtmlElements } from "./htmlElementCheckers";

const divideContent = async(content) => {
  const frontmatterRegex = /---\s*\n([\s\S]*?)\n---/;
  const match = content.match(frontmatterRegex);

  if (!match) {
    try {
      let markdown = content.trim();
      let hasComponents = checkForReactOrOtherComponents(markdown);
      if (hasComponents) markdown = replaceReactOrOtherComponents(markdown);
      
      // Check for HTML elements
      console.log("About to call checkAndParseHtmlElements");
      const htmlElements = checkAndParseHtmlElements(markdown);
      console.log("yeah dude dude", htmlElements);
      
      if (htmlElements.details.length > 0) {
        // Handle details elements if found
 
        markdown = await processDetailsElements(markdown, htmlElements.details);
        console.log("markdown!!!!", markdown);
      }
    
      return {
        frontmatter: "",
        markdown: markdown,
      };
    } catch (error) {
      console.error("Error in divideContent:", error);
      console.error("Error stack:", error.stack);
      throw error;
    }
  }

  const frontmatter = match[1];
  let markdown = content.slice(match[0].length).trim();
  let hasComponents = checkForReactOrOtherComponents(markdown);
  if (hasComponents) markdown = replaceReactOrOtherComponents(markdown);
  
  // Check for HTML elements
  const htmlElements = checkAndParseHtmlElements(markdown);
  if (htmlElements.details.length > 0) {
    // Handle details elements if found
    console.log("htmlElements.details", htmlElements.details);
    markdown = await processDetailsElements(markdown, htmlElements.details);
    console.log("markdown!!!!", markdown);
  }
  
  return {
    frontmatter,
    markdown,
  };
};

export function checkForReactOrOtherComponents(content) {
  // Updated to handle only React components (capitalized)
  const componentRegex = /<([A-Z][a-zA-Z]*)((?:\s+[a-zA-Z]+(?:=(?:["'][^"']*["']|{[^}]*}))?)*)(?:\/\>|\>[\s\S]*?<\/\1>)/;
  return componentRegex.test(content);
}

export function replaceReactOrOtherComponents(content) {
  const codeBlockRegex = /```{1,4}[\s\S]*?```{1,4}/g;
  const codeBlocks = [...content.matchAll(codeBlockRegex)].map(match => ({
    start: match.index,
    end: match.index + match[0].length
  }));

  const isInCodeBlock = (position) => {
    return codeBlocks.some(block => 
      position >= block.start && position <= block.end
    );
  };

  // Updated regex to handle only React components (capitalized)
  const componentRegex = /<([A-Z][a-zA-Z]*)((?:\s+[a-zA-Z]+(?:=(?:["'][^"']*["']|{[^}]*}))?)*)(?:\/\>|\>([\s\S]*?)<\/\1>)/g;

  return content.replace(
    componentRegex,
    (match, componentName, propsString, children, offset) => {
      if (isInCodeBlock(offset)) {
        return match;
      }

      const props = {};
      const propsRegex = /([a-zA-Z]+)=(?:["']([^"']*)["']|{([^}]*)})/g;
      let propMatch;

      while ((propMatch = propsRegex.exec(propsString)) !== null) {
        const [, propName, quotedValue, bracketValue] = propMatch;
        props[propName] = quotedValue || bracketValue;
      }

      if (children && children.trim()) {
        props.children = children.trim();
      }

      let encodedComponent = Buffer.from(match).toString("base64");
      let encodedProps = Buffer.from(JSON.stringify(props)).toString("base64");
      
      return `<component name="${componentName}" original="${encodedComponent}" props="${encodedProps}"></component>`;
    }
  );
}

function remarkComponent() {
  return (tree) => {
    visit(tree, "html", (node) => {
      if (node.value.startsWith("<component")) {
        // Extract all component attributes
        const componentNameMatch = node.value.match(/name="([^"]*)"/);
        const componentMatch = node.value.match(/original="([^"]*)"/);
        const propsMatch = node.value.match(/props="([^"]*)"/);

        if (componentNameMatch || componentMatch || propsMatch) {
          node.type = "component";
          node.data = {
            hName: "component",
            hProperties: {
              name: componentNameMatch ? componentNameMatch[1] : null,
              original: componentMatch ? componentMatch[1] : null,
              props: propsMatch ? propsMatch[1] : '{}'
            },
          };
        }
      }
    });
  };
}

function remarkDetails() {
  return (tree) => {
    let currentDetailsNode = null;
    let accumulatedContent = '';

    visit(tree, "html", (node, index, parent) => {
      if (node.value.startsWith("<details")) {
        // Start accumulating content
        currentDetailsNode = node;
        accumulatedContent = node.value;
        
        // Look ahead for more nodes until we find the closing tag
        let nextIndex = index + 1;
        while (nextIndex < parent.children.length) {
          const nextNode = parent.children[nextIndex];
          
          // Handle different node types
          if (nextNode.type === 'html') {
            accumulatedContent += nextNode.value;
          } else if (nextNode.type === 'paragraph' && nextNode.children) {
            // For paragraph nodes, we need to process their children
            nextNode.children.forEach(child => {
              if (child.type === 'text') {
                accumulatedContent += child.value;
              } else if (child.type === 'html') {
                accumulatedContent += child.value;
              }
            });
          }
          
          if (nextNode.type === 'html' && nextNode.value.includes("</details>")) {
            // We found the complete details tag
            const detailsMatch = accumulatedContent.match(/<details>([\s\S]*?)<\/details>/);
            console.log("accumulatedContent", accumulatedContent);
            if (detailsMatch) {
              const detailsContent = detailsMatch[1];
              const summaryMatch = detailsContent.match(/<summary>([\s\S]*?)<\/summary>/);
              const contentMatch = detailsContent.replace(/<summary>[\s\S]*?<\/summary>/, '').trim();
              console.log("contentMatch", contentMatch);
              console.log("summaryMatch", summaryMatch);
              currentDetailsNode.type = "details";
              currentDetailsNode.data = {
                hName: "details",
                hProperties: {
                  summary: summaryMatch ? summaryMatch[1] : '',
                  content: contentMatch || ''
                },
              };

              // Remove the nodes we've processed
              parent.children.splice(index + 1, nextIndex - index);
            }
            break;
          }
          nextIndex++;
        }
      }
    });
  };
}

async function processDetailsElements(content, details) {
  let processedContent = content;
  
  for (const detail of details) {
    let htmlContent = await markdownToHtml(detail.content);
    processedContent = processedContent.replace(detail.content, htmlContent.content);
  }

  console.log("processedContent", processedContent);
  return processedContent;
}

export async function markdownToHtml(fileContent) {
  try {
    let { markdown, frontmatter } = await divideContent(fileContent);
    console.log("markdown", markdown);
    console.log("frontmatter", frontmatter);
    let file = await unified()
      .use(remarkParse) // Parse markdown content to a syntax tree
      .use(remarkGfm)
      .use(remarkComponent)
      .use(remarkDetails)
      .use(remarkRehype) // Turn markdown syntax tree to HTML syntax tree, ignoring embedded HTML
      .use(rehypeStringify) // Serialize HTML syntax tree
      .process(markdown);
    console.log("file", file);
    console.log("what is file", file);
    return { content: String(file), frontmatter };
  } catch (error) {
    console.error(error);
    return "";
  }
}

async function handleReactTipTapComponents(htmlContent) {
  console.log("htmlContent in handle react tiptap", htmlContent);
  const file = await unified()
    .use(rehypeParse, {
      fragment: true,
      space: "html",
    })
    .use(rehypeRemark, {
      handlers: {
        component: (h, node) => {
          console.log("node", node);
          let original = Buffer.from(
            node.properties.original,
            "base64"
          ).toString();
          console.log("this is the original", original);
          return {
            type: "html",
            value: original,
          };
        },
        // Add a catch-all handler for other HTML elements
        "*": (h, node) => {
          // Convert regular HTML to markdown without preserving raw HTML
          return h(node);
        }
      },
    })
    .use(remarkGfm)
    .use(remarkStringify, {
      fences: true,
    })
    .process(htmlContent);

  return String(file);
}

async function handleDetailsElements(htmlContent) {
  console.log("htmlContent in handle details", htmlContent);
  const file = await unified()
    .use(rehypeParse, {
      fragment: true,
      space: "html",
    })
    .use(rehypeRemark, {
      handlers: {
        details: (h, node) => {
          console.log("details node", node);
          // Handle details element
          return {
            type: "html",
            value: `<details><summary>${node.properties.summary}</summary>${node.properties.content}</details>`
          };
        },
        "*": (h, node) => {
          // Convert regular HTML to markdown while preserving code blocks and other formatting
          return h(node);
        }
      },
    })
    .use(remarkGfm)
    .use(remarkStringify, {
      fences: true,
    })
    .process(htmlContent);

  return String(file);
}

export async function htmlToMarkdown(htmlContent) {
  try {
    console.log("htmlContent", htmlContent);
    if (htmlContent.includes("<component")) {
      console.log("htmlContent", htmlContent);
      return handleReactTipTapComponents(htmlContent);
    }

    if (htmlContent.includes("<details")) {
      console.log("htmlContent", htmlContent);
      return handleDetailsElements(htmlContent);
    }
    const file = await unified()
    .use(rehypeParse)  // Parse HTML content to a syntax tree
    .use(rehypeRemark) // Turn HTML syntax tree to markdown syntax tree
    .use(remarkGfm)
    .use(remarkStringify) // Serialize markdown syntax tree
    .process(htmlContent);
    return String(file);
  } catch (error) {
    console.log(error);
    console.error(error);
    return "";
  }
}

<template>
  <node-view-wrapper @mouseover="hover = true" @mouseleave="hover = false" class="draggable-image-item">
    <div style="width: 100%; height: auto; margin-bottom: 0px" class="image-uploader" v-if="showUpload">
      <svg xmlns="http://www.w3.org/2000/svg" width="51" height="50" viewBox="0 0 51 50" fill="none">
        <path
          d="M5.63563 50C4.10786 50 2.80045 49.4565 1.71341 48.3694C0.626374 47.2824 0.08193 45.9741 0.0800781 44.4444V5.55555C0.0800781 4.02778 0.624523 2.72037 1.71341 1.63333C2.8023 0.546296 4.10971 0.00185185 5.63563 0H44.5245C46.0523 0 47.3606 0.544445 48.4495 1.63333C49.5384 2.72222 50.0819 4.02963 50.0801 5.55555V44.4444C50.0801 45.9722 49.5366 47.2806 48.4495 48.3694C47.3625 49.4583 46.0541 50.0018 44.5245 50H5.63563ZM5.63563 44.4444H44.5245V5.55555H5.63563V44.4444ZM8.41341 38.8889H41.7467L31.3301 25L22.9967 36.1111L16.7467 27.7778L8.41341 38.8889Z"
          fill="#A2A1A5" />
      </svg>

      <Tabs value="0">
        <TabList pt:root:style="background: none" pt:content:style="background: none"
          pt:tabList:style="background: none">
          <Tab value="0">Upload a new image</Tab>
          <Tab value="1">Use an existing image</Tab>
        </TabList>
        <TabPanels pt:root:style="background: none">
          <TabPanel value="0">
            <div class="image-uploader">
              <Message severity="info">
                <span>The image will be stored in {{ folderLocation }}/{{ newImageFilename }}</span><br></br>
                <span>In markdown, the image path will be <code>{{ displayedShorthand }}</code></span>
              </Message>
              <label class="p-card-subtitle">Folder location for the image</label>
              <InputText v-model.trim="folderLocation" placeholder="Image name" :invalid="validateFolder()" />
              <label class="p-card-subtitle">Where to start the markdown image url in the markdown</label>
              <InputText v-model.trim="markdownShorthand" placeholder="img" />
              <label class="p-card-subtitle">Name of your image</label>
              <InputText v-model.trim="newImageFilename" placeholder="Image name" :invalid="validateFilename()" />
              <FileUpload name="demo[]" pt:header:style="margin-bottom: 0px;" pt:content:style="margin-bottom: 0px;"
                :customUpload="true" choose-label="Add Image" accept="image/*" @uploader="customUploader" />

            </div>

          </TabPanel>
          <TabPanel value="1">
            <div class="image-uploader">
              <Message severity="info">
                <span>In the markdown the path to the image will start with {{ markdownShorthand }} and exclude everything after</span>
              </Message>

              <label class="p-card-subtitle">Where to start the markdown image url in the markdown</label>
              <InputText v-model.trim="markdownShorthand" placeholder="img" />
              <Button label="Select Existing Image" @click="showExistingImages = true" />
            </div>

          </TabPanel>

        </TabPanels>
      </Tabs>
    </div>
    <img v-else-if="imageSrc" class="c-image-holder" draggable="false" :style="styleString" loading="lazy"
      @error="retryImage" :src="imageSrc" />
    <Skeleton v-else-if="node?.attrs?.src" width="20rem" height="20rem" borderRadius="16px"></Skeleton>
    <Dialog v-model:visible="showExistingImages" header="Select Existing Image" :style="{ width: '50vw' }">
      <MultiSelect v-model="relevantImageFolders" :options="folderOptions" filter placeholder="find relevant images"
        :maxSelectedLabels="3" class="w-full md:w-80" />
      <div class="existing-images-grid">
        <div v-for="image in existingImages" :key="image" class="image-item">
          <img :src="image?.download_url" @click="showImagePreview(image)" class="selectable-image" />
        </div>
      </div>
    </Dialog>

    <!-- Add new preview dialog -->
    <Dialog v-model:visible="showPreview" :header="previewImage?.name || 'Image Preview'" :style="{ width: '80vw' }"
      :modal="true">
      <div class="preview-container">
        <img :src="previewImage?.download_url" class="preview-image" @click="selectExistingImage(previewImage)" />
        <Button label="Select This Image" class="select-button" @click="selectExistingImage(previewImage)" />
      </div>
    </Dialog>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from "@tiptap/vue-3";
import {
  getImageFiles,
  getFileContent,
  getOwnerAndRepo,
  getImageFileContent,
} from "@/plugins/devdocsBackendService";
import { authInfo } from "@/plugins/authn.js";
import { getImageContent } from "@/plugins/imageService";
import { load } from "js-yaml";
//   import ColorPickerDialog from '../ColorPickerDialog.vue';
//   import AddElementDialog from '../cardCssDrawer.vue';
//   import EditStylesBar from '../EditStylesBar.vue';.de//   import { handleImageSrc, replaceImageUrl, getOrCreateImage } from '@/helper_functions/imageHelper';
//   import { updateCssAttributes, getCssUpdateData } from '@/helper_functions/cssHelper';
//   import { handlePaste } from '@/helper_functions/imageHelper';

function replaceSpacesWithUnderscores(str) {
  return str.replace(/ /g, "_").toLowerCase();
}

function normalizeImageName(str) {
  let brokenUpString = str.split("./")
  return brokenUpString[brokenUpString.length - 1]
}

export default {
  components: {
    NodeViewWrapper,
  },
  computed: {
    displayedShorthand() {
      let imageTopicPath = replaceSpacesWithUnderscores(
          `${this.folderLocation}/${this.newImageFilename}.png`
        ).toLowerCase();
      let shortHandAppliedPath = `/${this.markdownShorthand}${imageTopicPath.split(this.markdownShorthand)[1]}`
      return shortHandAppliedPath
    },
  },
  data: () => ({
    imageSrc: null,
    hover: false,
    currentInteraction: false,
    colorPickerDialog: false,
    folderLocation: "static/img",
    markdownShorthand: "img",
    styleString: "",
    newImageFilename: "",
    loading: false,
    showUpload: false,
    uploadImageDialog: false,
    apiUrl: "",
    invalidFolder: false,
    invalidFilename: false,
    showExistingImages: false,
    existingImages: [],
    relevantImageFolders: [],
    folderOptions: [],
    likelyImageFolders: [],
    showPreview: false,
    previewImage: null,
  }),
  props: nodeViewProps,
  async mounted() {
    try {
      let src = this.node.attrs.src;
      if (!src) {
        return;
      }
      if (src == "upload") {
        this.showUpload = true;
        return;
      }
      let images = await getImageFiles({ branch: "main" });
    
      console.log("images", images)
      let realSrc = normalizeImageName(this.node.attrs.src)
      console.log("realSrc", realSrc)
      let mainImage = images.files.find((image) => {
        return image.includes(realSrc);
      });

      let { owner, repo } = await getOwnerAndRepo();
      let { content } = await getImageFileContent({
        branch: "main",
        fileName: mainImage,
        owner,
        repo,
      });

      // Create data URL

      this.imageSrc = content;
    } catch (error) {
      console.error("Error loading image:", error);
      // Fallback to raw GitHub URL
      //this.imageSrc = `https://raw.githubusercontent.com/team-dev-docs/devdocsprod-dev-docs/main/static${this.node.attrs.src}`;
    }
  },
  methods: {
    validateFolder() {
      return (
        this.folderLocation.trim() === "" || this.folderLocation === undefined
      );
    },
    async getImage(src) {
      let images = await getImageFiles({ branch: "main" });

      let mainImage = images.files.find((image) => {
        return image.includes(src);
      });
      let { owner, repo } = await getOwnerAndRepo();
      let { content } = await getImageFileContent({
        branch: "main",
        fileName: mainImage,
        owner,
        repo,
      });
      this.imageSrc = content;
      return;
    },
    validateFilename() {
      return (
        this.newImageFilename === undefined ||
        this.newImageFilename.trim() === ""
      );
    },
    async customUploader(event) {
      try {
        this.loading = true;
        let component = this;
        const file = event.files[0];
        const reader = new FileReader();
        let blob = await fetch(file.objectURL).then((r) => r.blob());

        reader.readAsDataURL(blob);

        reader.onloadend = async () => {
          const base64data = reader.result;
          // You can perform additional operations with the base64data here
          await component.uploadSuccess(base64data, file);
          component.loading = false;
        };
      } catch {
        this.loading = false;
      }
    },
    async uploadSuccess(base64data, file) {
      console.log("File:", file);
      await this.addImage(base64data);
    },
    async addImage(file) {
      try {
        this.showUpload = false;
        var myHeaders = new Headers();
        var token = await authInfo.getToken();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        let imageTopic = replaceSpacesWithUnderscores(
          `${this.newImageFilename}`
        ).toLowerCase();
        var raw = JSON.stringify({
          file,
          custom_root: this.folderLocation,
          id: imageTopic,
        });
        console.log(raw);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        const url = await authInfo.getBaseUrl();
        const result = await fetch(`${url}/external_images`, requestOptions);
        let imageTopicPath = `${this.folderLocation}/${imageTopic}.png`
        let shortHandAppliedPath = `/${this.markdownShorthand}${imageTopicPath.split(this.markdownShorthand)[1]}`
        this.updateAttributes({ src: shortHandAppliedPath || imageTopicPath });
        const json = await result.json();
        await this.getImage(shortHandAppliedPath || imageTopicPath);
        return json;
      } catch (e) {
        console.log(e);
      }
    },
    async getFolders() {
      var myHeaders = new Headers();
      try {
        var token = await this.$authInstance.getToken();

        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var response = await fetch(`${url}/folders`, requestOptions);
        var jsonResponse = await response.json();
        return jsonResponse;
      } catch (e) {
        this.folderOptions = [];
      }
    },
    async fetchExternalImages() {
      try {
        this.folderOptions = await this.getFolders();
        let imageNodes = new Set();
        this.editor.state.doc.descendants((node) => {
          if (node.type.name === "image" && node.attrs.src.includes("/")) {
            const basePath = node.attrs.src.substring(
              0,
              node.attrs.src.lastIndexOf("/")
            );
            imageNodes.add(basePath);
          }
        });
        let realImages = await getImageFiles({ branch: "main" }); //
        let likelyImageFolders = Array.from(imageNodes);
        likelyImageFolders = likelyImageFolders.map(function (folder) {
          let file = realImages.files.find((image) => {
            return image.includes(folder);
          });
          const basePath = file.substring(0, file.lastIndexOf("/"));
          return basePath;
        });
        this.relevantImageFolders = likelyImageFolders;
        //await this.getRelevantExistingImage(likelyImageFolders);
      } catch (error) {
        console.error("Error fetching external images:", error);
      }
    },
    async getRelevantExistingImage(likelyImageFolders) {
      try {
        console.log("what are the image nodes", likelyImageFolders);
        const baseUrl = await authInfo.getBaseUrl();
        const token = await authInfo.getToken();
        for (let likelyImageFolder of likelyImageFolders) {
          const response = await fetch(
            `${baseUrl}/external_images?path=${likelyImageFolder}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          console.log("what are the images", data.images);
          console.log("this is the existing images", this.existingImages);
          this.existingImages = [...this.existingImages, ...data.images];
        }
      } catch (e) { }
    },
    showImagePreview(image) {
      this.previewImage = image;
      this.showPreview = true;
    },
    async selectExistingImage(imagePath) {
      console.log("this is image path", imagePath);
      this.showPreview = false;
      this.showExistingImages = false;
      this.showUpload = false;
      let shortHandAppliedPath = `/${this.markdownShorthand}${imagePath.path.split(this.markdownShorthand)[1]}`
      this.updateAttributes({ src: shortHandAppliedPath || imagePath.path });
      await this.getImage(shortHandAppliedPath || imagePath.path);
    },
  },
  watch: {
    async relevantImageFolders(newVal) {
      this.existingImages = [];
      await this.getRelevantExistingImage(newVal);
    },
    showUpload(newVal) {
      if (newVal) {
        this.fetchExternalImages();
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.image-uploader),
:deep(.image-uploader *) {
  margin-bottom: 0 !important;
}

/* If you have specific elements that need margin adjustments */
:deep(.image-preview) {
  margin: 10px 0;
  /* Custom margins if needed */
}

.draggable-image-item {
  border-radius: 0.5rem;
  display: flex;
  background: transparent;
  align-items: center;
  position: relative;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;

  .image-handle {
    position: absolute;
    top: 0;
    left: 0;

    /* Remove unnecessary styles */
    flex: none;
    width: auto;
    height: auto;
  }

  .glassmorph {
    background: radial-gradient(100% 359.18% at 0% 0%,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0.2) 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 20px;
    min-width: 100%;
    min-height: 100%;
    padding: 0.5rem;
  }

  .content {
    flex: 1 1 auto;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}

.settings {
  cursor: grab;
  color: white;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="#AB7C94" d="M600.704 64a32 32 0 0 1 30.464 22.208l35.2 109.376c14.784 7.232 28.928 15.36 42.432 24.512l112.384-24.192a32 32 0 0 1 34.432 15.36L944.32 364.8a32 32 0 0 1-4.032 37.504l-77.12 85.12a357.12 357.12 0 0 1 0 49.024l77.12 85.248a32 32 0 0 1 4.032 37.504l-88.704 153.6a32 32 0 0 1-34.432 15.296L708.8 803.904c-13.44 9.088-27.648 17.28-42.368 24.512l-35.264 109.376A32 32 0 0 1 600.704 960H423.296a32 32 0 0 1-30.464-22.208L357.696 828.48a351.616 351.616 0 0 1-42.56-24.64l-112.32 24.256a32 32 0 0 1-34.432-15.36L79.68 659.2a32 32 0 0 1 4.032-37.504l77.12-85.248a357.12 357.12 0 0 1 0-48.896l-77.12-85.248A32 32 0 0 1 79.68 364.8l88.704-153.6a32 32 0 0 1 34.432-15.296l112.32 24.256c13.568-9.152 27.776-17.408 42.56-24.64l35.2-109.312A32 32 0 0 1 423.232 64H600.64zm-23.424 64H446.72l-36.352 113.088-24.512 11.968a294.113 294.113 0 0 0-34.816 20.096l-22.656 15.36-116.224-25.088-65.28 113.152 79.68 88.192-1.92 27.136a293.12 293.12 0 0 0 0 40.192l1.92 27.136-79.808 88.192 65.344 113.152 116.224-25.024 22.656 15.296a294.113 294.113 0 0 0 34.816 20.096l24.512 11.968L446.72 896h130.688l36.48-113.152 24.448-11.904a288.282 288.282 0 0 0 34.752-20.096l22.592-15.296 116.288 25.024 65.28-113.152-79.744-88.192 1.92-27.136a293.12 293.12 0 0 0 0-40.256l-1.92-27.136 79.808-88.128-65.344-113.152-116.288 24.96-22.592-15.232a287.616 287.616 0 0 0-34.752-20.096l-24.448-11.904L577.344 128zM512 320a192 192 0 1 1 0 384 192 192 0 0 1 0-384zm0 64a128 128 0 1 0 0 256 128 128 0 0 0 0-256z"></path></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 0.5rem;
  background-blend-mode: difference;
}

.chat {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill-opacity="0.2"  d="m174.72 855.68 130.048-43.392 23.424 11.392C382.4 849.984 444.352 864 512 864c223.744 0 384-159.872 384-352 0-192.832-159.104-352-384-352S128 319.168 128 512a341.12 341.12 0 0 0 69.248 204.288l21.632 28.8-44.16 110.528zm-45.248 82.56A32 32 0 0 1 89.6 896l56.512-141.248A405.12 405.12 0 0 1 64 512C64 299.904 235.648 96 512 96s448 203.904 448 416-173.44 416-448 416c-79.68 0-150.848-17.152-211.712-46.72l-170.88 56.96z"></path></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 0.5rem;
  background-blend-mode: difference;
}

.trash {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M160 256H96a32 32 0 0 1 0-64h256V95.936a32 32 0 0 1 32-32h256a32 32 0 0 1 32 32V192h256a32 32 0 1 1 0 64h-64v672a32 32 0 0 1-32 32H192a32 32 0 0 1-32-32V256zm448-64v-64H416v64h192zM224 896h576V256H224v640zm192-128a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32zm192 0a32 32 0 0 1-32-32V416a32 32 0 0 1 64 0v320a32 32 0 0 1-32 32z"></path></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 0.5rem;
  background-blend-mode: difference;
}

.copy {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M768 832a128 128 0 0 1-128 128H192A128 128 0 0 1 64 832V384a128 128 0 0 1 128-128v64a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64h64z"></path><path fill="currentColor" d="M384 128a64 64 0 0 0-64 64v448a64 64 0 0 0 64 64h448a64 64 0 0 0 64-64V192a64 64 0 0 0-64-64H384zm0-64h448a128 128 0 0 1 128 128v448a128 128 0 0 1-128 128H384a128 128 0 0 1-128-128V192A128 128 0 0 1 384 64z"></path></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 0.5rem;
  background-blend-mode: difference;
}

.drag-handle {
  flex: 0 0 auto;
  position: relative;
  top: 0.3rem;
  margin-right: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path style="mix-blend-mode: difference;" fill-opacity="0.2" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-blend-mode: difference;
}

.lock {
  flex: 0 0 auto;
  position: relative;
  width: 1rem;
  height: 1rem;
  top: 0.3rem;
  margin-right: 0.5rem;
  cursor: grab;
  background-image: url('data:image/svg+xml;charset=UTF-8, <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-ea893728=""><path fill="currentColor" d="M224 448a32 32 0 0 0-32 32v384a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V480a32 32 0 0 0-32-32H224zm0-64h576a96 96 0 0 1 96 96v384a96 96 0 0 1-96 96H224a96 96 0 0 1-96-96V480a96 96 0 0 1 96-96z"></path><path fill="currentColor" d="M512 544a32 32 0 0 1 32 32v192a32 32 0 1 1-64 0V576a32 32 0 0 1 32-32zm192-160v-64a192 192 0 1 0-384 0v64h384zM512 64a256 256 0 0 1 256 256v128H256V320A256 256 0 0 1 512 64z"></path></svg>');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.c-image-holder {
  width: 100%;
  height: 100%;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

:deep(.draggable-image-item *) {
  margin-bottom: 0px;
}

.image-uploader {
  display: flex;
  padding: 0.4375rem 0.625rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .p-fileupload-advanced {
    margin-bottom: 0px;
  }

  .p-button {
    margin-bottom: 0px;
  }
}

.existing-images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.image-item {
  aspect-ratio: 1;
  overflow: hidden;
  border-radius: 8px;
  border: 2px solid transparent;
  transition: all 0.2s ease;

  &:hover {
    border-color: var(--primary-color);
    transform: scale(1.02);
    cursor: zoom-in;
  }
}

.selectable-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

.preview-image {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
  border-radius: 8px;
}

.select-button {
  width: fit-content;
}
</style>

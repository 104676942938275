<template>
  <div style="background-color: black;" class="dark flex flex-col items-center min-h-screen p-4">
    
    
  
  <div style="border-bottom: 1px solid rgba(122, 123, 128, 0.20);" class="flex flex-col mt-4 w-full items-center">
    <div class="p-4" style="background-color: black; width: 70%; border: 1px solid rgba(122, 123, 128, 0.20); border-top: none; border-bottom: none;">
      <h1>Generate Docs</h1>
    </div>
  </div>
  
  
  <div class="grid-holder dark flex flex-col items-center p-1 overflow-y-auto" style="background-color: black; width: 70%; border: 1px solid rgba(122, 123, 128, 0.20); border-top: none; border-bottom: none; max-height: calc(100vh - 120px);"
  :style="{ backgroundImage: `url(${floatingGrid})` }" >


  <div
      v-if="!loading && branch"
      style="background: none;"
      class="w-full max-w-3xl mb-6 bg-white rounded-lg shadow p-6"
    >


      <Card class="dark pr-card">
        <template #content>
          <div class="flex flex-col items-start gap-[1rem]">
            <h2>PR #{{ prNumber }}: {{ prTitle }}</h2>
          <label class="p-card-subtitle">{{ prBody || "No description found" }}</label>
          <Button as="a" :href="`https://github.com/${owner}/${repository}/pull/${prNumber}`"
            class="mb-4 pr-github-button"
            target="_blank" text>View on GitHub</Button> 
          </div>
 
        </template>
      </Card>
      <PrGenerateDoc
      v-if="!loading && branch && files?.length > 0"
      :org="organization"
      :branch="branch"
      :repo="repository"
      :owner="owner"
      :prInfo="{
        title: prTitle,
        body: prBody,
        number: prNumber
      }"
      :files="files"
      :pre-selected-files="preSelectedFiles"
    />
    </div>


    <div v-else class="w-full max-w-3xl p-4">
      <div class="animate-pulse space-y-4">
        <div class="h-4 bg-gray-200 rounded w-3/4"></div>
        <div class="space-y-3">
          <div class="h-4 bg-gray-200 rounded"></div>
          <div class="h-4 bg-gray-200 rounded w-5/6"></div>
        </div>
      </div>
    </div>




  </div>


  </div>
</template>

<script setup>
import PrGenerateDoc from "@/components/PrGenerateDoc.vue";
import floatingGrid from "@/assets/floating-grid.png";

import { getPullRequest } from "@/plugins/devdocsBackendService";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import router from "@/router";
import { parse } from "vue/compiler-sfc";

let owner = ref("");
let repository = ref("");
let branch = ref("");
let organization = ref("");
let loading = ref(true);
let prTitle = ref("");
let prNumber = ref("");
let prBody = ref("");
let preSelectedFiles = ref({});
let files = ref([]);

let route = useRoute();
onMounted(async () => {
  try {
    const { org, pull_number, repo, prompts } = checkLocalStorageForQueryParams(route.query)
    let gitHubRepo = decodeURIComponent(repo);
    let [ownerName, repoName] = gitHubRepo.split("/");
    owner.value = ownerName;
    repository.value = repoName;
    const pullRequestData = await getPullRequest({
      repo,
      org,
      pr: pull_number,
    });
    organization.value = org;
    branch.value = pullRequestData.branch;
    prTitle.value = pullRequestData.title;
    prBody.value = pullRequestData.body;
    prNumber.value = pull_number;
    files.value = pullRequestData.files;
    preSelectedFiles.value =  [{ key: 'Documentation::parent::index.js', checked: true, partialChecked: false, label: 'index.js', children: [] }]
    
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
});

const checkLocalStorageForQueryParams = ({ org, pull_number, repo, prompts }) => {
  if(org || pull_number || repo) return { org, pull_number, repo, prompts }
  else {
    let queryParams = localStorage.getItem("login_query_params")
    if(queryParams) {
      let pasrsedQueryParams = JSON.parse(queryParams)
      router.replace({ query: pasrsedQueryParams })
      let { org, pull_number, repo, prompts } = pasrsedQueryParams
      return { org, pull_number, repo, prompts }
    }
  }
}
</script>

<style scoped>
.pr-card {
display: flex;
padding: 3.125rem;
flex-direction: column;
justify-content: center;
align-items: start;
gap: 1.25rem;
align-self: stretch;
border-radius: 0.75rem;
border: 1px solid rgba(122, 123, 128, 0.10);

background: rgba(28, 28, 28, 0.65);

backdrop-filter: blur(12.5px);
}

.pr-github-button {
  border-radius: 0.5rem;
  border: 0.5px solid rgba(162, 161, 165, 0.75);
}

.grid-holder {
  scrollbar-width: thin;
  scrollbar-color: rgba(122, 123, 128, 0.20) transparent;
}

.grid-holder::-webkit-scrollbar {
  width: 8px;
}

.grid-holder::-webkit-scrollbar-track {
  background: transparent;
}

.grid-holder::-webkit-scrollbar-thumb {
  background-color: rgba(122, 123, 128, 0.20);
  border-radius: 4px;
}
</style>
<template>
    <div
        class="flex flex-col items-start p-[0.94rem] bg-[#1C1C1C] rounded-[0.5rem] gap-[0.31rem]"
        ref="linkDropdownContainer"
    >
        <div
            class="text-[#A2A1A5] font-medium"
        >
            URL
        </div>

        <InputText
            :value="editor.getAttributes('link').href || ''"
            @input="updateLink($event.target.value)"
            class="w-full bg-[#2C2C2C] border-[#3C3C3C] text-[#A2A1A5]"
            placeholder="Enter URL"
        />

        <div
            class="text-[#A2A1A5] font-medium"
        >
            Text to display
        </div>

        <InputText
            :value="editor.state.selection.empty ? '' : editor.state.doc.textBetween(editor.state.selection.from, editor.state.selection.to)"
            @input="updateText($event.target.value)"
            class="w-full bg-[#2C2C2C] border-[#3C3C3C] text-[#A2A1A5]"
            placeholder="Enter display text"
        />

    </div>
</template>

<script setup>
import { ref, onMounted, onUpdated } from 'vue';

const props = defineProps({
    editor: {
        type: Object,
        required: true
    }
})

const editor = ref(props.editor);
const linkDropdownContainer = ref(null);

const updateLink = (href) => {
    if (!href) {
        props.editor.chain()
            .unsetLink()
            .run();
        return;
    }
    props.editor.chain()
        .setLink({ href })
        .run();
}

const updateText = (text) => {
    if (text && !props.editor.state.selection.empty) {
        props.editor.chain()
            .insertContent(text)
            .run();
    }
}

// Function to adjust position if needed
const adjustPosition = () => {
    if (!linkDropdownContainer.value) return;
    
    const container = linkDropdownContainer.value;
    const rect = container.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    // Check if dropdown extends beyond right edge
    if (rect.right > viewportWidth) {
        container.style.left = 'auto';
        container.style.right = '0';
    }
    
    // Check if dropdown extends beyond bottom edge
    if (rect.bottom > viewportHeight) {
        container.style.top = 'auto';
        container.style.bottom = '100%';
        container.style.marginTop = '0';
        container.style.marginBottom = '1px';
    }
};

onMounted(() => {
    adjustPosition();
});

onUpdated(() => {
    adjustPosition();
});
</script>
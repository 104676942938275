<template>
  <div v-if="documents.length > 0" class="flex flex-col gap-4 mini-editor-container">
    <Toast />
    <div class="flex justify-between items-center mb-4">
      <div class="flex items-center gap-4">
        <span class="p-card-subtitle text-sm"
          >{{ documents.length }} document{{
            documents.length > 1 ? "s" : ""
          }}
          generated</span
        >
        <div v-if="hasCachedContent" class="text-sm flex items-center gap-2">
          <Button
            label="Clear"
            icon="pi pi-trash"
            @click="clearCachedContent"
            class="p-button-sm p-card-subtitle p-button-text p-button-danger"
          />
        </div>
      </div>
      <div class="flex items-center gap-4">
        <div class="flex items-center gap-2">
          <span class="text-sm">Auto Create:</span>
          <ToggleButton v-model="autoCreate" onLabel="On" offLabel="Off" />
        </div>
        <Button
          label="Accept All Without Review"
          icon="pi pi-check-circle"
          @click="handleAcceptAll"
          :loading="isAcceptingAll"
          class="p-button-sm"
        />
      </div>
    </div>
    <div class="flex">
      <div class="w-[300px]rounded-lg p-4 h-full">
        <div class="text-white/70 text-sm mb-2 px-2">Documents</div>
        <Menu
          style="max-width: 200px"
          :model="menuItems"
        >
          <template #item="{ item, props }">
            <div class="flex justify-between items-center w-full">
              <span @click="props.command" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap; max-width: 220px;" class="w-full">
                {{ item.label }}
              </span>
              <Button
                icon="pi pi-trash"
                class="p-button-text p-button-rounded p-button-sm"
                @click="removeDocument(item.label)"
              />
            </div>
          </template>
        </Menu>
      </div>
      <div class="ml-6 flex-1">
        <StreamedMiniEditor
          v-if="activeDocument"
          :initial-content="activeDocument.content"
          :file-name="activeDocument.fileName || 'temp.md'"
          :branch="branch"
          :owner="owner"
          :repo="repo"
          :auto-save="false"
          :title="'Generated Documentation'"
          class="bg-[#1C1C1C] rounded-lg min-h-[200px]"
          @accept="handleAccept(activeDocument)"
          @revert="handleRevert(activeDocument)"
          @discard="handleDiscard(activeDocument)"
        />
      </div>
    </div>
    <Dialog
      v-model:visible="showCachedDialog"
      header="Previous Results"
      :modal="true"
      class="w-[80vw]"
    >
      <div class="flex flex-col gap-4">
        <div
          v-for="(cache, index) in cachedResults"
          :key="index"
          class="p-4 bg-[#1C1C1C] rounded-lg"
        >
          <div class="flex justify-between items-center mb-2">
            <span class="text-white/70">{{
              new Date(cache.timestamp).toLocaleString()
            }}</span>
            <Button
              label="Load"
              icon="pi pi-replay"
              @click="loadCachedContent(cache)"
              class="p-button-sm"
            />
          </div>
          <div class="text-white/50 text-sm">{{ cache.result.path }}</div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import StreamedMiniEditor from "./StreamedMiniEditor.vue";
import ToggleButton from "primevue/togglebutton";
import {
  createFileContent,
  updateFileContent,
} from "../../plugins/devdocsBackendService";
import indexDbService from "../../plugins/indexDbService";
import Tooltip from "primevue/tooltip";

export default {
  name: "DocumentCarousel",
  components: {
    Menubar,
    StreamedMiniEditor,
    Toast,
    Button,
    Dialog,
    ToggleButton,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    branch: {
      type: String,
      default: "",
    },
    owner: {
      type: String,
      default: "",
    },
    repo: {
      type: String,
      default: "",
    },
    action: {
      type: String,
      required: true,
      validator: (value) =>
        [
          "code_objects_docs",
          "audit_content",
          "analyze_docs",
          "generate_complete_docs",
          "generate_content",
        ].includes(value),
    },
  },
  data() {
    return {
      activeDocumentIndex: 0,
      isAcceptingAll: false,
      hasCachedContent: false,
      showCachedDialog: false,
      cachedResults: [],
      autoCreate: false,
      processedDocuments: [],
      hasEmittedAcceptEvents: false,
    };
  },
  computed: {
    menuItems() {
      return this.documents.map((doc, index) => {
        const fileName = doc.fileName || `Document ${index + 1}`;
        return {
          label: fileName,
          icon: "pi pi-file",
          class: this.activeDocumentIndex === index ? "active-document" : "",
          command: () => {
            this.activeDocumentIndex = index;
          },
        };
      });
    },
    activeDocument() {
      return this.documents[this.activeDocumentIndex];
    },
  },
  async mounted() {
    if (this.documents.length > 0) {
      this.activeDocumentIndex = 0;
    }
    await this.checkCachedContent();
    
    // Initialize autoCreate from localStorage with this.action
    this.autoCreate = localStorage.getItem(`${this.action}_auto_create`) === 'true';
    
    // Reset processed documents
    this.resetProcessedDocuments();
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  emits: ["accept", "revert", "discard", "load-cached", "update:documents"],
  watch: {
    autoCreate(newValue) {
      localStorage.setItem(`${this.action}_auto_create`, String(newValue));
      
      // If auto-create is enabled, process any complete documents that haven't been processed yet
      if (newValue === true) {
        this.processCompleteDocuments();
      }
    },
    'documents.length': function() {
      // Reset processed documents when the documents array changes in length
      // This ensures we handle new sets of documents correctly
      this.processedDocuments = [];
    },
    documents: {
      handler: async function (newDocs, oldDocs) {
        // Reset processed documents if it's a totally different set of documents
        // This check helps identify when we've generated a new set of documents
        if (oldDocs && newDocs && this.isNewDocumentSet(newDocs, oldDocs)) {
          this.processedDocuments = [];
        }
        
        // Check if all documents are complete (have fileName and content)
        const allComplete = newDocs.every((doc) => doc.fileName && doc.content);
        if (allComplete && newDocs.length > 0) {
          try {
            // Save the complete set of documents to IndexDB
            await indexDbService.saveTableData(`${this.action}_cached_docs`, {
              timestamp: new Date().toISOString(),
              result: newDocs.length === 1 ? newDocs[0] : newDocs,
              context: {
                branch: this.branch,
                owner: this.owner,
                repo: this.repo,
              },
            });
            console.log("Saved completed documents to IndexDB");
            
            // Process any complete documents if auto-create is enabled
            if (this.autoCreate) {
              this.processCompleteDocuments();
            }
          } catch (e) {
            console.error("Error saving documents to cache:", e);
          }
        } else if (this.autoCreate) {
          // Process individual documents that are complete
          this.processCompleteDocuments();
        }
      },
      deep: true,
    },
  },
  methods: {
    async removeDocument(label) {
      try {
        const filteredDocs = this.documents.filter(
          (doc) => doc.fileName !== label
        );
        if (filteredDocs?.length <= 0) {
          indexDbService.clearTable(`${this.action}_cached_docs`);
        } else {
          indexDbService.saveTableData(`${this.action}_cached_docs`, {
            timestamp: new Date().toISOString(),
            result: filteredDocs,
            context: {
              branch: this.branch,
              owner: this.owner,
              repo: this.repo,
            },
          });
        }
        this.$emit("update:documents", filteredDocs);
      } catch (e) {
        console.error("Error removing document:", e);
      }
    },
    async checkCachedContent() {
      try {
        const cachedData = await indexDbService.getTableData(
          `${this.action}_cached_docs`
        );
        if (cachedData) {
          this.hasCachedContent = true;
          this.cachedResults = Array.isArray(cachedData)
            ? cachedData
            : [cachedData];
        }
      } catch (e) {
        console.warn("Error checking cached content:", e);
      }
    },
    async clearCachedContent() {
      try {
        await indexDbService.clearTable(`${this.action}_cached_docs`);
        this.hasCachedContent = false;
        this.cachedResults = [];
        this.$emit("clear-cached");
        this.toast.add({
          severity: "success",
          summary: "Success",
          detail: "Cleared completely",
          life: 3000,
        });
      } catch (e) {
        console.error("Error clearing cached content:", e);
        this.toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to clear data",
          life: 3000,
        });
      }
    },
    showCachedContent() {
      this.showCachedDialog = true;
    },
    async loadCachedContent(cache) {
      try {
        // Emit the cached content to be loaded
        this.$emit("load-cached", cache);
        this.showCachedDialog = false;
      } catch (e) {
        this.toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to load cached content",
          life: 3000,
        });
      }
    },
    async handleAcceptAll() {
      this.isAcceptingAll = true;
      let draftBranch = localStorage.getItem("draftBranch") || "main";

      const successful = [];
      const failed = [];

      try {
        for (const doc of this.documents) {
          try {
            await updateFileContent({
              branch: draftBranch,
              fileName: doc.fileName,
              owner: this.owner,
              repo: this.repo,
              content: doc.content,
            });

            successful.push(doc);
            
            // Mark as processed for auto-create tracking
            if (!this.processedDocuments.includes(doc.fileName)) {
              this.processedDocuments.push(doc.fileName);
            }

            // Add to localStorage instead of emitting for each doc
            const existingFiles = JSON.parse(
              localStorage.getItem("newFiles") || "[]"
            );
            if (!existingFiles.includes(doc.fileName)) {
              existingFiles.push(doc.fileName);
              localStorage.setItem("newFiles", JSON.stringify(existingFiles));
            }
          } catch (error) {
            console.error(`Error processing ${doc.fileName}:`, error);
            failed.push({ doc, error: error.message });
          }
        }

        if (successful.length > 0) {
          this.toast.add({
            severity: "success",
            summary: "Success",
            detail: `Successfully saved ${successful.length} document${
              successful.length > 1 ? "s" : ""
            }`,
            life: 3000,
          });

          successful.forEach((doc) => {
            const result = {
              content: doc.content,
              path: doc.fileName,
              branch: draftBranch,
            };
            this.$emit("accept", result);
          });
          
          // Mark that we've emitted accept events
          this.hasEmittedAcceptEvents = true;
        }

        if (failed.length > 0) {
          const failedFiles = failed.map((f) => f.doc.fileName).join(", ");
          this.toast.add({
            severity: "error",
            summary: "Error",
            detail: `Failed to save: ${failedFiles}`,
            life: 5000,
          });
        }

        this.streamingResponses = [];
      } catch (error) {
        console.error("Error in accept all:", error);
        this.toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to process documents: " + error.message,
          life: 3000,
        });
      } finally {
        this.isAcceptingAll = false;
      }
    },
    async handleAccept(document) {
      try {
        this.toast.add({
          severity: "success",
          summary: "Document Accepted",
          detail: `${document.fileName || "Document"} has been accepted`,
          life: 3000,
        });

        const index = this.documents.indexOf(document);
        if (index > -1) {
          this.$emit("accept", document);
          if (
            this.activeDocumentIndex === this.documents.length - 1 &&
            this.activeDocumentIndex > 0
          ) {
            this.activeDocumentIndex--;
          }
        }
      } catch (e) {
        console.error("Error accepting document:", e);
        this.$emit("accept", document);
      }
    },
    handleRevert(document) {
      this.$emit("revert", document);
    },
    handleDiscard(document) {
      this.$emit("discard", document);
    },
    // Process individual complete documents for auto-creation
    async processCompleteDocuments() {
      if (!this.autoCreate || this.hasEmittedAcceptEvents) return;
      
      const draftBranch = localStorage.getItem("draftBranch") || "main";
      const successful = [];
      const failed = [];
      
      for (const doc of this.documents) {
        // Skip documents that don't have both fileName and content or have already been processed
        if (!doc.fileName || !doc.content || this.processedDocuments.includes(doc.fileName)) {
          continue;
        }
        
        try {
          await updateFileContent({
            branch: draftBranch,
            fileName: doc.fileName,
            owner: this.owner,
            repo: this.repo,
            content: doc.content,
          });
          
          // Add to processedDocuments to avoid processing again
          this.processedDocuments.push(doc.fileName);
          
          // Add to localStorage for tracking
          const existingFiles = JSON.parse(localStorage.getItem("newFiles") || "[]");
          if (!existingFiles.includes(doc.fileName)) {
            existingFiles.push(doc.fileName);
            localStorage.setItem("newFiles", JSON.stringify(existingFiles));
          }
          
          // Add to successful array instead of emitting immediately
          successful.push({
            content: doc.content,
            path: doc.fileName,
            branch: draftBranch,
            originalDoc: doc,
          });
          
        } catch (error) {
          console.error(`Error auto-creating ${doc.fileName}:`, error);
          failed.push({ fileName: doc.fileName, error: error.message });
        }
      }
      
      // Show success toasts after all processing is done
      if (successful.length > 0) {
        this.toast.add({
          severity: "success",
          summary: "Auto-Create Success",
          detail: `Successfully created ${successful.length} document${
            successful.length > 1 ? "s" : ""
          }`,
          life: 3000,
        });
      }
      
      // Show errors after all processing is done
      if (failed.length > 0) {
        const failedFiles = failed.map(f => f.fileName).join(", ");
        this.toast.add({
          severity: "error",
          summary: "Auto-Create Failed",
          detail: `Failed to create: ${failedFiles}`,
          life: 5000,
        });
      }
      
      // Emit accept events for all successful documents at once
      // This will only happen after all documents are processed
      if (successful.length > 0) {
        // Mark that we've emitted accept events to prevent duplicate processing
        this.hasEmittedAcceptEvents = true;
        
        successful.forEach(result => {
          this.$emit("accept", result.originalDoc);
        });
      }
    },
    // Helper method to determine if it's a new set of documents
    isNewDocumentSet(newDocs, oldDocs) {
      // Simple heuristic: if more than half the filenames have changed, consider it a new set
      if (!oldDocs || oldDocs.length === 0) return true;
      
      const oldFileNames = oldDocs.map(doc => doc.fileName).filter(Boolean);
      const newFileNames = newDocs.map(doc => doc.fileName).filter(Boolean);
      
      // If we don't have filenames to compare yet, check content similarity
      if (oldFileNames.length === 0 || newFileNames.length === 0) return false;
      
      const commonFiles = newFileNames.filter(name => oldFileNames.includes(name));
      // If less than half the files are common, it's likely a new set
      return commonFiles.length < newFileNames.length / 2;
    },
    // Reset processed documents tracking
    resetProcessedDocuments() {
      this.processedDocuments = [];
      this.hasEmittedAcceptEvents = false;
    },
  },
};
</script>


<template>
  <div class="container">
    <!-- Header -->
    <header class="header">
      <div class="logo">
        <span class="logo-text">
          <img src="@/assets/docsdev.png" alt="Docs.dev Logo" />
        </span>
      </div>
      <div class="header-buttons">
        <template v-if="userData">
          <div class="user-profile">
            <img :src="userData.avatar_url" alt="User Avatar" class="user-avatar" />
            <button class="btn-logout" @click="logout">Logout</button>
          </div>
        </template>
        <template v-else>
          <a href="https://learn.docs.dev" target="_blank" class="btn-get-started" @click="openSignInDialog">
            Get started
          </a>
          <button class="btn-login" @click="openSignInDialog">Login</button>
        </template>
      </div>
    </header>

    <!-- Dynamic Layout Container -->
    <div
      :class="[
        'dynamic-layout',
        { 'side-by-side': loading || streams.length > 0 },
      ]"
    >
      <!-- Main Content -->
      <main
        :class="[
          'main-content',
          { 'with-sidebar': loading || streams.length > 0 },
        ]"
      >
        <div class="illustration-container">
          <img
            src="@/assets/conversion.png"
            alt="AI Assistant Illustration"
            class="illustration-img"
          />
        </div>

        <h1 class="title">Your docs AI assistant</h1>
        <p class="subtitle">Generate docs directly from your codebase.</p>

        <div class="repo-input-container">
          <div
            class="mb-2"
            style="
              display: flex;
              align-items: center;
              gap: 0.5rem;
              width: 100%;
              justify-content: flex-start;
            "
          >
            <img src="@/assets/icon-github.svg" alt="GitHub" />
            <label>Your GitHub repository</label>
          </div>
          <InputGroup>
            <InputText
              v-model="githubRepo"
              placeholder="Enter GitHub repository (username/repo)"
              class="repo-input"
              @update:modelValue="saveGithubRepo"
            />
            <InputGroupAddon>
              <Button
                @click="setQueryAndOpenSignInDialog"
                text
                :class="{ 'valid-repo': isValidGithubRepo }"
              >
                <img
                  v-if="isValidGithubRepo"
                  src="@/assets/valid-submit-conversion.svg"
                  alt="Submit"
                />
                <img v-else src="@/assets/submit-conversion.svg" alt="Submit" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </main>

      <!-- Streaming Content Sidebar -->
      <div v-if="loading || streams.length > 0" class="streaming-sidebar">
        <div class="streaming-content">
          <h2 class="text-xl font-semibold mb-4">Documentation Generation</h2>

          <!-- Loading and Streaming Preview -->
          <div v-if="loading && !streams.length" class="flex flex-col gap-4">
            <ProgressBar mode="indeterminate" class="mb-4" />
            <StreamingPreview
              v-if="streamingContent"
              :content="streamingContent"
            />
          </div>

          <!-- Streaming Preview Carousel -->
          <StreamingPreviewCarousel
            v-if="streams.length > 0"
            :streams="streams"
            @refresh="refreshStreams"
            @clear="clearStreams"
          />
        </div>
      </div>
    </div>

    <!-- Sign In Dialog -->
    <Dialog
      v-model:visible="signInDialogVisible"
      modal
      header="Sign In"
      :style="{ width: '450px' }"
      :closable="true"
    >
      <div class="p-fluid">
        <!-- GitHub Sign-in Button -->
        <div class="github-signin-container mb-4">
          <Button
            @click="signInWithGitHub"
            class="github-button"
            severity="secondary"
          >
            <span class="github-icon">
              <svg
                viewBox="0 0 24 24"
                width="24"
                height="24"
                stroke="currentColor"
                stroke-width="2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path
                  d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"
                ></path>
              </svg>
            </span>
            <span>Sign in / Sign up with GitHub</span>
          </Button>
        </div>

        <div class="sign-in-description text-sm text-center text-gray-400">
          <p>By signing in, you agree to our <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=32f204fe-a9c2-468e-ae48-a49decd878f9" target="_blank" class="text-primary">Terms</a> and <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f93b0777-1325-40b0-b83a-e8f1fc82c82c" target="_blank" class="text-primary">Privacy</a>.</p>
        </div>

        <div class="sign-in-footer mt-4">
          <p class="text-sm text-center text-gray-400">
            Need help? <a href="mailto:support@dev-docs.io" class="text-primary">Contact support</a>
          </p>
        </div>
      </div>
    </Dialog>

    <!-- Docs Repo Dialog -->
    <Dialog
      v-model:visible="docsRepoDialogVisible"
      modal
      header="Additional Repository Information"
      :style="{ width: '450px' }"
      :closable="true"
    >
      <div class="p-fluid">
        <div class="mb-4">
          <label class="input-label"
            >Do you have a separate docs repository?</label
          >
          <SelectButton
            v-model="hasSeparateDocsRepo"
            :options="docsRepoOptions"
            class="docs-repo-selector w-full"
          />
        </div>

        <div v-if="hasSeparateDocsRepo === 'Yes'" class="mb-4">
          <label class="input-label"
            >Documentation repository URL</label
          >
          <InputText
            v-model="docsRepo"
            placeholder="Enter GitHub repository (/username/repo)"
            class="w-full"
            :class="{ 'p-invalid': !isValidDocsRepo && docsRepo }"
            @update:modelValue="saveDocsRepo"
          />
          <small v-if="!isValidDocsRepo && docsRepo" class="p-error block mt-2">
            Please enter a valid GitHub repository in the format username/repo
          </small>
        </div>

        <div class="flex justify-content-end">
          <Button v-if="hasSeparateDocsRepo === 'Yes'"
            @click="generateTheDocs"
            label="Generate docs"
            class="p-button-primary"
            :disabled="!isValidDocsRepo"
          />
          <Button v-else
            @click="generateTheDocs"
            label="Generate docs"
            class="p-button-primary"
          />
        </div>
      </div>
    </Dialog>

    <!-- Pull Request Dialog -->
    <Dialog
      v-model:visible="pullRequestDialogVisible"
      modal
      header="Documentation Generated!"
      :style="{ width: '500px' }"
      :closable="true"
    >
      <div class="p-fluid">
        <div class="completion-message mb-4">
          <i class="pi pi-check-circle text-green-500 text-2xl mr-2"></i>
          <span>Your documentation has been generated successfully!</span>
        </div>
        <div class="pull-request-info">
          <p class="mb-2">A pull request has been created with your changes:</p>
          <div
            class="pr-link-container p-3 border-1 border-round surface-ground"
          >
            <a
              :href="pullRequestUrl"
              target="_blank"
              class="flex align-items-center gap-2 text-primary"
            >
              <i class="pi pi-github"></i>
              <span>View Pull Request</span>
              <i class="pi pi-external-link text-xs"></i>
            </a>
          </div>
          <p class="mt-3 mb-2">Branch name:</p>
          <div class="branch-info p-2 border-1 border-round surface-ground">
            <code>{{ branchName }}</code>
          </div>
        </div>
      </div>
    </Dialog>

    <!-- Footer -->
    <footer class="footer">
      <div class="footer-content">
        <div class="footer-social">
          <a
            href="https://linkedin.com/company/dev-docs"
            target="_blank"
            rel="noopener noreferrer"
            class="social-link"
          >
            <i class="pi pi-linkedin"></i>
          </a>
          <a
            href="https://github.com/team-dev-docs"
            target="_blank"
            rel="noopener noreferrer"
            class="social-link"
          >
            <i class="pi pi-github"></i>
          </a>
        </div>
        <div class="footer-links">
          <a href="https://docs.dev" class="footer-link">Home</a>
          <a href="https://learn.docs.dev" class="footer-link">Docs</a>
          <a href="mailto:support@dev-docs.io" target="_blank" class="footer-link">Support</a>
          <a href="mailto:founders@dev-docs.io" target="_blank" class="footer-link">Work Inquiries</a>
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=32f204fe-a9c2-468e-ae48-a49decd878f9" class="footer-link">Terms</a>
          <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=f93b0777-1325-40b0-b83a-e8f1fc82c82c" class="footer-link">Privacy</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, watch, computed } from "vue";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import SelectButton from "primevue/selectbutton";
import InputGroup from "primevue/inputgroup";
import InputGroupAddon from "primevue/inputgroupaddon";
import { authInfo } from "../plugins/authn.js";
import StreamingPreview from "@/components/EditorComponents/StreamingPreview.vue";
import StreamingPreviewCarousel from "@/components/EditorComponents/StreamingPreviewCarousel.vue";
import ProgressBar from "primevue/progressbar";

export default defineComponent({
  name: "GenerateDocsInstantly",
  components: {
    Dialog,
    InputText,
    Password,
    Button,
    SelectButton,
    InputGroup,
    InputGroupAddon,
    StreamingPreview,
    StreamingPreviewCarousel,
    ProgressBar,
  },
  setup() {
    // Refs for data binding
    const query = ref("");
    const signInDialogVisible = ref(false);
    const email = ref("");
    const password = ref("");
    const githubRepo = ref("");
    const docsRepo = ref("");
    const hasSeparateDocsRepo = ref("No");
    const docsRepoOptions = ref(["No", "Yes"]);
    const docsRepoDialogVisible = ref(false);
    const streams = ref([]);
    const streamingContent = ref("");
    const loading = ref(false);
    const branch = ref("main");
    const owner = ref("");
    const repo = ref("");
    const pullRequestDialogVisible = ref(false);
    const pullRequestUrl = ref("");
    const branchName = ref("");
    const userData = ref(null);

    // Add computed property for GitHub repo validation
    const isValidGithubRepo = computed(() => {
      if (!githubRepo.value) return false;
      if(githubRepo.value.trim() === "") return false;
      const repoPattern = /^[a-zA-Z0-9-]+\/[a-zA-Z0-9-_.]+$/;
      return repoPattern.test(githubRepo.value.trim());
    });

    // Add computed property for docs repo validation
    const isValidDocsRepo = computed(() => {
      if(!docsRepo.value) return false;
      if(docsRepo.value.trim() === "") return false;
      const repoPattern = /^[a-zA-Z0-9-]+\/[a-zA-Z0-9-_.]+$/;
      return repoPattern.test(docsRepo.value.trim());
    });

    // Load saved repos from localStorage if they exist
    onMounted(() => {
      const savedRepo = localStorage.getItem("github_repo_url");
      if (savedRepo) {
        githubRepo.value = savedRepo;
      }

      const savedDocsRepo = localStorage.getItem("docs_repo_url");
      if (savedDocsRepo) {
        docsRepo.value = savedDocsRepo;
        hasSeparateDocsRepo.value = "Yes";
      }

      if (window.location.pathname === "/auth/github/callback") {
        handleOAuthCallback();
      }

      // Check user login status
      checkUserLogin();
    });

    // Save GitHub repo to localStorage
    const saveGithubRepo = () => {
      if (githubRepo.value.trim()) {
        localStorage.setItem("github_repo_url", githubRepo.value);
        console.log("GitHub repo saved:", githubRepo.value);
      }
    };

    // Save Docs repo to localStorage
    const saveDocsRepo = () => {
      if (docsRepo.value.trim()) {
        localStorage.setItem("docs_repo_url", docsRepo.value);
        console.log("Docs repo saved:", docsRepo.value);
      }
    };

    const generateTheDocs = async () => {
      docsRepoDialogVisible.value = false;
      loading.value = true;
      streamingContent.value = "";
      streams.value = [];
      await authInfo.renew()
      let token = await authInfo.getToken();
      let org = await authInfo.org;
      let baseUrl = await authInfo.getBaseUrl();
      let gitHubRepo = githubRepo.value;
      let [ownerName, repoName] = gitHubRepo.split("/");
      owner.value = ownerName;
      repo.value = repoName;
      let docsRepoValue = docsRepo.value;
      if(hasSeparateDocsRepo.value === "No") docsRepoValue = null;
      let gitHubToken = localStorage.getItem("github_access_token");
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");

      try {
        const response = await fetch(
          `${baseUrl}/owners/${ownerName}/repos/${repoName}/complete_user_docs`,
          {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({
              gitHubToken: gitHubToken,
              docsRepo: docsRepoValue
            }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            // Process any remaining buffer
            if (buffer.trim()) {
              try {
                const data = JSON.parse(buffer);
                if (data.type === "doc_content_stream") {
                  handleStreamData(data);
                } else if (data.type === "complete") {
                  // Handle pull request completion
                  handlePullRequestComplete(data);
                }
              } catch (e) {
                console.error("Error parsing final buffer:", e);
              }
            }
            break;
          }

          const chunk = decoder.decode(value, { stream: true });
          streamingContent.value += chunk;
          buffer += chunk;

          const lines = buffer.split("\n");
          buffer = lines.pop() || "";

          for (const line of lines) {
            if (line.trim()) {
              try {
                const data = JSON.parse(line);
                if (data.type === "doc_content_stream") {
                  handleStreamData(data);
                } else if (data.type === "complete") {
                  // Handle pull request completion
                  handlePullRequestComplete(data);
                }
              } catch (e) {
                console.error("Error parsing JSON:", e);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error generating docs:", error);
      } finally {
        loading.value = false;
        // Mark all streams as complete when done
        streams.value = streams.value.map((stream) => ({
          ...stream,
          status: stream.status === "processing" ? "complete" : stream.status,
        }));
      }
    };

    const handleStreamData = (data) => {
      // Find existing stream or create new one
      let stream = streams.value.find((s) => s.fileName === data.file);

      if (!stream) {
        stream = {
          id: Date.now() + streams.value.length,
          fileName: data.file,
          content: "",
          status: "processing",
        };
        streams.value.push(stream);
      }

      // Convert Buffer data to string and append to stream content
      const bufferData = new Uint8Array(data.content.data);
      const content = new TextDecoder().decode(bufferData);
      stream.content += content;
    };

    const handlePullRequestComplete = (data) => {
      // Update all processing streams to complete
      streams.value = streams.value.map((stream) => ({
        ...stream,
        status: stream.status === "processing" ? "complete" : stream.status,
      }));

      // Store and display pull request information
      if (data.pullRequestUrl) {
        pullRequestUrl.value = data.pullRequestUrl;
        localStorage.setItem("lastPullRequestUrl", data.pullRequestUrl);
      }
      if (data.branch) {
        branchName.value = data.branch;
        localStorage.setItem("lastBranch", data.branch);
      }

      // Show the pull request dialog
      pullRequestDialogVisible.value = true;
    };

    const refreshStreams = () => {
      // Implement refresh logic if needed
      console.log("Refreshing streams");
    };

    const clearStreams = () => {
      streams.value = [];
      streamingContent.value = "";
    };

    // Method to save query to localStorage and open sign-in dialog
    const setQueryAndOpenSignInDialog = async () => {
      // Save the current query to localStorage
      if (query.value.trim()) {
        localStorage.setItem("docs_ai_query", query.value);
        console.log("Query saved:", query.value);
      }

      let token = await authInfo.getToken();
      let orgs = await authInfo.getOrgs();
      let gitHubToken = await localStorage.getItem("github_access_token");
      if (!orgs?.length > 0) {
        await authInfo.tryToSetOrg(token);
      }
      let storedOrg = await localStorage.getItem("org");
      let org = await authInfo.org || storedOrg;
      if (!org || !token || !gitHubToken) {
        signInDialogVisible.value = true;
      } else {
        docsRepoDialogVisible.value = true;
      }
    };

    // Method to open sign-in dialog directly
    const openSignInDialog = () => {
      signInDialogVisible.value = true;
    };

    // GitHub OAuth configuration
    let clientId = authInfo.getGeneratorClientId();
    const githubConfig = {
      clientId: clientId,
      redirectUri: `${window.location.origin}/auth/github/callback`,
      scope: "user:email read:user public_repo",
      authorizationUrl: "https://github.com/login/oauth/authorize",
      tokenUrl: "https://github.com/login/oauth/access_token",
    };

    // Generate a random state for CSRF protection
    const generateState = () => {
      const array = new Uint32Array(8);
      window.crypto.getRandomValues(array);
      return Array.from(array, (dec) =>
        ("0" + dec.toString(16)).substr(-2)
      ).join("");
    };

    // Handle GitHub OAuth callback
    const handleOAuthCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const returnedState = urlParams.get("state");
      const savedState = localStorage.getItem("github_oauth_state");

      // Clear state from localStorage
      localStorage.removeItem("github_oauth_state");

      // Verify state to prevent CSRF attacks
      if (!returnedState || returnedState !== savedState) {
        console.error("OAuth state mismatch - possible CSRF attack");
        return;
      }

      if (code) {
        try {
          // Exchange code for access token via backend proxy
          // (GitHub doesn't allow this directly from browser due to CORS)
          const response = await fetch(
            `${
              process.env.VUE_APP_API_URL || "https://api.docs.dev"
            }/auth/github/token`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                code,
                redirect_uri: githubConfig.redirectUri,
              }),
            }
          );

          if (!response.ok)
            throw new Error("Failed to exchange code for token");

          const data = await response.json();
          if (data.access_token) {
            // Store the token securely
            localStorage.setItem("github_access_token", data.access_token);

            // Get user info
            const userResponse = await fetch("https://api.github.com/user", {
              headers: {
                Authorization: `token ${data.access_token}`,
              },
            });

            if (!userResponse.ok) throw new Error("Failed to fetch user data");

            const userData = await userResponse.json();

            // Save authenticated user info
            localStorage.setItem("user_signed_in", "true");
            localStorage.setItem("auth_provider", "github");
            localStorage.setItem(
              "github_user",
              JSON.stringify({
                id: userData.id,
                login: userData.login,
                name: userData.name,
                email: userData.email,
                avatar_url: userData.avatar_url,
              })
            );

            // Process any saved query
            const savedQuery = localStorage.getItem("docs_ai_query");
            if (savedQuery) {
              console.log(
                "Processing saved query after GitHub auth:",
                savedQuery
              );
              // Handle the query with authenticated user
            }

            // Redirect to dashboard or appropriate page
            window.location.href = "/users_docs";
          }
        } catch (error) {
          console.error("GitHub authentication error:", error);
        }
      }
    };

    // GitHub sign in method
    const signInWithGitHub = () => {
      console.log("Initiating GitHub OAuth flow");

      // Save current query before redirecting
      if (query.value.trim()) {
        localStorage.setItem("docs_ai_query", query.value);
      }

      // Generate and store state for CSRF protection
      const state = generateState();
      localStorage.setItem("github_oauth_state", state);
      localStorage.setItem("login_redirect", window.location.pathname);

      // Construct authorization URL with parameters
      const authUrl = new URL(githubConfig.authorizationUrl);
      authUrl.searchParams.append("client_id", githubConfig.clientId);
      authUrl.searchParams.append("redirect_uri", githubConfig.redirectUri);
      authUrl.searchParams.append("scope", githubConfig.scope);
      authUrl.searchParams.append("state", state);

      // Redirect to GitHub authorization page
      window.location.href = authUrl.toString();
    };

    // Sign in method
    const signIn = () => {
      // Implement your sign-in logic here
      console.log("Signing in with:", email.value);
      signInDialogVisible.value = false;

      // Here you would typically call your authentication API
      // For now, we'll just simulate a successful login
      localStorage.setItem("user_signed_in", "true");
      localStorage.setItem("auth_provider", "email");

      // Redirect to dashboard or handle the saved query
      const savedQuery = localStorage.getItem("docs_ai_query");
      if (savedQuery) {
        console.log("Processing saved query:", savedQuery);
        // Here you would process the query
      }
    };

    // Register method
    const register = () => {
      // Implement your registration logic here
      console.log("Registering with:", email.value);
      // You might redirect to a registration page or show a different dialog
    };

    const continueToSignIn = () => {
      docsRepoDialogVisible.value = false;
      signInDialogVisible.value = true;
    };

    // Check if user is logged in and get their data
    const checkUserLogin = () => {
      const githubUserData = localStorage.getItem("github_user");
      if (githubUserData) {
        try {
          userData.value = JSON.parse(githubUserData);
        } catch (e) {
          console.error("Error parsing user data:", e);
        }
      }
    };

    // Logout method
    const logout = async () => {
      localStorage.removeItem("github_user");
      localStorage.removeItem("github_access_token");
      localStorage.removeItem("user_signed_in");
      localStorage.removeItem("auth_provider");
      localStorage.removeItem("token");
      userData.value = null;
      await authInfo.logoutFromGenerateDocs();
    };

    return {
      query,
      signInDialogVisible,
      email,
      password,
      githubRepo,
      docsRepo,
      hasSeparateDocsRepo,
      docsRepoOptions,
      docsRepoDialogVisible,
      streams,
      streamingContent,
      loading,
      branch,
      owner,
      repo,
      setQueryAndOpenSignInDialog,
      generateTheDocs,
      openSignInDialog,
      signInWithGitHub,
      signIn,
      register,
      saveGithubRepo,
      saveDocsRepo,
      continueToSignIn,
      refreshStreams,
      clearStreams,
      pullRequestDialogVisible,
      pullRequestUrl,
      branchName,
      isValidGithubRepo,
      isValidDocsRepo,
      userData,
      logout,
    };
  },
});
</script>

<style scoped>
.container {
  min-height: 100vh;
  background-image: url("@/assets/conversion-background.png");
  background-color: #000000;
  background-position: center -243px;
  background-size: cover;
  background-repeat: no-repeat;
  color: #ebebeb;
  margin: 0px;
  padding: 0px;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
}

.logo-text {
  font-size: 1.25rem;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
}

.header-buttons {
  display: flex;
  gap: 1rem;
}

.btn-get-started {
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 3.125rem;
  background: var(--white-black, #ebebeb);
  color: #000000;
}

.btn-login {
  background-color: transparent;
  color: #ebebeb;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 3.125rem;
  border: 1px solid var(--light-grey-20, rgba(162, 161, 165, 0.2));
}

.dynamic-layout {
  flex: 1;
  display: flex;
  width: 100%;
  gap: 2rem;
  padding: 2rem;
}

.dynamic-layout.side-by-side {
  padding: 0;
}

.main-content {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  transition: all 0.3s ease;
}

.main-content.with-sidebar {
  margin: 0;
  max-width: 50%;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.streaming-sidebar {
  flex: 1;
  max-width: 50%;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  min-height: calc(100vh - 80px); /* Adjust based on your header height */
}

.streaming-content {
  max-width: 800px;
  margin: 0 auto;
}

/* Adjust illustration size when in side-by-side mode */
.main-content.with-sidebar .illustration-container {
  width: 20rem;
  height: 12rem;
}

/* Adjust title size when in side-by-side mode */
.main-content.with-sidebar .title {
  font-size: 2rem;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .dynamic-layout.side-by-side {
    flex-direction: column;
  }

  .main-content.with-sidebar {
    max-width: 100%;
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .streaming-sidebar {
    max-width: 100%;
  }
}

.illustration-container {
  width: 25.9375rem;
  height: 15.08381rem;
  margin-bottom: 1.875rem;
  /* aspect-ratio: 415/241.34;
  margin: 2rem auto;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(106, 120, 251, 0.2);
  border-radius: 1.5rem;
  background: var(--light-grey-20, rgba(162, 161, 165, 0.2)); */
}

.illustration-img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: white;
}

.subtitle {
  font-size: 1.1rem;
  color: #a2a1a5;
  margin-bottom: 2rem;
  max-width: 600px;
}

.repo-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 2rem;
}

.repo-input {
  width: 100%;
  padding: 1rem;
  background-color: transparent;
  border: 1px solid rgba(106, 120, 251, 0.3);
  border-radius: 6px;
  color: white;
  font-size: 1rem;
  border-radius: 0.5rem;
  border: 2px solid var(--light-grey-20, rgba(162, 161, 165, 0.2));
  background: var(--light-grey-20, rgba(162, 161, 165, 0.2));
}

.repo-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.repo-option-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto 2rem;
  text-align: left;
}

.docs-repo-selector {
  margin-top: 0.5rem;
}

.docs-repo-selector :deep(.p-button) {
  background-color: transparent;
  border: 1px solid rgba(106, 120, 251, 0.3);
  color: white;
}

.docs-repo-selector :deep(.p-button.p-highlight) {
  background-color: #6a78fb;
  border-color: #6a78fb;
}

.search-container {
  position: relative;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 3rem;
}

.search-input {
  width: 100%;
  padding: 1rem 3rem 1rem 1rem;
  background-color: transparent;
  border: 1px solid rgba(106, 120, 251, 0.3);
  border-radius: 6px;
  color: white;
  font-size: 1rem;
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.search-button {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #6a78fb;
  font-size: 1.2rem;
  cursor: pointer;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  max-width: 900px;
}

.feature-item {
  background-color: rgba(10, 10, 10, 0.5);
  padding: 1rem 1.5rem;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 1rem;
  display: flex;
  max-width: 50rem;
  max-height: 22.5rem;
  padding: 0.25rem 0.75rem;
  align-items: flex-start;
}

.feature-item:nth-child(5) {
  grid-column: span 2;
}

/* GitHub button styles */
.github-signin-container {
  margin-bottom: 1.5rem;
}

.github-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #24292e;
  border-color: #24292e;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.github-button:hover {
  background-color: #2f363d;
}

.github-icon {
  display: flex;
  align-items: center;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
  color: #666;
}

.divider::before,
.divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #333;
}

.divider span {
  padding: 0 10px;
  font-size: 0.875rem;
}

/* PrimeVue dialog customizations */
:deep(.p-dialog) {
  background-color: #121212;
  color: white;
  border-radius: 8px;
}

:deep(.p-dialog-header) {
  background-color: #121212;
  color: white;
  border-bottom: 1px solid #333;
}

:deep(.p-dialog-content) {
  background-color: #121212;
  color: white;
  padding: 1.5rem;
}

:deep(.p-inputtext) {
  background-color: #1e1e1e;
  color: white;
  border: 1px solid #444;
}

:deep(.p-button-primary) {
  background-color: #6a78fb;
  border-color: #6a78fb;
}

:deep(.p-button-secondary) {
  background-color: transparent;
  border-color: #444;
  color: white;
}

:deep(.field) {
  margin-bottom: 1.5rem;
}

.flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.p-fluid .field {
  margin-bottom: 1.5rem;
}

.input-label {
  display: block;
  text-align: left;
  margin-bottom: 0.5rem;
  color: #a2a1a5;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .header {
    padding: 1rem;
  }

  .main-content {
    padding: 1rem;
  }

  .title {
    font-size: 2rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .feature-item:nth-child(5) {
    grid-column: span 1;
  }

  .illustration-container {
    width: 200px;
    height: 200px;
  }
}

.completion-message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  background-color: rgba(34, 197, 94, 0.1);
  border-radius: 0.5rem;
  margin-bottom: 1.5rem;
}

.pr-link-container {
  background-color: rgba(255, 255, 255, 0.05);
}

.pr-link-container a {
  text-decoration: none;
  color: #6a78fb;
}

.branch-info {
  background-color: rgba(255, 255, 255, 0.05);
  font-family: monospace;
  word-break: break-all;
}

.valid-repo {
  color: #6a78fb !important;
}

.valid-repo:hover {
  color: #8a94fb !important;
}

/* Footer Styles */
.footer {
  width: 100%;
  padding: 1rem 2rem;
  background-color: transparent;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-links {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0.5rem 0;
}

.footer-social {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 0.5rem 0;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0.5rem 0;
}

.social-link {
  color: #a2a1a5;
  font-size: 1.25rem;
  transition: color 0.2s ease;
  display: flex;
  align-items: center;
}

.social-link:hover {
  color: #fff;
}

.footer-link {
  color: #a2a1a5;
  text-decoration: none;
  font-size: 0.875rem;
  transition: color 0.2s ease;
}

.footer-link:hover {
  color: #fff;
}

@media (max-width: 768px) {
  .footer-content {
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
}

.sign-in-description {
  color: #a2a1a5;
  font-size: 0.9rem;
  line-height: 1.5;
}

.sign-in-description a {
  color: #6a78fb;
  text-decoration: none;
}

.sign-in-description a:hover {
  text-decoration: underline;
}

.sign-in-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 1rem;
}

.text-primary {
  color: #6a78fb;
}

.text-primary:hover {
  color: #8a94fb;
}

.text-sm {
  font-size: 0.875rem;
}

.text-center {
  text-align: center;
}

.text-gray-400 {
  color: #a2a1a5;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.p-invalid {
  border-color: #ff5757 !important;
}

.p-error {
  color: #ff5757;
  font-size: 0.875rem;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.2);
}

.btn-logout {
  background-color: transparent;
  color: #ebebeb;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 3.125rem;
  border: 1px solid var(--light-grey-20, rgba(162, 161, 165, 0.2));
}

.btn-logout:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>

<template>
  <div class="mini-editor-container">
    <div v-if="title" class="editor-title">{{ title }}</div>
    <div v-if="fileName" class="text-white px-4 py-2 border-b border-white/12">
      Generated file path: {{ fileName }}
    </div>
    <div class="flex gap-3 p-4 border-t border-white/12">
      <Button 
        @click="handleAccept"
        class="!bg-[#2C2C2C] hover:!bg-[#3C3C3C] !border-none flex items-center gap-2"
      >
        <i class="pi pi-check"></i>
        Accept
      </Button>
    </div>
    <editor-content v-if="editor" :editor="editor" class="mini-editor-content" />
    <div v-else class="mini-editor-content">
      <div class="ProseMirror p-4 text-white">Loading editor...</div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref, watch, nextTick } from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import { htmlToMarkdown, markdownToHtml } from "@/plugins/unifiedParserService.js";
import { createFileContent, updateFileContent } from "@/plugins/devdocsBackendService.js";
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Code from '@tiptap/extension-code';
import Link from '@tiptap/extension-link';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import VsCodeImage from "@/components/EditorExtensions/MarkdownGithubImage";


const props = defineProps({
  initialContent: {
    type: String,
    default: ''
  },
  fileName: {
    type: String,
    required: true
  },
  branch: {
    type: String,
    required: true
  },
  owner: {
    type: String,
    required: true
  },
  repo: {
    type: String,
    required: true
  },
  autoSave: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  }
});

const emit = defineEmits(['update:content', 'saved', 'accept', 'revert', 'discard']);

const editor = ref(null);
const content = ref('');

// Initialize editor with basic extensions
onMounted(async () => {
  if (!editor.value) {
    editor.value = new Editor({
      extensions: [
        StarterKit,
        Document,
        Paragraph,
        Text,
        Code,
        Link,
        Table.configure({
          resizable: true,
        }),
        TableCell,
        TableHeader,
        TableRow,
        VsCodeImage.configure({ allowBase64: true, inline: true }),
      ],
      content: ''
    });
  }

  // Wait for editor to be ready before setting content
  await nextTick();
  if (props.initialContent) {
    try {
      const parsedContent = await markdownToHtml(props.initialContent);
      if (editor.value && parsedContent.content) {
        editor.value.commands.setContent(parsedContent.content);
      }
    } catch (e) {
      console.error('Error parsing content:', e);
      // Fallback to setting raw content if parsing fails
      if (editor.value) {
        editor.value.commands.setContent(props.initialContent);
      }
    }
  }
});

// Watch for changes in initialContent prop
watch(() => props.initialContent, async (newContent) => {
  if (editor.value && newContent) {
    try {
      const parsedContent = await markdownToHtml(newContent);
      if (parsedContent.content) {
        editor.value.commands.setContent(parsedContent.content);
      }
    } catch (e) {
      console.error('Error parsing content:', e);
      editor.value.commands.setContent(newContent);
    }
  }
});

const createFile = async () => {
  try {
    let html = await editor.value.getHTML();
    let markdown = await htmlToMarkdown(html);
    // Validate branch and use draftBranch from localStorage if empty
    const effectiveBranch = props.branch || localStorage.getItem('draftBranch') || 'main';
    const response = await createFileContent({
      branch: effectiveBranch,
      fileName: props.fileName,
      owner: props.owner,
      repo: props.repo,
      content: markdown
    });
    emit('saved', response);
    return response;
  } catch (error) {
    console.error('Error creating file:', error);
    throw error;
  }
};

const handleAccept = async () => {
  if (!editor.value) return;
  
  try {
    await createFile();
    const html = editor.value.getHTML();
    emit('accept', {
      content: html,
      fileName: props.fileName
    });
  } catch (e) {
    console.error('Error handling accept:', e);
  }
};

const handleRevert = () => {
  emit('revert');
};

const handleDiscard = () => {
  emit('discard');
};

// Clean up editor instance
onBeforeUnmount(() => {
  if (editor.value) {
    editor.value.destroy();
    editor.value = null;
  }
});

// Expose methods to parent component
defineExpose({
  createFile,
  editor
});
</script>

<style lang="scss" scoped>
.mini-editor-container {
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 0.5rem;
  background: #121212;
}

.editor-title {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

:deep(.mini-editor-content) {
  .ProseMirror {
    min-height: 200px;
    padding: 1rem;
    outline: none;
    color: white;
    font-family: 'Inter', sans-serif;

    &:focus {
      outline: none;
    }

    p {
      margin-bottom: 0.5rem;
    }

    h1, h2, h3 {
      color: white;
      margin-bottom: 1rem;
    }

    ul, ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }

    code {
      background-color: #1e1e1e;
      padding: 0.2rem 0.4rem;
      border-radius: 0.25rem;
      font-family: 'Fira Code', monospace;
    }

    pre {
      background: rgba(17, 25, 40, 0.6);
      padding: 1rem;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
    }

    a {
      color: #7984eb;
      text-decoration: underline;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 1rem;

      td, th {
        border: 1px solid rgba(255, 255, 255, 0.12);
        padding: 0.5rem;
      }
    }
  }
}
</style>
import { visit } from "unist-util-visit";

/**
 * Main function to check and parse HTML elements in content
 * @param {string} content - The content to check for HTML elements
 * @returns {Object} Object containing parsed content and any found HTML elements
 */
export function checkAndParseHtmlElements(content) {
  console.log("Starting checkAndParseHtmlElements with content:", content);
  const result = {
    details: parseDetails(content)
  };
  console.log("Result from checkAndParseHtmlElements:", result);
  console.log("About to return from checkAndParseHtmlElements");
  return result;
}

/**
 * Parses details elements from content
 * @param {string} content - The content to parse for details elements
 * @returns {Array} Array of parsed details objects
 */
function parseDetails(content) {
  console.log("Starting parseDetails with content:", content);
  const detailsRegex = /<details>([\s\S]*?)<\/details>/g;
  const details = [];
  let match;

  // First check if we can find any details tags at all
  const hasDetails = content.includes('<details>');
  console.log("Does content contain <details> tag?", hasDetails);

  while ((match = detailsRegex.exec(content)) !== null) {
    console.log("Found a match:", match);
    const detailsContent = match[1];
    console.log("Details content:", detailsContent);
    
    const summaryMatch = detailsContent.match(/<summary>([\s\S]*?)<\/summary>/);
    console.log("Summary match:", summaryMatch);
    
    const contentMatch = detailsContent.replace(/<summary>[\s\S]*?<\/summary>/, '').trim();
    console.log("Content match:", contentMatch);

    details.push({
      summary: summaryMatch ? summaryMatch[1] : '',
      content: contentMatch || ''
    });
  }

  console.log("Final details array:", details);
  return details;
}

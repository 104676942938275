<template>
  <div class="auto-create-settings p-4 rounded-lg">
    <h3 class="text-white text-lg mb-4">Auto Create Settings</h3>
    <div class="flex flex-wrap gap-4">
      <div v-for="(setting, index) in autoCreateSettings" style="border-radius: 1.5rem;" :key="index" class="flex items-center gap-2 p-2">
        <span class="text-sm text-white/80">{{ setting.label }}:</span>
        <ToggleButton 
          v-model="setting.value" 
          onLabel="On" 
          offLabel="Off"
          @change="updateSetting(setting.key, setting.value)"
        />
      </div>
    </div>
    <div class="mt-4 flex justify-center">
      <ToggleButton
        v-model="allSettingsEnabled"
        onLabel="Turn All Off"
        offLabel="Turn All On"
        onIcon="pi pi-check"
        offIcon="pi pi-times"
        @change="setAllSettings(allSettingsEnabled)"
      />
    </div>
  </div>
</template>

<script>
import ToggleButton from 'primevue/togglebutton';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';

export default {
  name: 'AutoCreateSettings',
  components: {
    ToggleButton,
    Button
  },
  data() {
    return {
      allSettingsEnabled: false,
      autoCreateSettings: [
        { 
          key: 'code_objects_docs_auto_create', 
          label: 'Code Objects Docs', 
          value: false 
        },
        { 
          key: 'audit_content_auto_create', 
          label: 'Audit Content', 
          value: false 
        },
        { 
          key: 'analyze_docs_auto_create', 
          label: 'Analyze Docs', 
          value: false 
        },
        { 
          key: 'generate_complete_docs_auto_create', 
          label: 'Generate Complete Docs', 
          value: false 
        },
        { 
          key: 'generate_content_auto_create', 
          label: 'Generate Content', 
          value: false 
        }
      ]
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  mounted() {
    // Load saved settings from localStorage
    this.loadSettings();
    // Check if all settings are enabled to set the toggle state
    this.updateAllSettingsState();
  },
  methods: {
    loadSettings() {
      this.autoCreateSettings.forEach(setting => {
        const savedValue = localStorage.getItem(setting.key);
        if (savedValue !== null) {
          setting.value = savedValue === 'true';
        }
      });
    },
    updateSetting(key, value) {
      localStorage.setItem(key, String(value));
      this.toast.add({
        severity: 'success',
        summary: 'Setting Updated',
        detail: `Auto create for ${key.replace('_auto_create', '').replace(/_/g, ' ')} is now ${value ? 'enabled' : 'disabled'}`,
        life: 3000
      });
      // Update the all settings toggle state
      this.updateAllSettingsState();
    },
    updateAllSettingsState() {
      // Check if all settings are enabled
      this.allSettingsEnabled = this.autoCreateSettings.every(setting => setting.value);
    },
    setAllSettings(value) {
      this.autoCreateSettings.forEach(setting => {
        setting.value = value;
        localStorage.setItem(setting.key, String(value));
      });
      
      this.toast.add({
        severity: 'info',
        summary: 'Settings Updated',
        detail: `All auto create settings are now ${value ? 'enabled' : 'disabled'}`,
        life: 3000
      });
    }
  }
};
</script>

<style scoped>
.auto-create-settings {
  max-width: 100%;
}
</style> 
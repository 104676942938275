<template>
  <Toast />
  <div v-if="loading" class="flex flex-col gap-4 p-5">
    <h2 class="text-xl text-white font-semibold">Generate content</h2>
    <div
      v-if="!contentComplete"
      class="bg-[#1C1C1C] rounded-lg p-4 min-h-[200px] font-mono text-white whitespace-pre-wrap"
    >
      ```markdown
      {{ streamingContent }}
      ```
    </div>
    <div v-else class="flex flex-col gap-4">
      <DocumentCarousel
        v-model:documents="streamingResponses"
        :branch="branch"
        :owner="documentationRepo?.owner || ''"
        :repo="documentationRepo?.repo || ''"
        action="generate_content"
        @accept="handleAccept"
        @revert="handleRevert"
        @clear-cached="streamingResponses = []"
        @discard="handleDiscard"
        @load-cached="handleLoadCached"
      />
      <div v-if="generatingTitle" class="text-white/50">
        Generating appropriate file path...
      </div>
      <div v-if="generatedFileName" class="text-white">
        Generated file path: {{ generatedFileName }}
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col gap-[1.25rem] mt-[1.25rem]">
    <div
      style="display: flex; flex-direction: column"
      class="flex flex-col gap-[1.25rem]"
    >
      <div
        style="display: flex; flex-direction: column !important; gap: 0.62em"
        class=""
      >
        <label for="folders"
          >What existing docs do you want to use as context</label
        >
        <!-- <TreeSelect fluid filter filterMode="lenient" v-model="selectedFolders" :options="treeNodes"
                    selectionMode="checkbox" placeholder="Select item(s)" class="w-full !border-none !bg-[#1c1c1c]" /> -->
        <ContextSelectTree
          v-if="folderOptions?.length > 0 && updatedContextSource"
          :data="folderObject"
          :additional-items="repoContextItems"
          v-model="selectedFolders"
          @selection-change="handleSelectionItemChange"
          class="mt-[0.62rem] !bg-[#1C1C1C]"
        />
        <Skeleton class="mt-2" v-else height="2rem" fluid />
      </div>
    </div>
    <div class="">
      <div class="flex items-center p-[0.62rem] gap-[0.62rem]">
        <Checkbox
          v-model="checkCode"
          :binary="true"
          inputId="binary"
          label="Verify documentation matches current code"
        />
        <label for="size_normal"
          >Click here to use code as context. Once checked, select codefiles
          from the dropdown above.</label
        >
      </div>
    </div>

    <div class="flex flex-col gap-[0.62rem]">
      <span class="w-full p-card-subtitle">Your task for Dev-Docs AI</span>
      <div style="display: flex; position: relative">
        <Textarea
          placeholder="Type your document prompt here."
          class="w-full rounded-[0.5rem] !border-none !bg-[#1c1c1c] px-[0.63rem] py-[0.44rem] h-[9.375rem]"
          autoResize
          v-model="value"
          variant="filled"
          autofocus
          fluid
          rows="2"
        />
      </div>
    </div>
    <div class="flex flex-col justify-start items-start gap-[0.62rem]">
      <div
        class="self-stretch text-white/50 text-sm font-normal font-['Inter'] leading-[21px]"
      >
        Popular prompts:
      </div>
      <div
        class="self-stretch flex flex-col justify-start items-start gap-[0.62rem]"
      >
        <div
          v-for="(prompt, index) in popularPrompts"
          :key="index"
          class="px-[0.44rem] py-[0.06rem] bg-[#7984eb]/25 rounded-[0.5rem] border border-[#7984eb] justify-start items-start inline-flex cursor-pointer"
        >
          <div
            @click="setPrompt(prompt)"
            class="text-[#d3d3d3] text-xs font-normal font-['Inter'] leading-[18px]"
          >
            {{ prompt }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-end mt-4">
      <Button @click="generateContent" label="Submit" />
    </div>
    <div v-if="hasCachedContent" class="mb-4">
      <DocumentCarousel
        v-model:documents="streamingResponses"
        :branch="branch"
        :owner="documentationRepo?.owner || ''"
        :repo="documentationRepo?.repo || ''"
        action="generate_content"
        @accept="handleAccept"
        @revert="handleRevert"
        @discard="handleDiscard"
        @clear-cached="streamingResponses = []"
        @load-cached="handleLoadCached"
      />
    </div>
  </div>
</template>

<script>
import ApiKey from "@/views/ApiKey.vue";
import {
  Editor,
  EditorContent,
  VueNodeViewRenderer,
  FloatingMenu,
} from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import MarkdownEditor from "@/components/NewMarkdownEditor.vue";
import ContextSelectTree from "./ContextSelectTree.vue";
import DocumentCarousel from "./EditorComponents/DocumentCarousel.vue";
import {
  getFileContent,
  getCustomFiles,
  getOwnerAndRepo,
  getSyncedDocsRepo,
} from "../plugins/devdocsBackendService.js";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import indexDbService from "../plugins/indexDbService.js";

function findParentPaths(items) {
  const paths = Array.isArray(items) ? items : [];
  return paths.filter((path, index) => {
    return !paths.some((otherPath, otherIndex) => {
      if (index === otherIndex) return false;
      return path.startsWith(otherPath + "/");
    });
  });
}

export default {
  props: {
    repo: String,
    branch: String,
  },
  components: {
    EditorContent,
    FloatingMenu,
    MarkdownEditor,
    ContextSelectTree,
    DocumentCarousel,
    Toast,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      value: "",
      additionalOptionsValue: null,
      checkCode: false,
      locationValue: "docs",
      items: ["docs", "blog"],
      loading: false,
      response: null,
      documentationRepo: {},
      githubRepository: null,
      streamingResponses: [],
      selectedFolders: {},
      folderObject: {},
      folderOptions: [],
      codeFiles: [],
      editorOptions: ["Edit raw markdown", "Use rich text editor(preview)"],
      editorSelectvalue: "Edit raw markdown",
      openapiOptions: [],
      openapiRoutes: [],
      draftContent: null,
      additonalContextOptions: [
        { name: "openapi files", value: "openapi files" },
      ],
      treeNodes: [],
      editor: null,
      updatedContextSource: true,
      repoContextItems: [],
      popularPrompts: [
        "Provide a high-level overview of core features and use cases for different developer roles.",
        "Offer a step-by-step troubleshooting guide or link to a FAQ.",
        "Generate a blog post on most recent updates",
      ],
      streamingContent: "",
      generatedFileName: null,
      generatingTitle: false,
      finalContent: "",
      contentComplete: false,
      hasCachedContent: false,
      cachedDocument: null,
    };
  },
  async mounted() {
    let component = this;
    let documentationRepo = await getOwnerAndRepo();
    this.documentationRepo = documentationRepo;
    this.githubRepository = `${documentationRepo?.owner}/${documentationRepo?.repo}`;
    await this.getFolders();
    let items = await this.fetchOpenApi();
    console.log("what is the items", items);
    this.openapiOptions = items;
    await this.checkCachedContent();
  },
  watch: {
    additionalOptionsValue: function (val) {
      console.log("what is the value", val);
    },
    openapiRoutes: function (val) {
      console.log("what is the value", val);
    },
    selectedFolders: function (val) {
      console.log("what is the value", val);
    },
    checkCode: {
      async handler(newData) {
        try {
          if (newData) {
            this.updatedContextSource = false;
            let repos = await this.getRepos();
            let newRepoContextItems = [];
            console.log("what is repos", repos);
            for (let repo of repos) {
              console.log("what is repo here", repo);
              console.log(
                "what is this documentation repo",
                this.documentationRepo
              );
              if (
                this.documentationRepo.repo == repo.name &&
                this.documentationRepo.owner == repo.owner
              ) {
                continue;
              }
              let files = await getCustomFiles({
                branch: repo.default_branch,
                owner: repo.owner,
                repo: repo.name,
              });
              let repoContextItem = {
                label: repo.full_name,
                files: files?.files || [],
                category: repo.full_name,
              };
              newRepoContextItems.push(repoContextItem);
            }
            this.repoContextItems = newRepoContextItems;
            this.updatedContextSource = true;
          }
        } catch (e) {
          this.updatedContextSource = true;
        }
      },
    },
  },
  methods: {
    search(event) {
      this.items = ["docs", "blog"];
    },
    async getRepos() {
      var myHeaders = new Headers();
      try {
        var token = await this.$authInstance.getToken();

        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        let url = await this.$authInstance.getBaseUrl();
        let response = await fetch(`${url}/github_app_repos`, requestOptions);
        let jsonResponse = await response.json();
        //this.repos = jsonResponse?.repos
        return jsonResponse?.repos;
      } catch (e) {
        //this.folderOptions = []
      }
    },
    handleSelectionItemChange(value) {
      // Handle the selections here
      console.log("Selected folders:", value);
      this.selectedFolders = value.selections;
      let byCategory = value.byCategory;
      this.codeFiles = byCategory;
      // You can store the selections in your component's data
      // or emit them to a parent component if needed
    },
    setPrompt(prompt) {
      this.value = prompt;
    },
    processSelectedFolders(folderObj) {
      // Get all checked folders
      console.log("this is the folder obj", folderObj);
      let checkedFolders = Object.entries(folderObj)
        .filter(([key, value]) => value.checked)
        .map(([key]) => key);

      // Remove the "0" entry if it exists
      checkedFolders = checkedFolders.filter((folder) => folder !== "0");

      // Sort by path length to process shortest (parent) paths first
      checkedFolders.sort((a, b) => a.split("/").length - b.split("/").length);

      const result = [];
      console.log("this is the checked folders", checkedFolders);
      for (let folder of checkedFolders) {
        // Check if this folder is a parent of any already included folders
        const isParent = result.some((existingFolder) =>
          existingFolder.startsWith(folder + "/")
        );

        // Check if this folder is a child of any already included folders
        const isChild = result.some((existingFolder) =>
          folder.startsWith(existingFolder + "/")
        );

        // Only add if it's not a parent or child of existing folders
        if (!isParent && !isChild) {
          result.push(folder);
        }
      }

      return result;
    },
    async getFolders() {
      var myHeaders = new Headers();
      try {
        var token = await this.$authInstance.getToken();

        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var response = await fetch(`${url}/folders`, requestOptions);
        var jsonResponse = await response.json();
        this.folderOptions = jsonResponse;
        //this.treeNodes = await this.turnIntoNodes(jsonResponse)
        this.folderObject = { category: "Documentation", files: jsonResponse };
      } catch (e) {
        this.folderOptions = [];
      }
    },
    async turnIntoNodes(folderArray) {
      // Helper function to create a node
      const createNode = (key, label, isFile = false) => ({
        key,
        label,
        data: key,
        icon: isFile ? "pi pi-fw pi-file" : "pi pi-fw pi-folder",
        children: [],
      });

      // Create root node
      const root = createNode("0", "Documents");

      for (let path of folderArray) {
        const parts = path.split("/");
        let currentLevel = root.children;
        let currentPath = "";

        // Iterate through each part of the path
        parts.forEach((part, index) => {
          currentPath = currentPath ? `${currentPath}/${part}` : part;
          const isFile = index === parts.length - 1 && part.includes(".");

          // Check if node already exists at current level
          let existingNode = currentLevel.find((node) => node.label === part);

          if (!existingNode) {
            // Create new node
            existingNode = createNode(currentPath, part, isFile);
            currentLevel.push(existingNode);
          }

          // Move to next level
          currentLevel = existingNode.children;
        });
      }

      return [root];
    },
    async generateContent() {
      this.loading = true;
      this.contentComplete = false;
      this.generatedFileName = null;
      await this.$nextTick(() => {
        this.streamingContent = "Generating content...";
      });
      var myHeaders = new Headers();
      try {
        let org = await this.$authInstance.getOrg();
        let token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");
        let sub_folder = "docs";
        let githubRepository = this.githubRepository;

        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        let folders = this.codeFiles["Documentation"];
        folders = findParentPaths(folders);
        let codeObjects = this.codeFiles;
        delete codeObjects["Documentation"];
        let message = `${this.value}.  Please respond with the anwser to the prompt in <response> </response> tags`;
        let page = false;
        let publishedBranch = localStorage.getItem("publishedBranch") || "main";
        const requestBody = {
          branch: publishedBranch,
          codeObjects,
          folders,
          githubRepository,
          message,
          page,
        };
        const raw = JSON.stringify(requestBody);
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        let url = await this.$authInstance.getBaseUrl();
        const { body } = await fetch(`${url}/ai/messages`, requestOptions);
        let accumulatedText = "";
        const reader = body.getReader();
        const decoder = new TextDecoder();
        await this.$nextTick(() => {
          this.streamingContent = "";
        });
        let done, value;
        let result;

        while (true) {
          const readerRead = await reader.read();
          ({ done, value } = readerRead);
          if (done) {
            // Process the complete accumulated text after stream ends
            const responseMatch = accumulatedText.match(
              /<response>(.*?)<\/response>/s
            );
            if (responseMatch) {
              const cleanContent = responseMatch[1].trim();
              this.finalContent = cleanContent;
              await this.$nextTick(() => {
                this.streamingContent = cleanContent;
                this.contentComplete = true;
              });
       
              // After content is generated, generate the title
              let fileName = await this.generateTitle(cleanContent);
             let streamingResponse = {
                    id: Date.now(),
                    content: cleanContent,
                    fileName,
                    complete: true
              };
              
            this.streamingResponses.push(streamingResponse);
            }
            break;
          }

          const decoded = decoder.decode(value);
          try {
            const json = JSON.parse(decoded);
            result = json;
          } catch (e) {
            accumulatedText += decoded;
            await this.$nextTick(() => {
              this.streamingContent = accumulatedText;
            });
          }
        }
      } catch (e) {
        console.log(e);
        return {};
      }
    },
    async generateTitle(content) {
      this.generatingTitle = true;
      var myHeaders = new Headers();
      try {
        let token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        const message = `Based on the following content, suggest an appropriate file path and name (including .md extension) for this documentation. The path should start with either 'docs/' or 'blog/' depending on if this is documentation or a blog post. Make the path descriptive but concise. Only respond with the path, nothing else. Example responses: "docs/getting-started.md" or "blog/new-features-release.md". Here's the content to evaluate: ${content}`;

        const requestBody = {
          message,
          githubRepository: this.githubRepository,
        };
        const raw = JSON.stringify(requestBody);
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        let url = await this.$authInstance.getBaseUrl();
        const response = await fetch(`${url}/ai/messages`, requestOptions);
        const { body } = response;
        const reader = body.getReader();
        const decoder = new TextDecoder();
        let accumulatedText = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const decoded = decoder.decode(value);
          accumulatedText += decoded;

          // Try to extract a clean file path
          const cleanPath = accumulatedText
            .trim()
            .replace(/<[^>]*>/g, "")
            .trim();
          if (cleanPath.match(/^(docs|blog)\/[\w-]+\.md$/)) {
            this.generatedFileName = cleanPath;
            this.generatingTitle = false;
            return cleanPath;
          }
        }

        this.generatingTitle = false;
      } catch (e) {
        console.error("Error generating title:", e);
        this.generatingTitle = false;
      }
    },
    async updateBlock() {
      let content = this.draftContent;
      // this.updateAttributes({ saved: true })
      var myHeaders = new Headers();
      try {
        var org = await this.$authInstance.getOrg();
        var token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }
        var raw = JSON.stringify({
          message: options.historicalMessage || this.command,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var saveResponseTwo = await fetch(
          `${url}/proxy_url/message`,
          requestOptions
        );
        var result = await saveResponseTwo.json();

        this.output = result?.content;
        this.command = "";
      } catch (e) {}
    },
    async fetchOpenApi() {
      try {
        let org = await this.$authInstance.getOrg();
        let token = await this.$authInstance.getToken();
        let url = await this.$authInstance.getBaseUrl();
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const response = await fetch(`${url}/openapi`, requestOptions);
        const result = await response.json();
        console.log("what is the result", result);
        return result.configs;
      } catch (e) {
        return {};
      }
    },
    async handleAccept(data) {
      try {
        let publishedBranch = localStorage.getItem('publishedBranch') || 'main';
        const result = {
          content: this.finalContent,
          path: this.generatedFileName,
          branch: publishedBranch
        };
        let newFiles = [this.generatedFileName];
        localStorage.setItem('newFiles', JSON.stringify(newFiles));
        // Navigate to the editor with the new content
        this.$router.replace({
          query: {
            branch: encodeURIComponent(publishedBranch || ''),
            file: encodeURIComponent(this.generatedFileName || ''),
            repo: encodeURIComponent(this.githubRepository || '')
          }
        });

        // Emit the draft event with the result
        this.$emit('draft', result);

        // Reset the form
        this.value = '';
        this.loading = false;
      } catch (e) {
        console.error('Error accepting content:', e);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to save the content',
          life: 3000
        });
      }
    },
    handleRevert() {
      // Clear generated content and return to prompt
      this.loading = false;
      this.contentComplete = false;
      this.generatedFileName = null;
      this.streamingContent = '';
      this.finalContent = '';
    },
    handleDiscard() {
      // Reset everything and return to initial state
      this.loading = false;
      this.contentComplete = false;
      this.generatedFileName = null;
      this.streamingContent = '';
      this.finalContent = '';
      this.value = '';
      this.streamingResponses = [];
    },
    async checkCachedContent() {
      try {
        const cachedData = await indexDbService.getTableData('generate_content_cached_docs');
        console.log("cachedData", cachedData);
        if (cachedData) {
          this.hasCachedContent = true;
          // Check if cachedData.result is an array, if not, wrap it in an array
          if (cachedData.result) {
            this.streamingResponses = Array.isArray(cachedData.result) 
              ? cachedData.result 
              : [cachedData.result];
          } else {
            // If the structure is different, try to handle it appropriately
            this.streamingResponses = Array.isArray(cachedData) 
              ? cachedData 
              : [cachedData];
          }
          console.log("cachedData", cachedData.result);
          console.log("streamingResponses", this.streamingResponses);
        }
      } catch (e) {
        console.warn('Error checking cached content:', e);
      }
    },
    async handleLoadCached(cache) {
      try {
        // Set the content and file name from the cached data
        const cacheData = cache.result || cache;
        const content = cacheData.content;
        const fileName = cacheData.fileName || cacheData.path;
        
        if (content) {
          this.streamingContent = content;
          this.generatedFileName = fileName;
          this.contentComplete = true;
          this.loading = true;
          
          // Update the UI to show the loaded content
          this.$nextTick(() => {
            this.toast.add({
              severity: 'success',
              summary: 'Loaded',
              detail: 'Successfully loaded cached content',
              life: 3000
            });
          });
        } else {
          console.error('Cached content is missing required data');
          this.toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Cached content is missing required data',
            life: 3000
          });
        }
      } catch (e) {
        console.error('Error loading cached content:', e);
        this.toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load cached content',
          life: 3000
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.mini-editor-container) {
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 0.5rem;
  background: #1c1c1c;
  min-height: 200px;
}

:deep(.mini-editor-content) {
  .ProseMirror {
    min-height: 200px;
    padding: 1rem;
    outline: none;
    color: white;
    font-family: "Inter", sans-serif;

    &:focus {
      outline: none;
    }

    p {
      margin-bottom: 0.5rem;
    }

    h1,
    h2,
    h3 {
      color: white;
      margin-bottom: 1rem;
    }

    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 1rem;
    }

    code {
      background-color: #2c2c2c;
      padding: 0.2rem 0.4rem;
      border-radius: 0.25rem;
      font-family: "Fira Code", monospace;
    }

    pre {
      background: rgba(17, 25, 40, 0.6);
      padding: 1rem;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
    }

    a {
      color: #7984eb;
      text-decoration: underline;
    }

    table {
      border-collapse: collapse;
      width: 100%;
      margin-bottom: 1rem;

      td,
      th {
        border: 1px solid rgba(255, 255, 255, 0.12);
        padding: 0.5rem;
      }
    }
  }
}

:deep(.p-button) {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

:deep(.pi) {
  font-size: 1rem;
}
</style>

<template>
    <div
        class="flex flex-col items-start p-[0.94rem] bg-[#1C1C1C] rounded-[0.5rem] gap-[0.31rem]"
        ref="dropdownContainer"
    >
        <div
            class="text-[#A2A1A5] font-medium"
        >
            Format
        </div>

        <button
            @click="() => {
                removeSlash();
                editor.chain().focus().toggleHeading({ level: 1 }).run();
            }"
            :class="[
                'hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] flex flex-row items-center gap-[0.62rem] px-[0.62rem] py-[0.31rem] w-full',
                editor.isActive('heading', { level: 1 }) ? 'bg-white/20' : '',
            ]"
        >
            <div
                class="text-[#B8F171] flex flex-row gap-0 items-end"
            >
                <span
                    class="text-[0.88rem]"
                >
                    H
                </span>
                <span
                    class="text-[0.63rem]"
                >
                    1
                </span>
            </div>
            <div>
                Heading 1
            </div>
        </button>

        <button
            @click="() => {
                removeSlash();
                editor.chain().focus().toggleHeading({ level: 2 }).run();
            }"
            :class="[
                'hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] flex flex-row items-center gap-[0.62rem] px-[0.62rem] py-[0.31rem] w-full',
                editor.isActive('heading', { level: 2 }) ? 'bg-white/20' : '',
            ]"
        >
            <div
                class="text-[#B8F171] flex flex-row gap-0 items-end"
            >
                <span
                    class="text-[0.88rem]"
                >
                    H
                </span>
                <span
                    class="text-[0.63rem]"
                >
                    2
                </span>
            </div>
            <div>
                Heading 2
            </div>
        </button>

        <button
            @click="() => {
                removeSlash();
                editor.chain().focus().toggleHeading({ level: 3 }).run();
            }"
            :class="[
                'hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] flex flex-row items-center gap-[0.62rem] px-[0.62rem] py-[0.31rem] w-full',
                editor.isActive('heading', { level: 3 }) ? 'bg-white/20' : '',
            ]"
        >
            <div
                class="text-[#B8F171] flex flex-row gap-0 items-end"
            >
                <span
                    class="text-[0.88rem]"
                >
                    H
                </span>
                <span
                    class="text-[0.63rem]"
                >
                    3
                </span>
            </div>
            <div>
                Heading 3
            </div>
        </button>

        <button
            @click="() => {
                removeSlash();
                editor.chain().focus().toggleBulletList().run();
            }"
            :class="[
                'hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] flex flex-row items-center gap-[0.62rem] px-[0.62rem] py-[0.31rem] w-full',
                editor.isActive('bulletList') ? 'bg-white/20' : '',
            ]"
        >
            <img
                class="w-[1rem] h-[1rem]"
                :src="bulletListIcon"
                alt="bullet list"
            />
            <div>
                Bullet list
            </div>
        </button>

        <button
            @click="() => {
                removeSlash();
                editor.chain().focus().toggleOrderedList().run();
            }"
            :class="[
                'hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] flex flex-row items-center gap-[0.62rem] px-[0.62rem] py-[0.31rem] w-full',
                editor.isActive('orderedList') ? 'bg-white/20' : '',
            ]"
        >
            <img
                class="w-[1rem] h-[1rem]"
                :src="numberListIcon"
                alt="number list"
            />
            <div>
                Number list
            </div>
        </button>
        <button
            @click="() => {
                removeSlash();
                editor.chain().focus().toggleCodeBlock().run();
            }"
            :class="[
                'hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] flex flex-row items-center gap-[0.62rem] px-[0.62rem] py-[0.31rem] w-full',
                editor.isActive('codeBlock') ? 'bg-white/20' : '',
            ]"
        >
            <img
                class="w-[1rem] h-[1rem]"
                :src="codeIcon"
                alt="code"
            />
            <div>
                Code block
            </div>
        </button>
        <div
            class="bg-[rgba(162,161,165,0.25)] w-full h-[0.0625rem] my-[0.94rem]"
        />
        <button
            @click="() => {
                removeSlash();
                editor.chain().focus().setImage({ src: 'upload' }).run();
            }"
            :class="[
                'hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] flex flex-row items-center gap-[0.62rem] px-[0.62rem] py-[0.31rem] w-full',
                editor.isActive('codeBlock') ? 'bg-white/20' : '',
            ]"
        >
            <img
                class="w-[1rem] h-[1rem]"
                :src="imageIcon"
                alt="code"
            />
            <div>
                Add Image
            </div>
        </button>
        <button
            @click="() => {
                removeSlash();
                editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run();
            }"
            :class="[
                'hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] flex flex-row items-center gap-[0.62rem] px-[0.62rem] py-[0.31rem] w-full',
                editor.isActive('codeBlock') ? 'bg-white/20' : '',
            ]"
        >
            <img
                class="w-[1rem] h-[1rem]"
                :src="tableIcon"
                alt="code"
            />
            <div>
                Add Table
            </div>
        </button>

        <!-- <button
            @click=""
            :class="[
                'hover:bg-white/10 p-[0.31rem] rounded-[0.5rem] flex flex-row items-center gap-[0.62rem] px-[0.62rem] py-[0.31rem] w-full',
            ]"
        >
            <img
                class="w-[1rem] h-[1rem]"
                :src="greenLinkIcon"
                alt="link"
            />
            <div>
                Link
            </div>
        </button> -->

        <div
            class="bg-[rgba(162,161,165,0.25)] w-full h-[0.0625rem] my-[0.94rem]"
        />

        <AskAiButton class="w-full" :editor="editor" />
    </div>
</template>

<script setup>
import bulletListIcon from '@/assets/icon-list-bullet.svg';
import numberListIcon from '@/assets/icon-list-number.svg';
import codeIcon from '@/assets/icon-code.svg';
import imageIcon from '@/assets/icon-image.svg';
import tableIcon from '@/assets/icon-table.svg';
import greenLinkIcon from '@/assets/icon-link-green.svg';
import purpleAiIcon from '@/assets/icon-ai-purple.svg';

import AskAiButton from '@/components/EditorComponents/AskAiButton.vue';
import { ref, onMounted, onUpdated } from 'vue';

const props = defineProps({
    editor: {
        type: Object,
        required: true
    }
});

const dropdownContainer = ref(null);

const removeSlash = () => {
    const resolvedPos = props.editor.state.doc.resolve(props.editor.state.selection.from);
    const lineStart = resolvedPos.before();
    let lineEnd = resolvedPos.after();
    const docSize = props.editor.state.doc.content.size;
    if (lineEnd > docSize) {
        lineEnd = docSize
    }

    const lineContent = props.editor.state.doc.textBetween(
        lineStart,
        lineEnd,
        '\n', // Block separator
        ' ',  // Leaf separator
    );

    if (lineContent === '/') {
        const tr = props.editor.state.tr.delete(lineStart, lineEnd);
        props.editor.view.dispatch(tr);
    }
}

// Function to adjust position if needed
const adjustPosition = () => {
    if (!dropdownContainer.value) return;
    
    const container = dropdownContainer.value;
    const rect = container.getBoundingClientRect();
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    
    // Check if dropdown extends beyond right edge
    if (rect.right > viewportWidth) {
        container.style.left = 'auto';
        container.style.right = '0';
    }
    
    // Check if dropdown extends beyond bottom edge
    if (rect.bottom > viewportHeight) {
        container.style.top = 'auto';
        container.style.bottom = '100%';
        container.style.marginTop = '0';
        container.style.marginBottom = '1px';
    }
};

onMounted(() => {
    adjustPosition();
});

onUpdated(() => {
    adjustPosition();
});
</script>

<style scoped>
</style>
<template>
  <Dialog 
    v-model:visible="show" 
    modal 
    header="Create Organization" 
    :style="{ width: '450px' }"
    :closable="false"
    :dismissableMask="false"
  >
    <div class="p-fluid">
      <p>To continue, you need to create an organization.</p>
      <div class="p-field">
        <label for="org-name">Organization Name</label>
        <InputText 
          id="org-name" 
          v-model="orgName" 
          placeholder="Enter organization name"
          @input="validateInput"
          :class="{ 'p-invalid': validationError }"
        />
        <small class="p-helper-text">Use only lowercase alphanumeric characters and hyphens.</small>
        <small v-if="validationError" class="p-error">{{ validationError }}</small>
      </div>
    </div>
    <template #footer>
      <Button 
        label="Cancel" 
        icon="pi pi-times" 
        @click="$emit('cancel')" 
        class="p-button-text"
      />
      <Button 
        label="Create Organization" 
        icon="pi pi-check" 
        @click="submitOrg" 
        :disabled="!isValid"
        autofocus
      />
    </template>
  </Dialog>
</template>

<script>
import { defineComponent, ref, watch } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

export default defineComponent({
  name: 'OrganizationDialog',
  components: {
    Dialog,
    Button,
    InputText
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  emits: ['create', 'cancel', 'update:show'],
  setup(props, { emit }) {
    const orgName = ref('');
    const validationError = ref('');
    const isValid = ref(false);
    
    const validateInput = () => {
      const regex = /[^a-z0-9-]+/;
      const value = orgName.value.toLowerCase().trim();
      
      if (value === '') {
        validationError.value = 'Organization name is required';
        isValid.value = false;
      } else if (regex.test(value)) {
        validationError.value = 'Please use only lowercase alphanumeric characters and hyphens';
        isValid.value = false;
      } else {
        validationError.value = '';
        isValid.value = true;
      }
    };

    const submitOrg = () => {
      validateInput();
      if (isValid.value) {
        emit('create', orgName.value.toLowerCase().trim());
        orgName.value = '';
      }
    };

    // Reset form when dialog is opened
    watch(() => props.show, (newVal) => {
      if (newVal) {
        orgName.value = '';
        validationError.value = '';
        isValid.value = false;
      }
    });

    return {
      orgName,
      validationError,
      isValid,
      validateInput,
      submitOrg
    };
  }
});
</script>

<style scoped>
.p-dialog-header {
  background-color: rgba(10, 10, 10, 0.9) !important;
  color: #ebebeb !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.p-dialog-content {
  background-color: rgba(10, 10, 10, 0.9) !important;
  color: #ebebeb !important;
  padding: 1.5rem !important;
}

.p-dialog-footer {
  background-color: rgba(10, 10, 10, 0.9) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
  padding: 1.5rem !important;
}

.p-field {
  margin-top: 1rem;
}

.p-field label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.p-helper-text {
  color: #a2a1a5;
}

.p-error {
  color: #ff6b6b;
}

/* Override PrimeVue's default input styling */
.p-inputtext {
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 1px solid rgba(255, 255, 255, 0.2) !important;
  color: #ffffff !important;
}

.p-inputtext:focus {
  box-shadow: 0 0 0 1px #6a78fb !important;
  border-color: #6a78fb !important;
}

.p-inputtext.p-invalid {
  border-color: #ff6b6b !important;
}
</style> 
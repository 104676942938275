<template>
  <Button style="color: #888888; width: 100%; max-width: 100%;" @click="showSearchDialog = true" text>
    <IconField style="width: 100%;">
      <InputIcon class="pi pi-search" />
      <InputText style="width: 100%;" placeholder="Search" autofocus="false" :tabindex="-1" />
    </IconField>
  </Button>
  <Dialog :header="title" :style="{ width: '50rem' }" v-model:visible="showSearchDialog">
    <div class="flex justify-between items-center mb-3">
      <Button 
        @click="toggleMultiSelectMode" 
        :class="{ 'p-button-outlined': isMultiSelectMode }" 
        class="p-0" 
        text
      >
        <i class="pi pi-list" style="color: #888888" />
      </Button>
      <Button 
        v-if="isMultiSelectMode" 
        @click="toggleSelectAll" 
        class="p-0" 
        text
      >
        <i :class="['pi', isAllSelected ? 'pi-delete-left' : 'pi-check-square']" style="color: #888888" />
      </Button>
    </div>

    <Listbox
      v-model="selectedPublishedDraft"
      :options="items"
      filter
      class="w-full"
      :virtualScrollerOptions="{ itemSize: 80 }"
      listStyle="height:200px"
      :multiple="isMultiSelectMode"
    >
      <template #option="slotProps">
        <div class="flex items-center justify-between w-full">
          <router-link v-if="!isMultiSelectMode"
            @click="handleLinkClick"
            :key="slotProps.option"
            :to="{
              path: '/editor',
              query: {
                file: slotProps.option,
                branch: branch,
                repo: `${owner}%2F${repo}`,
              },
            }"
            class="text-[0.875rem] hover:text-white cursor-pointer"
          >
            <span class="pi pi-file-edit mr-2" />
            <span v-tooltip.left="slotProps.option">{{
              removeFileExtension(slotProps.option)
            }}</span>
          </router-link>
          <div v-else class="flex items-center w-full">
            <Checkbox 
              v-model="selectedItems" 
              :value="slotProps.option"
              :binary="false"
              class="mr-2"
            />
            <span class="text-[0.875rem]">{{ removeFileExtension(slotProps.option) }}</span>
          </div>
        </div>
      </template>
    </Listbox>

    <div v-if="isMultiSelectMode && selectedItems.length > 0" class="mt-3 flex justify-end">
      <Button 
        @click="bulkDeleteDrafts" 
        severity="danger" 
        :badge="selectedItems.length.toString()"
      >
        Delete Selected
      </Button>
    </div>
  </Dialog>
  <Toast />
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
const showSearchDialog = ref(false);
const selectedPublishedDraft = ref(null);
const router = useRouter();
const route = useRoute();
const isMultiSelectMode = ref(false);
const selectedItems = ref([]);
import {
  deleteFile
} from "@/plugins/devdocsBackendService.js";
import { useToast } from "primevue/usetoast";

const toast = useToast();

watch(
  () => route,
  async (to, from) => {
    showSearchDialog.value = false;
  },
  { deep: true }
);

const removeFileExtension = (filename) => {
    return filename ? filename.replace(/\.[^/.]+$/, '') : filename;
};

const handleLinkClick = () => {
      setTimeout(() => {
      showSearchDialog.value = false;
    }, 300);
};

const toggleMultiSelectMode = () => {
  isMultiSelectMode.value = !isMultiSelectMode.value;
  if (!isMultiSelectMode.value) {
    selectedItems.value = [];
  }
};

const isAllSelected = computed(() => {
  return props.items.length > 0 && selectedItems.value.length === props.items.length;
});

const toggleSelectAll = () => {
  if (isAllSelected.value) {
    selectedItems.value = [];
  } else {
    selectedItems.value = [...props.items];
  }
};



const bulkDeleteDrafts = async () => {
  try {
    await Promise.all(
        selectedItems.value.map(fileName =>
        deleteFile({
          branch: props.branch,
          fileName,
          owner: props.owner,
          repo: props.repo,
        })
      )
    );

    toast.add({
      severity: "success",
      summary: "Success",
      detail: `Successfully deleted ${selectedItems.value.length} drafts`,
      life: 3000,
    });

    selectedItems.value = [];
    isMultiSelectMode.value = false;
  } catch (error) {
    console.error("Error deleting drafts:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to delete some drafts",
      life: 3000,
    });
  }
};



const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  branch: {
    type: String,
    required: true,
  },
  owner: {
    type: String,
    required: true,
  },
  repo: {
    type: String,
    required: true,
  },
  repoUrl: {
    type: String,
    required: true,
  },
  onBulkDelete: {
    type: Function,
    required: true
  }
});

</script>

<style scoped>
.p-listbox-item {
  margin-bottom: 0.5rem;
}
</style>

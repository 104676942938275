<template>
  <div class="github-callback">
    <div class="card">
      <div v-if="loading && !showOrgDialog" class="loading-container">
        <div class="spinner"></div>
        <h2>Authenticating with GitHub...</h2>
        <p>Please wait while we complete your authentication.</p>
      </div>
      <div v-if="error" class="error-container">
        <h2>Authentication Error</h2>
        <p>{{ errorMessage }}</p>
        <button @click="goToHome" class="btn-primary">Go Back</button>
      </div>
    </div>
    
    <!-- Organization Dialog -->
    <OrganizationDialog 
      :show="showOrgDialog" 
      @create="handleOrgCreate" 
      @cancel="handleOrgCancel"
    />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { authInfo } from "../plugins/authn.js";
import OrganizationDialog from "../components/OrganizationDialog.vue";

export default defineComponent({
  name: "GitHubCallback",
  components: {
    OrganizationDialog
  },
  props: {
    code: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const router = useRouter();
    const loading = ref(true);
    const error = ref(false);
    const errorMessage = ref("");
    const org_name = ref("");
    const showOrgDialog = ref(false);
    
    const handleOrgCreate = async (orgName) => {
      org_name.value = orgName;
      await createOrg();
    };

    const handleOrgCancel = () => {
      // When user cancels, we should redirect them to home
      router.push("/");
    };

    const createOrg = async () => {
      // Validation check
      let teamName = org_name.value.toLowerCase().trimEnd().trimStart();
      let orgName = org_name.value.toLowerCase().trimEnd().trimStart();
      
      // Rest of your code
      var myHeaders = new Headers();
      var idtoken = await authInfo.getToken();
      myHeaders.append("Authorization", `Bearer ${idtoken}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        org_name: orgName,
        team_name: teamName,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      var url = await authInfo.getUrl();
      var response = await fetch(`${url}/org`, requestOptions);
      var responseJson = await response.json();
      console.log("what is the response json", responseJson);

      var idtoken = await authInfo.renew();
      if (responseJson.message == "ALREADY_EXISTS") {
        window.alert("Current Org Name Already Exists, Try Different Name");
        showOrgDialog.value = true; // Show dialog again if org name exists
      } else {
        await window.localStorage.setItem("org", org_name.value);
        
        // Save authenticated user info
        localStorage.setItem("user_signed_in", "true");
        localStorage.setItem("auth_provider", "github");
        await authInfo.renew();
        window.location.href = "/generate-docs-instantly";
        // let href = `${window.location.origin}/users_docs`;
        // const redirect = window.localStorage.getItem("start_redirect");
        // if (redirect) {
        //   href = redirect;
        //   window.localStorage.removeItem("start_redirect");
        // }
        // window.location.href = href;
      }
    };

    const handleOAuthCallback = async () => {
      try {
        // Check for CSRF protection
        const savedState = localStorage.getItem("github_oauth_state");
        localStorage.removeItem("github_oauth_state");

        // Verify state to prevent CSRF attacks
        if (!props.state || props.state !== savedState) {
          throw new Error("OAuth state mismatch - possible CSRF attack");
        }

        if (!props.code) {
          throw new Error("No authorization code received");
        }

        // Configure redirect URI (same as in the initial request)
        const redirectUri = `${window.location.origin}/auth/github/callback`;

        // Exchange the code for an access token
        let baseUrl = await authInfo.getUrl();
        const response = await fetch(`${baseUrl}/auth/github/token`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code: props.code,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(
            errorData.error || "Failed to exchange code for token"
          );
        }

        const data = await response.json();

        if (!data.access_token) {
          throw new Error("No access token received");
        }

        // Store the token securely
        await localStorage.setItem("github_access_token", data.access_token);
        await localStorage.setItem("github_user", JSON.stringify(data.userinfo));
        await localStorage.setItem("github_user_id", data.userinfo.id);
        await localStorage.setItem("token", data.devDocsToken);
        await localStorage.setItem("refresh_token", data.devDocsRefreshToken);

        let orgs = await authInfo.getOrgs();

        if (!orgs?.length > 0) {
          // Show organization dialog instead of auto-creating
          showOrgDialog.value = true;
          loading.value = false;
        } else {
          await authInfo.tryToSetOrg(data.devDocsToken);
          
          // Save authenticated user info
          localStorage.setItem("user_signed_in", "true");
          localStorage.setItem("auth_provider", "github");

          // Process any saved query
          const savedQuery = localStorage.getItem("docs_ai_query");
          if (savedQuery) {
            console.log("Processing saved query after GitHub auth:", savedQuery);
            // Here we would process the query
          }

          // Get redirect path
          const redirectPath =
            localStorage.getItem("login_redirect") || "/users_docs";
          localStorage.removeItem("login_redirect");

          // Redirect to dashboard or previous page
          router.push(redirectPath);
        }
      } catch (error) {
        console.error("GitHub authentication error:", error);
        
        // Check if localStorage items are already set despite the error
        const hasAccessToken = localStorage.getItem("github_access_token");
        const hasUserInfo = localStorage.getItem("github_user");
        const hasUserId = localStorage.getItem("github_user_id"); 
        const hasToken = localStorage.getItem("token");
        const hasRefreshToken = localStorage.getItem("refresh_token");
        
        if (hasAccessToken && hasUserInfo && hasUserId && hasToken && hasRefreshToken) {
          console.log("Authentication items already exist in localStorage, proceeding with session");
          
          // Check if user has organizations
          let orgs = await authInfo.getOrgs();
          
          if (!orgs?.length > 0) {
            // Show organization dialog if no orgs exist
            showOrgDialog.value = true;
            loading.value = false;
          } else {
            // Set organization and redirect
            await authInfo.tryToSetOrg(hasToken);
            
            // Save authenticated user info
            localStorage.setItem("user_signed_in", "true");
            localStorage.setItem("auth_provider", "github");
            
            // Get redirect path
            const redirectPath = localStorage.getItem("login_redirect") || "/users_docs";
            localStorage.removeItem("login_redirect");
            
            // Redirect to dashboard or previous page
            router.push(redirectPath);
          }
        } else {
          // Show the error if we don't have all required auth data
          error.value = true;
          errorMessage.value = error.message || "Failed to authenticate with GitHub";
          loading.value = false;
        }
      }
    };

    const goToHome = () => {
      router.push("/");
    };

    onMounted(async () => {
      // Process the callback
      await handleOAuthCallback();
    });

    return {
      loading,
      error,
      errorMessage,
      goToHome,
      showOrgDialog,
      handleOrgCreate,
      handleOrgCancel
    };
  },
});
</script>

<style scoped>
.github-callback {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #000000;
  color: #ebebeb;
}

.card {
  max-width: 500px;
  width: 90%;
  padding: 2rem;
  border-radius: 8px;
  background-color: rgba(10, 10, 10, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.loading-container,
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: #6a78fb;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem 0;
}

p {
  color: #a2a1a5;
  margin-bottom: 1.5rem;
}

.btn-primary {
  background-color: #6a78fb;
  color: white;
  border: none;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}
</style>

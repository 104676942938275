<template>
    <div v-if="streams.length > 0" class="streaming-preview-carousel">
        <div class="carousel-header flex justify-between items-center mb-4">
            <span class="text-sm text-gray-400">
                Processing {{ streams.length }} document{{ streams.length > 1 ? 's' : '' }} in parallel
            </span>
        </div>

        <!-- Navigation tabs -->
        <div class="carousel-tabs flex flex-wrap gap-2 mb-4">
            <div 
                v-for="(stream, index) in streams" 
                :key="index"
                @click="activeIndex = index"
                :class="['tab-item', { 'active': activeIndex === index }]"
            >
                {{ stream.fileName || `Document ${index + 1}` }}
                <span v-if="stream.status" :class="['status-indicator', stream.status]"></span>
            </div>
        </div>

        <!-- Active stream content -->
        <div class="active-stream-container">
            <div class="stream-info-header flex justify-between items-center mb-2">
                <div class="file-info">
                    <span class="file-name">{{ activeStream.fileName || 'Document' }}</span>
                    <span v-if="activeStream.reason" class="reason ml-2 text-gray-400">({{ activeStream.reason }})</span>
                </div>
                <div class="status-badge" v-if="activeStream.status">
                    {{ getStatusText(activeStream.status) }}
                </div>
            </div>
            <StreamingPreview 
                :content="activeStream.content" 
                :key="activeIndex" 
            />
        </div>
    </div>
</template>

<script>
import StreamingPreview from './StreamingPreview.vue';

export default {
    name: 'StreamingPreviewCarousel',
    components: {
        StreamingPreview
    },
    props: {
        streams: {
            type: Array,
            default: () => [],
            // Expected format:
            // [
            //   { 
            //     id: String|Number,
            //     fileName: String,
            //     content: String,
            //     status: 'pending'|'processing'|'complete'|'error',
            //     reason: String
            //   }
            // ]
        }
    },
    data() {
        return {
            activeIndex: 0
        };
    },
    computed: {
        activeStream() {
            return this.streams[this.activeIndex] || { content: '' };
        }
    },
    watch: {
        streams: {
            handler(newStreams) {
                // If the current active index is no longer valid, reset to the first item
                if (newStreams.length <= this.activeIndex) {
                    this.activeIndex = Math.max(0, newStreams.length - 1);
                }
            },
            deep: true
        }
    },
    methods: {
        getStatusText(status) {
            const statusMap = {
                'pending': 'Pending',
                'processing': 'Processing...',
                'complete': 'Complete',
                'error': 'Error'
            };
            return statusMap[status] || status;
        }
    },
    emits: ['refresh', 'clear']
};
</script>

<style scoped>
.streaming-preview-carousel {
    background-color: #1C1C1C;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
    padding: 1rem;
}

.carousel-header {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.carousel-tabs {
    overflow-x: auto;
    white-space: nowrap;
    padding-bottom: 0.5rem;
}

.tab-item {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    background-color: rgba(255, 255, 255, 0.05);
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    position: relative;
}

.tab-item.active {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.status-indicator {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    margin-left: 0.5rem;
}

.status-indicator.pending {
    background-color: #FFC107;
}

.status-indicator.processing {
    background-color: #2196F3;
    animation: pulse 1.5s infinite;
}

.status-indicator.complete {
    background-color: #4CAF50;
}

.status-indicator.error {
    background-color: #F44336;
}

.stream-info-header {
    padding: 0.5rem 0;
}

.file-name {
    font-weight: 500;
    color: #fff;
}

.status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    background-color: rgba(255, 255, 255, 0.1);
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.6;
    }
}
</style> 
<template>
    <div>
        <div v-if="analysisInfo">
            <div class="flex items-center justify-between mb-4">
                <span class="text-sm text-gray-400" v-if="isLoadedFromCache">
                    Using cached data
                </span>
                <div class="flex gap-2">
                    <Button icon="pi pi-refresh" @click="refreshData" label="Run Again"
                        class="text-white p-button-sm" text />
                    <Button icon="pi pi-trash" @click="clearData" label="Clear" class="text-white p-button-sm"
                        text />
                </div>
            </div>
            <ScrollPanel style="width: 100%; height: 100vh">
                <PersonaAnalysis v-if="personaAnalysis" @update="refreshEditor" :branch="draftBranch"
                    :published-branch="publishedBranch" analysisObjectsKey="personas_addressed_in_docs"
                    title="Persona analysis" keyValue="persona" :data="personaAnalysis" />
                <PersonaAnalysis v-if="seoAnalysis" :branch="draftBranch" @update="refreshEditor" :published-branch="publishedBranch"
                    analysisObjectsKey="seo_keywords_analysis" title="SEO analysis" keyValue="keyword"
                    :data="seoAnalysis" />
                <div v-if="batchUpdateResults?.length" class="mt-4 card">
                    <h3>File Creation Results</h3>
                    <div v-for="result in batchUpdateResults" :key="result.path" class="flex items-center gap-2 mb-2">
                        <i class="text-green-500 pi pi-check" v-if="result.status === 'completed'"></i>
                        <span>{{ result.path }} - {{ result.action }}</span>
                    </div>
                </div>
            </ScrollPanel>
        </div>
        <div v-else class="flex flex-col p-fluid gap-[1.25rem] mt-[1.25rem]">
            <div v-if="showAnalysisButton" class="flex flex-col self-end justify-end w-full">
                <label class="mb-4 p-card-subtitle">Analyze your documentation content based on users persona as well as SEO to help uplevel your docs and fill in missing gaps</label>
                <Button label="Analyze" @click="analyzeContent" class="w-fit" />
            </div>
        </div>

      

        <div class="flex flex-col gap-4">
            <div v-if="loading" class="bg-[#1C1C1C] rounded-lg p-4 min-h-[200px] font-mono text-white whitespace-pre-wrap">
                <div class="mb-4">Analysis in progress...</div>
                <StreamingPreview :content="streamingContent" />
            </div>
        </div>
    </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';
import ScrollPanel from 'primevue/scrollpanel';
import indexDbService from '../plugins/indexDbService.js';
import { getOwnerAndRepo, getFileContent, generateLLMTxt } from '../plugins/devdocsBackendService.js';
import PersonaAnalysis from './PersonaAnalysis.vue';
import StreamingPreview from './EditorComponents/StreamingPreview.vue';

export default {
    props: {
        draftBranch: {
            type: String,
            required: true
        },
        publishedBranch: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            showAnalysisButton: false,
            analysisInfo: null,
            additionalPersona: '',
            documentationRepo: null,
            personaAnalysis: null,
            seoAnalysis: null,
            isLoadedFromCache: false,
            batchUpdateLoading: false,
            batchUpdateResults: null,
            streamingContent: '',
        };
    },
    components: {
        Skeleton,
        ScrollPanel,
        PersonaAnalysis,
        StreamingPreview
    },
    async mounted() {
        this.loading = true;
        let documentationRepo = await getOwnerAndRepo();
        this.documentationRepo = documentationRepo
        await this.getLLMtxt(documentationRepo);
        await this.checkExistingData();
        this.loading = false;
        this.showAnalysisButton = true;
    },
    methods: {
        async checkExistingData() {
            const cachedData = await indexDbService.getTableData('analysisData');
            if (cachedData) {
                this.analysisInfo = cachedData.analysisInfo;
                this.personaAnalysis = cachedData.personaAnalysis;
                this.seoAnalysis = cachedData.seoAnalysis;
                this.isLoadedFromCache = true;
            }
        },
        async getLLMtxt(documentationRepo) {
            try {
            let { owner, repo } = documentationRepo;
            let llmTxtJSONFile;
            try {
                llmTxtJSONFile = await getFileContent({
                    branch: this.publishedBranch,
                    fileName: "llmText.json",
                    owner: owner,
                    repo: repo
                })
            } catch (error) {
                console.error('Error getting llmText.json:', error);
            }
            console.log("what is the llmTxtJSONFile", llmTxtJSONFile)
            if(!llmTxtJSONFile || llmTxtJSONFile?.error) {
                await generateLLMTxt(this.publishedBranch);
            }

            } catch (error) {
                console.error('Error getting llmText.json:', error);
            }
            return
        },
        async clearData() {
            this.analysisInfo = null;
            this.personaAnalysis = null;
            this.seoAnalysis = null;
            this.isLoadedFromCache = false;
            await indexDbService.clearStore();
            this.additionalPersona = '';
        },
        async refreshData() {
            this.analysisInfo = null;
            this.isLoadedFromCache = false;
            await this.analyzeContent();
        },
        refreshEditor() {
            this.$emit('draft');
        },
        async analyzeContent() {
            this.loading = true;
            try {
                const token = await this.$authInstance.getToken();
                const url = await this.$authInstance.getBaseUrl();

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        repo: this.documentationRepo.repo,
                        owner: this.documentationRepo.owner,
                        branch: this.publishedBranch,
                        additionalPersona: this.additionalPersona,
                        returnStream: true
                    })
                };

                const response = await fetch(`${url}/llm_txt/analysis`, requestOptions);
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let buffer = '';
                let collectedAnalysis = { personas: [], seo: [] };
                
                this.streamingContent = "";
                let accumulatedContent = [];

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        const lastChunk = decoder.decode();
                        buffer += lastChunk;
                        break;
                    }

                    const decoded = decoder.decode(value, { stream: true });
                    buffer += decoded;
                    
                    // Split by newlines and process each complete JSON object
                    const lines = buffer.split('\n');
                    buffer = lines.pop() || ''; // Keep the last incomplete line in the buffer
                    this.streamingContent += decoded;
                    for (const line of lines) {
                        if (line.trim()) {
                            try {
                                const data = JSON.parse(line);
                                
                                switch (data.type) {
                                    case 'status':
                                        this.streamingContent = JSON.stringify({ type: 'status', message: data.message }, null, 2);
                                        break;
                                    case 'progress':
                                        this.streamingContent = JSON.stringify({ type: 'progress', current: data.current, total: data.total }, null, 2);
                                        break;
                                    case 'analysis_stream':
                                        if (data.data && data.data.type === 'Buffer') {
                                            const bufferString = new TextDecoder().decode(new Uint8Array(data.data.data));
                                            console.log("bufferString", bufferString)
                                            try {
                                                const parsedData = JSON.parse(bufferString);
                                                if (parsedData.personas_addressed_in_docs) {
                                                    collectedAnalysis.personas = parsedData.personas_addressed_in_docs;
                                                }
                                                if (parsedData.seo_keywords_analysis) {
                                                    collectedAnalysis.seo = parsedData.seo_keywords_analysis;
                                                }
                                                this.streamingContent = JSON.stringify({
                                                    type: 'analysis_stream',
                                                    data: parsedData
                                                }, null, 2);
                                            } catch (e) {
                                                console.log("Error parsing buffer data:", e, bufferString);
                                            }
                                        } else {
                                            if (data.data.personas_addressed_in_docs) {
                                                collectedAnalysis.personas = data.data.personas_addressed_in_docs;
                                            }
                                            if (data.data.seo_keywords_analysis) {
                                                collectedAnalysis.seo = data.data.seo_keywords_analysis;
                                            }
                                            this.streamingContent = JSON.stringify({
                                                type: 'analysis_stream',
                                                data: data.data
                                            }, null, 2);
                                        }
                                        break;
                                    case 'completed':
                                        console.log("Analysis completed:", collectedAnalysis);
                                        this.analysisInfo = {
                                            personas_addressed_in_docs: collectedAnalysis.personas,
                                            seo_keywords_analysis: collectedAnalysis.seo
                                        };
                                        this.isLoadedFromCache = false;
                                        this.streamingContent = JSON.stringify({ 
                                            type: 'completed', 
                                            message: 'Analysis completed successfully',
                                            data: this.analysisInfo
                                        }, null, 2);
                                        
                                        // Update persona and SEO analysis with the correct structure
                                        this.personaAnalysis = {
                                            personas_addressed_in_docs: collectedAnalysis.personas
                                        };
                                        this.seoAnalysis = {
                                            seo_keywords_analysis: collectedAnalysis.seo
                                        };
                                        
                                        // Save to IndexDB with correct key structure
                                        await indexDbService.saveTableData('analysisData', {
                                            analysisInfo: this.analysisInfo,
                                            personaAnalysis: this.personaAnalysis,
                                            seoAnalysis: this.seoAnalysis
                                        });
                                        this.loading = false;
                                        break;
                                    case 'error':
                                        this.streamingContent = JSON.stringify({ type: 'error', message: data.message }, null, 2);
                                        console.error('Error in analysis:', data.message);
                                        break;
                                    default:
                                        this.streamingContent = JSON.stringify(data, null, 2);
                                }
                            } catch (e) {
                                console.log("Error parsing JSON line:", e, "Line:", line);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error analyzing content:', error);
                // Try to load from IndexDB if network fails
                const cachedData = await indexDbService.getTableData('analysisData');
                if (cachedData) {
                    this.analysisInfo = cachedData.analysisInfo;
                    this.personaAnalysis = cachedData.personaAnalysis;
                    this.seoAnalysis = cachedData.seoAnalysis;
                    this.isLoadedFromCache = true;
                }
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>
.card {
    background-color: #2c2c2c;
    padding: 1rem;
    border-radius: 0.5rem;
}

h3 {
    margin-bottom: 1rem;
    color: #ffffff;
}
</style>

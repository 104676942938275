<template>
    <node-view-wrapper>

  <details class="details-block">
    <summary class="details-summary">
      <editor-content v-if="summaryEditor && node?.attrs?.summary" :editor="summaryEditor" />
    </summary>
    <div class="details-content">
      <editor-content v-if="contentEditor && node?.attrs?.content" :editor="contentEditor" />
    </div>
  </details>  
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-3'
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import { onBeforeUnmount } from 'vue'

export default {
  props: nodeViewProps,
  components: {
    NodeViewContent, NodeViewWrapper, EditorContent
  },
  data() {
    return {
      summaryEditor: null,
      contentEditor: null,
    }
  },
  mounted() {
    this.summaryEditor = new Editor({
      content: this.node.attrs.summary || 'Click to edit summary',
      extensions: [StarterKit],
      editorProps: {
        attributes: {
          class: 'summary-editor',
        },
      },
      onUpdate: ({ editor }) => {
        this.updateAttributes({
          summary: editor.getHTML(),
          content: this.node.attrs.content || ''
        })
      },
    })

    this.contentEditor = new Editor({
      content: this.node.attrs.content || 'Click to edit content',
      extensions: [StarterKit],
      editorProps: {
        attributes: {
          class: 'content-editor',
        },
      },
      onUpdate: ({ editor }) => {
        this.updateAttributes({
          summary: this.node.attrs.summary || '',
          content: editor.getHTML()
        })
      },
    })
  },
  beforeDestroy() {
    this.summaryEditor?.destroy()
    this.contentEditor?.destroy()
  }
}
</script>

<style>
.details-block {
  margin: 0.5em 0;
  padding: 0.25em;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.details-summary {
  padding: 0.25em;
  cursor: pointer;
  position: relative;
  list-style: none;
  display: flex;
  align-items: center;
  min-height: 24px;
}

/* Custom disclosure triangle */
.details-summary::before {
  content: "▶";
  margin-right: 0.35em;
  font-size: 0.75em;
  transition: transform 0.2s;
  display: inline-block;
  width: 0.8em;
  color: #666;
}

/* Rotate triangle when details is open */
details[open] .details-summary::before {
  transform: rotate(90deg);
}

.details-content {
  padding-left: 1.5em;
  padding-right: 1.5em;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.summary-editor .ProseMirror {
  outline: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  min-width: 150px;
  font-weight: 500;
  font-size: 0.95em;
  line-height: 1.2;
}

.content-editor .ProseMirror {
  outline: none;
  min-height: 1em;
  padding: 0.25em;
  font-size: 0.95em;
  line-height: 1.4;
}

/* Remove the default details marker */
.details-summary::-webkit-details-marker {
  display: none;
}

/* Style for the content area */
.details-content {
  margin-top: 0.25em;
  margin-left: 0.75em;
  padding-left: 0.75em;
  border-left: 1px solid #eee;
}
</style> 
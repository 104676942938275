const DB_NAME = 'DevDocsDB';
const DB_VERSION = 1;
const STORES = {
    TABLES: 'tables'
};

class IndexDbService {
    constructor() {
        this.db = null;
        this.initDb();
    }

    initDb() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(DB_NAME, DB_VERSION);

            request.onerror = (event) => {
                reject('Database error: ' + event.target.error);
            };

            request.onsuccess = (event) => {
                this.db = event.target.result;
                resolve(this.db);
            };

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(STORES.TABLES)) {
                    db.createObjectStore(STORES.TABLES, { keyPath: 'id', autoIncrement: true });
                }
            };
        });
    }

    async saveTableData(key, data) {
        await this.ensureDbConnection();
        return new Promise((resolve, reject) => {
            try {
                // Safely stringify the data to handle non-cloneable objects
                const safeData = JSON.parse(JSON.stringify(data));
                
                const transaction = this.db.transaction([STORES.TABLES], 'readwrite');
                const store = transaction.objectStore(STORES.TABLES);
                const request = store.put({
                    id: key,
                    data: safeData,
                    timestamp: new Date().getTime()
                });

                request.onsuccess = () => resolve(request.result);
                request.onerror = () => reject(request.error);
            } catch (error) {
                reject(new Error('Failed to serialize data for storage: ' + error.message));
            }
        });
    }

    async getTableData(key) {
        await this.ensureDbConnection();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([STORES.TABLES], 'readonly');
            const store = transaction.objectStore(STORES.TABLES);
            const request = store.get(key);

            request.onsuccess = () => {
                try {
                    // Parse the data back if it exists
                    const result = request.result?.data || null;
                    resolve(result);
                } catch (error) {
                    reject(new Error('Failed to parse stored data: ' + error.message));
                }
            };
            request.onerror = () => reject(request.error);
        });
    }

    async ensureDbConnection() {
        if (!this.db) {
            await this.initDb();
        }
    }

    async clearStore() {
        await this.ensureDbConnection();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([STORES.TABLES], 'readwrite');
            const store = transaction.objectStore(STORES.TABLES);
            const request = store.clear();

            request.onsuccess = () => resolve();
            request.onerror = () => reject(request.error);
        });
    }

    async clearTable(key) {
        await this.ensureDbConnection();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([STORES.TABLES], 'readwrite');
            const store = transaction.objectStore(STORES.TABLES);
            const request = store.delete(key);

            request.onsuccess = () => resolve();
            request.onerror = () => reject(request.error);
        });
    }

    async removeTableItem(tableKey, itemId) {
        await this.ensureDbConnection();
        return new Promise((resolve, reject) => {
            const transaction = this.db.transaction([STORES.TABLES], 'readwrite');
            const store = transaction.objectStore(STORES.TABLES);
            const request = store.get(tableKey);

            request.onsuccess = () => {
                try {
                    const tableData = request.result?.data;
                    if (!tableData) {
                        resolve(false);
                        return;
                    }

                    // If tableData is an array, remove the item by index or id
                    if (Array.isArray(tableData)) {
                        const index = typeof itemId === 'number' ? 
                            itemId : 
                            tableData.findIndex(item => item.id === itemId);
                            
                        if (index > -1) {
                            tableData.splice(index, 1);
                            // Save the updated table data
                            return this.saveTableData(tableKey, tableData)
                                .then(() => resolve(true))
                                .catch(reject);
                        }
                    }
                    // If tableData is an object, delete the property
                    else if (typeof tableData === 'object') {
                        if (itemId in tableData) {
                            delete tableData[itemId];
                            // Save the updated table data
                            return this.saveTableData(tableKey, tableData)
                                .then(() => resolve(true))
                                .catch(reject);
                        }
                    }
                    
                    resolve(false);
                } catch (error) {
                    reject(new Error('Failed to remove item: ' + error.message));
                }
            };
            request.onerror = () => reject(request.error);
        });
    }
}

export default new IndexDbService();

<template>
    <el-breadcrumb v-if="repo" :separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/users_docs'}">
            <el-icon>
                <home-filled />
            </el-icon>
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/docs/' + $route?.params?.repo }">{{ repo }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ file }}</el-breadcrumb-item>
    </el-breadcrumb>
  
        <el-main class="headersection">
            <figure>
                <h3 class="content-header">Internal Documentation: {{ repo }}
            </h3>
            </figure>
            <figure class="pt-2 pb-2">
                <h2>
                    Documentation for {{ file }}
                <span v-if="folder">
                 📁
                </span>
                </h2>
            </figure>
            <!-- <h3>Context Doc for {{ repo }}</h3> -->
        </el-main>

    
    <el-container>
        <el-main class="pagesection">
            <read-only v-if="content" :content="content" />
        </el-main>
    </el-container>
</template>

<script>

import ReadOnly from '@/components/ReadOnly.vue'
import { HomeFilled, Folder } from '@element-plus/icons-vue'

export default {
    async mounted() {
        await this.getDoc()
    },
    data: () => ({
        content: null,
        repo: null,
        repoPath: null,
        file: null,
        folder: null
    }),
    components: {
        ReadOnly,
        HomeFilled, 
        Folder
    },
    methods: {
        async getDoc() {
            
            const repo = atob(this.$route?.params?.repo)
            const file = atob(this.$route?.params?.id)
            
            this.repo = repo
            if(file.includes("/dev-docs/misc")) this.file = file
            else this.file = file.split("/")[1]
            
            
            var myHeaders = new Headers();
            var token = await this.$authInstance.getToken()
            
            myHeaders.append("Content-Type", "application/json");
            if (token) {
                myHeaders.append("Authorization", `Bearer ${token}`)
            }
            var url = await this.$authInstance.getBaseUrl()
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            var responseData 
            if(file.includes("misc")) responseData = await fetch(`${url}/docs?searchKey=filter&searchValue=${repo}-misc`, requestOptions)
            else responseData = await fetch(`${url}/docs?searchKey=filter&searchValue=${repo}-context`, requestOptions)
            
            var resJson = await responseData.json()
            const { data } = resJson
            const document = data.find(function(item){ return item.file == file})
            
            if (document) {
                this.content = document?.content
                this.folder = document?.folder
            }
        }
    }
}

</script>
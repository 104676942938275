<template>
  <figure class="px-10 pt-10 pb-10">
    <h2 class="text-left">Your user-facing documentation site</h2>
    <h3 class="mt-4 text-left subpage-heading">
      Manage your documentation with Dev-Docs
    </h3>
  </figure>

  <div v-if="initalized" ref="sectionholder" class="sections-holder">
    <div
      style="display: flex"
      class="main-card p-5 rounded-[10px] border border-[#a2a1a5]/75"
    >
      <div
        style="
          max-height: 30vh;
          max-width: 590.72px !important;
          border-radius: 0.625rem;
          border: 2px solid #1c1c1c;
          display: grid;
        "
        class="overflow-hidden"
      >
        <div style="max-width: 590.72px; max-height: 30vh" class="relative">
          <div
            v-if="connectedGitUrl && repoData.homepage"
            class="iframe-wrapper"
          >
            <iframe
              :src="repoData.homepage + '/docs'"
              title="W3Schools Free Online Web Tutorials"
              class="iframe"
            ></iframe>
          </div>
          <div v-else-if="connectedGitUrl && !repoData?.homepage">
            <img
              ref="iframeimg"
              :src="notDeployed"
              alt="placeholder"
              class="iframe-image"
            />
          </div>
          <div v-else>
            <img
              ref="iframeimg"
              :src="notDeployed"
              alt="placeholder"
              class="iframe-image"
            />
            <div class="overlay-button">
              <Button
                style="flex-grow: 0 !important"
                @click="showEditDocsSetup = true"
              >
                <img :src="deployIcon" />
                Setup
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        style="display: flex; flex-direction: column;"
        class="items-start justify-start gap-10 pl-8 ml-8"
      >
        <div style="max-height: 30vh; overflow-y: scroll;"
          class="w-full md:w-[541px] flex flex-col justify-start items-start gap-2.5"
        >
          <div class="flex flex-col justify-start items-start gap-2.5">
            <div class="flex justify-start items-start gap-2.5">
              <div class="grow text-white text-xl font-medium leading-[30px]">
                Your external Dev-Docs repo 🚀
              </div>
              <div class="ml-4">
                <Tag
                  v-if="repoData?.homepage"
                  severity="success"
                  value="Success"
                  >Project deployed</Tag
                >
                <Tag v-else severity="danger" value="Success"
                  >Project not deployed</Tag
                >
              </div>
            </div>
            <div class="info-container text-[#a2a1a5] text-base font-medium leading-normal">
             Your docs repo: <a :href="connectedGitUrl" target="_blank">{{
                connectedGitUrl
              }}</a>
              <br>
              <br>
              <label class="p-card-subtitle">Your connected repos <i style="font-size: 0.8rem" class="pi pi-info-circle" v-tooltip="'these are your codebases to be used as context connected to the Github App for your current account, do not add codebases you do not want to share or use as context'"></i></label>
              <br>
              <br>
              <Tag v-for="(connectedRepo, index) in visibleRepos" :key="index" :value="connectedRepo">{{ connectedRepo }}</Tag>
              <Button v-if="connectedRepos.length > 3 && !showAllRepos" 
                      class="p-button-text p-button-sm" 
                      @click="showAllRepos = true">...</Button>
              <div class="mt-4">
                <Button as="a":href="githubAppUrl" target="_blank" severity="help" variant="outlined" class="text-[#a2a1a5] text-base p-card-subtitle">Connect additional repos</Button>
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-start items-start gap-[5px] mt-4">
            <div class="text-[#a2a1a5] text-base font-medium leading-normal">
              Last updated
            </div>
            <div
              v-if="repoData?.updated_at"
              class="text-base font-medium leading-normal text-white"
            >
              {{ getTheLastEditedAt(repoData?.updated_at) }}
            </div>
            <div v-else class="text-base font-medium leading-normal text-white">
              Never Edited
            </div>
          </div>
          <div class="flex flex-col justify-start items-start gap-[5px] mt-4">
            <div class="text-[#a2a1a5] text-base font-medium leading-normal">
              Domain
            </div>
            <div class="text-base font-medium leading-normal text-white">
              <a :href="repoData?.homepage" target="_blank">{{
                repoData?.homepage
              }}</a>
            </div>
          </div>
        </div>
        <div  v-if="hasMatchingRepo" class="dashoptions flex justify-end items-center gap-2.5">
          <Button
            style="white-space: nowrap"
            as="a"
            :href="githubAppUrl"
            target="_blank"
            severity="contrast"
            label="GitHub setup"
            icon="pi pi-github"
            outlined
          />
          <a
            v-if="hasMatchingRepo"
            style="white-space: nowrap"
            class="p-button p-component p-button-primary"
            href="/editor"
            target="_blank"
            rel="noopener"
          >
            <span
              class="p-button-icon p-button-icon-left pi pi-file-edit"
              data-pc-section="icon"
            ></span>
            Edit docs
          </a>
          <a
            v-else
            style="white-space: nowrap"
            class="p-button p-component p-button-primary"
            @click="showEditDocsSetup = true"
          >
            <span
              class="p-button-icon p-button-icon-left pi pi-file-edit"
              data-pc-section="icon"
            ></span>
            Edit docs
          </a>
          <Button
            severity="contrast"
            style="white-space: nowrap"
            @click="toggle"
            outlined
          >
            Create/update llm.txt
            <img :src="aiIcon" />
          </Button>

          <Button severity="contrast" style="white-space: nowrap" @click="toggleDeployOptions" outlined>
            <img :src="deployIcon" />
            Deploy
          </Button>
        </div>
        <div  v-else class="dashoptions flex justify-end items-center gap-2.5">
          <a
            style="white-space: nowrap"
            class="p-button p-component p-button-primary"
            @click="showEditDocsSetup = true"
          >
            <span
              class="p-button-icon p-button-icon-left pi pi-file-edit"
              data-pc-section="icon"
            ></span>
            Setup docs
          </a>
        </div>
      </div>
    </div>
    <Popover class="popover-content" ref="deployToggle" unstyled>
      <div
        style="background-color: #030711"
        class="flex flex-wrap justify-center gap-4 p-4 card"
      >
        <a
          class="p-button p-button-outlined"
          style="display: inline-flex; color: white"
          target="_blank"
          :href="'https://vercel.com/new/import?s=' + connectedGitUrl"
          ><img
            style="max-height: 20px; max-width: 20px"
            :src="vercelIcon"
            alt="Deploy with your own Vercel"
          />Deploy with your own Vercel</a
        >
      </div>
    </Popover>
    <Popover class="popover-content" ref="aimenu" unstyled>
      <div
        style="background-color: #030711"
        class="flex flex-wrap justify-center gap-4 p-4 card"
      >
        <Button
          style="white-space: nowrap"
          severity="contrast"
          @click="generatePage = true"
          outlined
        >
          Generate new docs
        </Button>
        <Button style="color: white" outlined @click="standaloneEditor = true"
          >Standalone editor</Button
        >
        <Button style="color: white" outlined @click="changeLog = true"
          >Generate a changelog</Button
        >
        <Button style="color: white" outlined @click="audit = true"
          >Audit docs</Button
        >
      </div>
    </Popover>
    <Popover class="popover-content" ref="deployToggleIframe" unstyled>
      <div
        style="background-color: #030711"
        class="flex flex-wrap justify-center gap-4 p-4 card"
      >
        <Button style="color: white" outlined @click="pushToVercel"
          >Deploy with Dev-Docs</Button
        ><a
          class="p-button p-button-outlined"
          style="display: inline-flex; color: white"
          target="_blank"
          :href="'https://vercel.com/new/import?s=' + connectedGitUrl"
          ><img
            style="max-height: 20px; max-width: 20px"
            :src="vercelIcon"
            alt="Deploy with your own Vercel"
          />Deploy with your own Vercel</a
        >
      </div>
    </Popover>
    <Dialog v-model:visible="githubToggle" modal>
      <github-tabs
        @updatechat="syncChatEdits"
        @customrepo="captureRepos"
        :chat-settings="chatSettings"
      />
    </Dialog>
    <Dialog
      modal
      header="Use AI To generate a new Page"
      :style="{ width: '80vw' }"
      maximizable
      v-model:visible="generatePage"
      @show="() => maximizeDialog($refs.dialog2)"
      ref="dialog2"
    >
      <generate-page
        :repo="connectedGitUrl.split('https://github.com/')[1].split('.git')[0]"
      />
    </Dialog>
    <Dialog
      modal
      header="Use AI To generate a Changelog Page"
      :style="{ width: '80vw' }"
      v-model:visible="changeLog"
    >
      <change-log
        :repo="connectedGitUrl.split('https://github.com/')[1].split('.git')[0]"
      />
    </Dialog>
    <Dialog
      modal
      header="Audit"
      :style="{ width: '80vw' }"
      v-model:visible="audit"
    >
      <audit />
    </Dialog>
    <Dialog
      modal
      header="Use AI To generate a new Page"
      :style="{ width: '80vw' }"
      maximizable
      v-model:visible="standaloneEditor"
      @show="() => maximizeDialog($refs.dialog1)"
      ref="dialog1"
    >
      <external-editor />
    </Dialog>
    <Dialog
      v-model:visible="showLlmDialog"
      modal
      header="Create/Update LLM.txt"
      :style="{ width: '30rem' }"
    >
      <div class="flex flex-col gap-4">
        <div class="flex flex-col">
          <label class="mb-2">File Location</label>
          <input
            v-model="llmFileLocation"
            type="text"
            class="p-2 rounded bg-[#1e1e1e] border border-[#333]"
            placeholder="Enter file location"
          />
        </div>
        <div class="flex justify-end">
          <Button @click="handleLlmSubmit">Generate</Button>
        </div>
      </div>
    </Dialog>
  </div>
  <Skeleton width="100%" height="350px" v-else />
  <Dialog
    v-model:visible="showEditDocsSetup"
    :style="{ width: '30rem', padding: '1.5rem' }"
    modal
    header="Set up your docs"
  >
    <p class="m-0 mt-2 mb-[5rem]">
      Connect your docs repo and GitHub App to start creating documentation. If
      you don't have docs yet, you can use our docusaurus template repo to start
      making docs.
    </p>
    <Button class="m-0 mt-2 mb-2" fluid @click="showGithubAppSetup = true"
      >Connect your own docs</Button
    >
    <Button
      as="a"
      href="https://github.com/new?template_name=starter-template-v3&template_owner=team-dev-docs"
      target="_blank"
      class="m-0 mt-2 mb-2 p-button p-component p-button-text p-button-fluid"
      text
      fluid
      >Don't have docs to connect? Use our GitHub docs template</Button
    >
  </Dialog>

  <Dialog
    v-model:visible="showGithubAppSetup"
    modal
    header="Install the GitHub app to use the AI editor"
    :style="{ width: '80vw' }"
    maximizable
  >
    <div class="flex flex-col justify-start items-start gap-2.5 mt-8 mb-8">
      <Stepper class="mt-8 mb-8" :value="actionStep">
        <StepList>
          <Step @click="actionStep = '1'" value="1"
            >Install the GitHub app</Step
          >
          <Step @click="actionStep = '2'" value="2">Set your docs repos</Step>
        </StepList>
      </Stepper>
      <div class="flex justify-start items-start gap-2.5 mt-8 mb-8">
        <div v-if="actionStep == 1">
          <h3>Install the GitHub App</h3>
          <p class="mt-2 mb-2 p-card-subtitle">
            Get started by installing the GitHub app! Once it's set up, pick
            your documentation repo and any related codebases to connect. This
            makes it super easy to keep your docs in sync with your code,
            automatically update content, and ensure everything stays organized
            and up-to-date.
          </p>
          <Button
            as="a"
            :href="githubAppUrl"
            target="_blank"
            class="m-0 mt-2 mb-2 p-button p-component p-button-text"
            text
            >Install the GitHub App</Button
          >
        </div>
        <div v-if="actionStep == 2">
          <h3>Set your docs repo</h3>
          <p class="mt-2 mb-2 p-card-subtitle">
            Select and set your docs repo in Dev-Docs from the list of GitHub
            repos connected through the GitHub App.
          </p>
          <Select
            class="mt-2 mb-2"
            fluid
            v-model="selectedDocRepo"
            :options="repoItems"
          />
        </div>
      </div>
      <div class="flex justify-end items-end gap-2.5 mt-8 mb-8 w-full">
        <Button
          class="m-0 mt-2 mb-2"
          v-if="actionStep == 1"
          @click="actionStep = '2'"
          >Next</Button
        >
        <Button v-else class="m-0 mt-2 mb-2" @click="completeSetup"
          >Complete GitHub App setup</Button
        >
      </div>
    </div>
  </Dialog>

  <github-drawer
    v-if="integrationDrawer"
    :open="integrationDrawer"
    title="github"
    @reset="integrationDrawer = false"
  />
</template>

<script>
import GithubDrawer from "@/components/GithubDrawer.vue";
import GithubTabs from "@/components/GithubPopup.vue";
import GeneratePage from "@/components/GenerateDocumentationPage.vue";
import ChangeLog from "@/components/ChangeLog.vue";
import Audit from "@/components/Audit.vue";
import Button from "primevue/button";
import ExternalEditor from "@/components/NewMarkdownEditor.vue";
import Stepper from "primevue/stepper";
import StepList from "primevue/steplist";
import StepPanels from "primevue/steppanels";
import StepItem from "primevue/stepitem";
import Step from "primevue/step";
import StepPanel from "primevue/steppanel";
import Dialog from "primevue/dialog";

import Popover from "primevue/popover";

import { Setting, Edit } from "@element-plus/icons-vue";
import { getOwnerAndRepo, getCodebaseRepos } from "@/plugins/devdocsBackendService";

var cutItemsBy4 = function (bigarray) {
  var size = 3;
  var arrayOfArrays = [];
  for (var i = 0; i < bigarray.length; i += size) {
    arrayOfArrays.push(bigarray.slice(i, i + size));
  }

  return arrayOfArrays;
};

const waitOneSecond = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 1000); // 1000 milliseconds = 1 second
  });
};

import { getSyncedDocsRepo } from "@/plugins/devdocsBackendService";
import Select from "@/components/ui/select/Select.vue";
import { Skeleton } from "primevue";

export default {
  watch: {
    selectedDocRepo: async function (val) {
      console.log("what is the val", val);
      if (val && this.initalized) await this.submitFormData();
    },
  },
  data: () => ({
    githubToggle: false,
    sectionsGroups: null,
    org: null,
    initalized: false,
    actionStep: "1",
    generatePage: false,
    notDeployed: require("@/assets/not-deployed.png"),
    onboardingProgress: 1,
    integrationDrawer: false,
    docsUrl: null,
    activePanel: null,
    showEditUrl: false,
    newUser: "",
    githubInfo: null,
    repoData: null,
    selectedDocRepo: null,
    activateCallback: {},
    vercelInfd: null,
    showConnectedGithub: false,
    syncedRepoName: null,
    connectedGitUrl: null,
    chatSettings: null,
    githubAppUrl: "#",
    standaloneEditor: false,
    hasGithubAppSetup: false,
    showEditDocsSetup: false,
    showGithubAppSetup: false,
    repoItems: [],
    connectedRepos: [],
    deployIcon: require("@/assets/deploy.svg"),
    githubIcon: require("@/assets/github.png"),
    chromeIcon: require("@/assets/chrome.png"),
    vercelIcon: require("@/assets/vercel.svg"),
    aiIcon: require("@/assets/ai.svg"),
    changeLog: false,
    audit: false,
    users: [],
    llmFileLocation: "",
    showLlmDialog: false,
    showAllRepos: false,
  }),
  components: {
    Setting,
    GithubDrawer,
    GithubTabs,
    Edit,
    Button,
    Popover,
    Audit,
    GeneratePage,
    ChangeLog,
    ExternalEditor,
    Stepper,
    StepList,
    StepPanels,
    StepItem,
    Step,
    Dialog,
  },
  methods: {
    syncChatEdits(val) {
      console.log("what is the val", val);
      this.chatSettings = val;
    },
    toggle(event) {
      this.showLlmDialog = true;
    },
    maximizeDialog(dialogRef) {
      if (dialogRef && dialogRef.maximize) {
        dialogRef.maximize();
      }
    },
    async getAPIConfigs() {
      var myHeaders = new Headers();
      try {
        var token = await this.$authInstance.getToken();

        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var saveResponseTwo = await fetch(
          `${url}/external_githubs?decrypt=yes`,
          requestOptions
        );
        var saveResponseOne = await fetch(
          `${url}/external_githubs?gpt=yes`,
          requestOptions
        );
        var jsonResponse = await saveResponseTwo.json();
        var jsonResponseOne = await saveResponseOne.json();
        console.log("what is the json response", jsonResponse.docs[0].content);
        this.sectionsGroups = jsonResponse.docs[0].content.repo;
        this.connectedGitUrl = `https://github.com/${jsonResponse.docs[0].content.repo}`;
      } catch (e) {}
    },
    async getRepos() {
      var myHeaders = new Headers();
      try {
        var token = await this.$authInstance.getToken();

        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var response = await fetch(`${url}/repos`, requestOptions);
        var jsonResponse = await response.json();
        console.log(jsonResponse);
        this.repos = jsonResponse;
        let repoItems = jsonResponse.map((repo) => repo.full_name);
        this.repoItems = repoItems;
        console.log("this is the repo item", this.repoItems);
        this.hasGithubAppSetup = jsonResponse.length > 0;
        return repoItems;
        // this.$emit("customrepo", { github_docs: jsonResponse, github_gpts: jsonResponseOne })
        // return { github_docs: jsonResponse, github_gpts: jsonResponseOne }
      } catch (e) {
        this.repos = [];
      }
    },
    async completeSetup() {
      window.location.reload();
    },
    findAndCenterButton() {
      const imgElement = this.$refs.iframeimg;
      if (imgElement) {
        const buttonElement = document.createElement("button");
        buttonElement.innerText = "Click Me";
        buttonElement.style.position = "absolute";
        buttonElement.style.left = "50%";
        buttonElement.style.top = "50%";
        buttonElement.style.transform = "translate(-50%, -50%)";
        imgElement.parentNode.appendChild(buttonElement);
      }
    },
    async generateLLMTxt() {
      var myHeaders = new Headers();
      try {
        let { owner, repo } = await getOwnerAndRepo();
        var token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var raw = JSON.stringify({
          repo,
          owner,
          fileLocation: this.llmFileLocation,
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        let setResponse = await fetch(`${url}/llm_txt`, requestOptions);
      } catch (e) {
        console.log(e);
        return;
      }
    },
    async handleLlmSubmit() {
      await this.generateLLMTxt();
      this.showLlmDialog = false;
    },
    async submitFormData() {
      var myHeaders = new Headers();
      try {
        var token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }
        let repo = this.selectedDocRepo;

        var raw = JSON.stringify({ repo, github_app: true });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        let setResponse = await fetch(
          `${url}/external_githubs`,
          requestOptions
        );
      } catch (e) {
        console.log(e);
        return;
      }
    },
    getTheLastEditedAt(timestamp) {
      const givenDate = new Date(timestamp);

      // Get the current date
      const currentDate = new Date();

      // Calculate the difference in milliseconds
      const differenceInMs = currentDate - givenDate;

      // Convert milliseconds to days
      const differenceInDays = Math.floor(
        differenceInMs / (1000 * 60 * 60 * 24)
      );

      return `${differenceInDays} days ago`;
    },
    async scrollToGetStarted() {
      this.activePanel = "0";
      await waitOneSecond();

      const container = this.$refs.sectionholder;
      const element = this.$refs.setup;

      if (container && element) {
        const elementPosition = element.offsetTop - container.offsetTop;
        container.scrollTo({
          top: elementPosition,
          behavior: "smooth",
        });
      }

      // const element = this.$refs.setup;
      // element.scrollIntoView({ behavior: 'smooth' });
    },
    toggleDeployOptions(event) {
      this.$refs.deployToggle.toggle(event);
      //this.$refs.githubToggle.show(event);
    },
    toggleIframeOptions(event) {
      console.log("YEBHHBSDHYGBFYUDGFUYGSUDY");
      //this.$refs.deployToggleIframe.toggle(event)
    },
    hideOverlay(event) {
      this.$refs.githubToggle.hide();
    },
    toggleGithubOptions() {
      this.$refs.githubToggle.show(event);
    },
    async getIntegrations() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      try {
        var org = await this.$authInstance.getOrg();
        var token = await this.$authInstance.getToken();
        // var user = this.$authInstance.getUserInfo()

        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        // var saveResponse = await fetch(`${url}/external_doc`, requestOptions)
        var saveResponseTwo = await fetch(
          `${url}/external_githubs?decrypt=yes`,
          requestOptions
        );

        var response = await saveResponseTwo.json();

        var integration = response?.docs[0];
        this.githubInfo = integration;
        const { content } = integration;

        this.docsUrl = content?.url;

        //this.form_data = {url: integration?.url, user: integration?.user, api_token: integration?.api_token || "set"}
      } catch (e) {}
    },
    async submitUrl() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      try {
        var org = await this.$authInstance.getOrg();
        var token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var user = await this.$authInstance.userinfo();

        var raw = JSON.stringify({ github_user: user.name, url: this.docsUrl });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var saveResponseTwo = await fetch(
          `${url}/external_docs_url`,
          requestOptions
        );
        var result = await saveResponseTwo.json();
      } catch (e) {}
    },
    async checkForExistingGithub() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      try {
        var org = await this.$authInstance.getOrg();
        var token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var user = await this.$authInstance.userinfo();

        var requestOptions = {
          method: "get",
          headers: myHeaders,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var saveResponseTwo = await fetch(
          `${url}/created_repo`,
          requestOptions
        );

        var result = await saveResponseTwo.json();

        this.connectedGitUrl = result?.clone_url;
        this.repoData = result;
        // if (!result?.clone_url) {
        //     await this.createGithub()
        //     await this.checkForExistingGithub()
        // }
        this.users = result.users.filter(function (user) {
          return !user.login.includes("avb");
        });
      } catch (e) {}
    },
    async checkForVercelUrl() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      try {
        var org = await this.$authInstance.getOrg();
        var token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var user = await this.$authInstance.userinfo();

        var requestOptions = {
          method: "get",
          headers: myHeaders,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var saveResponseTwo = await fetch(
          `${url}/vercel_project`,
          requestOptions
        );
        var result = await saveResponseTwo.json();

        if (result.status && result.exists)
          this.vercelUrl = `https://${org}-dev-docs.vercel.app`;
      } catch (e) {}
    },
    async createGithub() {
      // this.updateAttributes({ saved: true })
      if (this.connectedGitUrl) {
        this.showConnectedGithub = true;
        return;
      }
      var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      try {
        var org = await this.$authInstance.getOrg();
        var token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var user = await this.$authInstance.userinfo();

        var raw = JSON.stringify({ user });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var saveResponseTwo = await fetch(`${url}/fork`, requestOptions);
        var result = await saveResponseTwo.json();

        // this.showConnectedGithub = true
        this.connectedGitUrl = result.clone_url;
      } catch (e) {}
    },
    async addGithubUser() {
      // this.updateAttributes({ saved: true })

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      try {
        var org = await this.$authInstance.getOrg();
        var token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var user = { name: this.newUser };

        var raw = JSON.stringify({ user });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var saveResponseTwo = await fetch(
          `${url}/external_users`,
          requestOptions
        );
        var result = await saveResponseTwo.json();

        this.showConnectedGithub = true;
        this.connectedGitUrl = result.clone_url;
      } catch (e) {}
    },
    async pushToVercel() {
      // this.updateAttributes({ saved: true })
      var myHeaders = new Headers();
      try {
        var org = await this.$authInstance.getOrg();
        var token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var user = await this.$authInstance.userinfo();

        // const { content } = this.githubInfo
        var raw = JSON.stringify({ user });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var saveResponseTwo = await fetch(
          `${url}/vercel_project`,
          requestOptions
        );
        var result = await saveResponseTwo.json();
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    isPublished() {
      return this.$route.name == "published documentation";
    },
    progress() {
      return ``;
    },
    hasMatchingRepo() {
      if (!this.syncedRepoName || !this.repoItems?.length) return false;

      try {
        const repoFormat = this.syncedRepoName;
        return this.repoItems.some((repo) => {
          return repoFormat.includes(repo);
        });
      } catch (error) {
        console.error("Error parsing GitHub URL:", error);
        return false;
      }
    },
    visibleRepos() {
      if (this.showAllRepos) {
        return this.connectedRepos;
      } else {
        return this.connectedRepos.slice(0, 3);
      }
    },
  },
  async mounted() {
    var sections = [
      { label: "Guides", value: "guides" },
      { label: "Sample Apps", value: "sample apps" },
      { label: "Concepts", value: "concepts" },
      { label: "Blog", value: "blog" },
      { label: "Rest API Reference", value: "rest api" },
      { label: "Community Content", value: "community" },
    ];
    this.sectionsGroups = cutItemsBy4(sections);

    if (this.$route.params.org) this.org = this.$route.params.org;
    let repos = await getCodebaseRepos()
    this.connectedRepos = repos.map(repo => {
        return repo.full_name
    })
    await this.getIntegrations();
    await this.checkForExistingGithub();
    await this.checkForVercelUrl();
    await this.getAPIConfigs();
    await this.getRepos();
    this.syncedRepoName = await getSyncedDocsRepo();
    this.githubAppUrl = this.$authInstance.getGitHubAppUrl();
    this.initalized = true;
  },
};
</script>

<style>
.flex-container {
  width: 100%;
  height: 100%;
}

.iframe-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.iframe-image {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}

.iframe {
  width: 1420px;
  height: 1080px;
  border: none;
  overflow: hidden;
  transform: scale(0.416);
  transform-origin: 0 0;
}

.overlay-button {
  position: absolute;
  border-radius: 0.625rem;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  z-index: 10;
  top: 0;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-content: center;
  align-items: center;
}

/* Optional: Add blur effect to iframe */

.not-deployed-button {
  position: absolute !important;
  top: 50%;
  left: 280px;
  transform: translate(-50%, -50%);
  background-color: #555;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.sections-holder {
  padding: 2em;
}

.external-docs-grid {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75);

  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 2em;
  min-height: 80ch;
}

.guides-content {
  background: #2c2738 !important;
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02),
    0px 24px 48px rgba(44, 39, 56, 0.04) !important;
  border-radius: 20px !important;
  color: white !important;
}

.apps-content {
  background: #14a38b !important;
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02),
    0px 24px 48px rgba(44, 39, 56, 0.04) !important;
  border-radius: 20px !important;
  color: white !important;
}

.concepts-content {
  background: #736bf5 !important;
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02),
    0px 24px 48px rgba(44, 39, 56, 0.04) !important;
  border-radius: 20px !important;
  color: white !important;
}

.blogs-content {
  background: #fab5b5 !important;
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02),
    0px 24px 48px rgba(44, 39, 56, 0.04) !important;
  border-radius: 20px !important;
  color: white !important;
}

.api-content {
  background: #409eff !important;
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02),
    0px 24px 48px rgba(44, 39, 56, 0.04) !important;
  border-radius: 20px !important;
  color: white !important;
}

.community-content {
  box-shadow: 0px 12px 24px rgba(44, 39, 56, 0.02),
    0px 24px 48px rgba(44, 39, 56, 0.04) !important;
  border-radius: 20px !important;
}
</style>

<template>
    <div>
        <div v-if="auditInfo">
            <div class="flex items-center justify-between mb-4">
                <span class="text-sm text-gray-400" v-if="isLoadedFromCache">
                    Using cached data
                </span>
                <div class="flex gap-2">
                    <Button icon="pi pi-refresh" @click="refreshData" label="Run Again"
                        class="text-white p-button-sm" />
                    <Button icon="pi pi-trash" @click="clearData" label="Clear" class="text-white p-button-sm" />
                </div>
            </div>
            <ScrollPanel style="width: 100%; height: 100vh">
                <div>
                    <SelectButton class="mt-2 mb-2" v-model="value" :options="options" aria-labelledby="basic" />
                    <DataTable :value="items" v-model:selection="selectedItems" :paginator="true" :rows="5"
                        currentPageReportTemplate="Displaying {first} to {last} of {totalRecords}">
                        <template #paginatorstart>
                            <div
                                style="display: flex;align-items: center;flex-direction: row; min-width: 500px; gap: 1rem;">
                                <span style="max-width: 350px">
                                    Select pages to generate GitHub Issues for.
                                </span>
                                <Button v-if="value == 'Fix and Modify'" @click="handleGeneration">
                                    Copy Pages to Drafts
                                </Button>
                            </div>
                        </template>
                        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                        <Column field="path" header="Path"></Column>
                        <Column field="reason" header="Reason"></Column>
                    </DataTable>

                    <Button v-if="value == 'Generate Issues'" label="Generate Issues" @click="handleGeneration"
                        :disabled="!selectedItems.length" class="mt-3" />
                </div>
                <ProgressItemsBar :items="selectedItems" :processedItems="processedItems" itemType="drafts" />

                <div v-if="loading || streamingResponses.length > 0 && generationLoading" class="flex flex-col gap-4 mt-4">
                    <StreamingPreviewCarousel 
                        v-if="parallelStreams.length > 0"
                        :streams="parallelStreams"
                        @refresh="refreshData"
                        @clear="clearStreams"
                    />
                    <StreamingPreview v-else-if="streamingContent && !streamingResponses.length"
                        :content="streamingContent" />
                    <DocumentCarousel v-if="streamingResponses.length" v-model:documents="streamingResponses" :branch="branch"
                        :owner="documentationRepo?.owner" :repo="documentationRepo?.repo" @accept="handleAccept" action="audit_content"
                        @revert="handleRevert" @discard="handleDiscard" @clear-cached="streamingResponses = []" />
                </div>
            </ScrollPanel>
            <div v-if="hasCachedContent && streamingResponses.length > 0" class="mb-4">
                <DocumentCarousel
                    :documents="streamingResponses"
                    :branch="branch"
                    :owner="documentationRepo?.owner"
                    :repo="documentationRepo?.repo"
                    action="audit_content"
                    @accept="handleAccept"
                    @revert="handleRevert"
                    @discard="handleDiscard"
                    @clear-cached="streamingResponses = []"
                    @load-cached="handleLoadCached"
                />
            </div>
        </div>
        <div v-else class="flex flex-col p-fluid gap-[1.25rem] mt-[1.25rem]">
            <div v-if="hasCachedContent && streamingResponses.length > 0" class="mb-4">
                <DocumentCarousel
                    :documents="streamingResponses"
                    :branch="branch"
                    :owner="documentationRepo?.owner"
                    :repo="documentationRepo?.repo"
                    action="audit_content"
                    @accept="handleAccept"
                    @revert="handleRevert"
                    @discard="handleDiscard"
                    @clear-cached="streamingResponses = []"
                    @load-cached="handleLoadCached"
                />
            </div>
            <div class="">
                <label for="folders">Existing docs to audit</label>
                <ContextSelectTree v-if="folderOptions?.length > 0 && updatedContextSource" :data="folderObject"
                    :additional-items="repoContextItems" v-model="selectedFolders"
                    @selection-change="handleSelectionItemChange" class="mt-[0.62rem] !bg-[#1C1C1C]" />
                <Skeleton class="mt-2" v-else height="2rem" fluid />
            </div>

            <div class="">
                <div class="flex items-center p-[0.62rem] gap-[0.62rem]">
                    <Checkbox v-model="checkCode" :binary="true" inputId="binary"
                        label="Check here to verify that your docs match current code. Once checked, select docs and codefiles you would like to audit from dropdown above." />
                    <label for="size_normal">Check here to verify that your docs match current code. Once checked,
                        select docs
                        and codefiles you would like to audit from dropdown above.</label>
                </div>
            </div>

            <div class="flex flex-col gap-[0.62rem]">
                <div>
                    Describe what you want to audit
                </div>
                <div style="display: flex; position: relative;">
                    <Textarea
                        placeholder='Your audit prompt goes here. Separate prompts with a comma or period. For example: "Check for spelling/grammatical errors, ensure a professional tone is used throughout these docs, etc."'
                        class="w-full px-[0.63rem] py-[0.44rem] rounded-[0.5rem] bg-[#1C1C1C] min-h-[9.375rem] !border-none"
                        autoResize v-model="newAuditValue" variant="filled" autofocus fluid rows="2" />
                </div>
            </div>

            <div class="flex flex-col gap-[0.62rem]">
                <div class="">
                    <PopularPrompts
                        :items="['Find and fix spelling and grammatical errors', 'Find all headings with Title case', 'Identify any inconsistent content', 'Ensure that the tone is professional throughout these docs']"
                        v-model="newAuditValue" :appendMode="true" />
                </div>
            </div>

            <div class="flex self-end justify-end w-fit">
                <Button label="Submit" @click="auditYourDocs" class="w-fit" />
            </div>
        </div>

        <div v-if="loading && !streamingResponses.length && !auditInfo" class="flex flex-col gap-4">
            <StreamingPreview v-if="streamingContent" :content="streamingContent" />
            <StreamingPreview v-else="'Auditing Documentation...'" :content="'Auditing Documentation...'" />
        </div>
    </div>
</template>

<script> 
import ContextSelectTree from './ContextSelectTree.vue';
import PopularPrompts from './PopularPrompts.vue';
import ProgressItemsBar from './EditorComponents/ProgressItemsBar.vue';
import StreamingPreview from './EditorComponents/StreamingPreview.vue';
import StreamingPreviewCarousel from './EditorComponents/StreamingPreviewCarousel.vue';
import DocumentCarousel from './EditorComponents/DocumentCarousel.vue';
import indexDbService from '../plugins/indexDbService.js';
import streamServices from '../plugins/streamServices.js';

function findParentPaths(items) {
    const paths = Array.isArray(items) ? items : [];
    return paths.filter((path, index) => {
        return !paths.some((otherPath, otherIndex) => {
            if (index === otherIndex) return false;
            return path.startsWith(otherPath + '/');
        });
    });
}

import { getFileContent, getCustomFiles, getOwnerAndRepo } from '../plugins/devdocsBackendService.js';
import { Skeleton } from 'primevue';
import aiBlock from './custom_extensions/aiBlock';

export default {
    props: {
        branch: {
            type: String,
        }
    },
    data() {
        return {
            audits: [],
            newAuditValue: '',
            selectedFolders: [],
            currentDraftCount: null,
            processedItems: 0,
            items: [],
            codeItems: [],
            checkCode: false,
            folderOptions: [],
            folderObject: {},
            loading: false,
            auditInfo: null,
            selectedItems: [],
            documentationRepo: null,
            repos: [],
            codeFiles: [],
            updatedContextSource: true,
            repoContextItems: [],
            value: 'Fix and Modify',
            options: ['Fix and Modify', 'Generate Issues'],
            isLoadedFromCache: false,
            streamingContent: '',
            streamingResponses: [],
            hasCachedContent: false,
            auditTableCollected: false,
            generationLoading: false,
            parallelStreams: [],
        };
    },
    computed: {
        progressPercentage() {
            if (!this.selectedItems.length) return 0
            return (this.processedItems / this.selectedItems.length) * 100
        }
    },
    components: {
        ContextSelectTree,
        PopularPrompts,
        ProgressItemsBar,
        StreamingPreview,
        StreamingPreviewCarousel,
        DocumentCarousel
    },
    async mounted() {
        //await this.getRepos()
        this.documentationRepo = await getOwnerAndRepo()
        await this.checkExistingData();
        await this.getFolders();
        await this.loadStoredSelections();
        await this.checkCachedContent();
    },
    watch: {
        checkCode: {
            async handler(newData) {
                try {
                    if (newData) {
                        this.updatedContextSource = false
                        let repos = await this.getRepos()
                        let newRepoContextItems = []
                        console.log("what is repos", repos)
                        for (let repo of repos) {
                            console.log("what is repo here", repo)
                            console.log("what is this documentation repo", this.documentationRepo)
                            if (this.documentationRepo.repo == repo.name && this.documentationRepo.owner == repo.owner) {
                                continue
                            }
                            let files = await getCustomFiles({ branch: repo.default_branch, owner: repo.owner, repo: repo.name })
                            let repoContextItem = { label: repo.full_name, files: files?.files || [], category: repo.full_name }
                            newRepoContextItems.push(repoContextItem)
                        }
                        this.repoContextItems = newRepoContextItems
                        this.updatedContextSource = true
                    }

                } catch (e) {
                    this.updatedContextSource = true
                }

            }
        }
    },
    methods: {
        async loadStoredSelections() {
            const storedSelections = await indexDbService.getTableData('auditSelections');
            if (storedSelections) {
                this.selectedFolders = storedSelections.selectedFolders;
                this.codeFiles = storedSelections.codeFiles;
            }
        },
        async checkExistingData() {
            const cachedData = await indexDbService.getTableData('auditData');
            if (cachedData) {
                this.auditInfo = cachedData.auditInfo;
                this.items = cachedData.items;
                this.isLoadedFromCache = true;
                this.auditTableCollected = true;
            }
        },

        async clearData() {
            this.auditInfo = null;
            this.items = [];
            this.selectedItems = [];
            this.isLoadedFromCache = false;
            this.processedItems = 0;
            await indexDbService.clearStore();
            this.resetFormValues();
        },

        resetFormValues() {
            this.newAuditValue = '';
            this.selectedFolders = [];
            this.codeFiles = {};
            this.audits = [];
            this.checkCode = false;
        },

        async refreshData() {
            this.auditInfo = null;
            this.isLoadedFromCache = false;
        },
        addAudit() {
            let newAuditValue = this.newAuditValue.trim();
            this.audits.push(newAuditValue);
            this.newAuditValue = '';
        },
        async getFolders() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                var url = await this.$authInstance.getBaseUrl()
                var response = await fetch(`${url}/folders`, requestOptions)
                var jsonResponse = await response.json()
                this.folderOptions = jsonResponse
                this.folderObject = { category: "Documentation", files: jsonResponse }
            } catch (e) {
                this.folderOptions = []
            }
        }, handleSelectionItemChange(value) {
            // Handle the selections here
            console.log('Selected folders:', value);
            this.selectedFolders = value.selections;
            let byCategory = value.byCategory
            this.codeFiles = byCategory

            indexDbService.saveTableData('auditSelections', {
                codeFiles: this.codeFiles,
                selectedFolders: this.selectedFolders
            });
            // You can store the selections in your component's data
            // or emit them to a parent component if needed

        },
        async getRepos() {
            var myHeaders = new Headers();
            try {
                var token = await this.$authInstance.getToken()

                myHeaders.append("Content-Type", "application/json");
                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`)
                }

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                };
                let url = await this.$authInstance.getBaseUrl()
                let response = await fetch(`${url}/github_app_repos`, requestOptions)
                let jsonResponse = await response.json()
                //this.repos = jsonResponse?.repos
                return jsonResponse?.repos
            } catch (e) {
                //this.folderOptions = []
            }
        },
        removeAudit(index) {
            console.log(index);
            this.audits.splice(index, 1);
        },
        async handleGeneration() {
            this.generationLoading = true;
            if (this.value === 'Generate Issues') {
                // For issues, we'll use Promise.all to generate all issues in parallel
                const issuePromises = this.selectedItems.map(selected => {
                    let content = `Need to Modify ${selected.path} because of ${selected.reason}`
                    let title = `Docs Audit: ${selected.reason}`
                    return this.generateIssues({ content, title })
                });
                
                await Promise.all(issuePromises);
            } else {
                // For document modification, we'll use Promise.all with parallel streaming
                this.processedItems = 0;
                this.currentDraftCount = 0;
                this.parallelStreams = [];
                
                // Clear streamingResponses before starting new processing
                this.streamingResponses = [];
                console.log("Starting parallel processing with empty streamingResponses array");
                
                // First, create stream objects for each document
                this.selectedItems.forEach((selected, index) => {
                    this.parallelStreams.push({
                        id: selected.path,
                        fileName: selected.path,
                        content: `Preparing to process document ${index + 1}/${this.selectedItems.length}...`,
                        status: 'pending',
                        reason: selected.reason
                    });
                });
                
                // Create an array of promises for parallel processing
                const updatePromises = this.selectedItems.map((selected, index) => {
                    return this.updateYourDocParallel(selected, index);
                });
                
                // Wait for all updates to complete
                await Promise.all(updatePromises);
                console.log("DO I EVER GET HERE")
                console.log(`Parallel processing complete. StreamingResponses count: ${this.streamingResponses.length}`);
                console.log("StreamingResponses array:", JSON.stringify(this.streamingResponses));
                
                // If streamingResponses is still empty but we have parallel streams with content, use them as fallback
                if (this.streamingResponses.length === 0 && this.parallelStreams.length > 0) {
                    console.log("Using parallel streams as fallback for streamingResponses");
                    
                    this.parallelStreams.forEach(stream => {
                        if (stream.content && stream.fileName) {
                            this.streamingResponses.push({
                                id: stream.id || Date.now(),
                                content: stream.content,
                                fileName: stream.fileName,
                                complete: true
                            });
                        }
                    });
                    
                    console.log(`After fallback, StreamingResponses count: ${this.streamingResponses.length}`);
                }
                
                // Store all file paths to localStorage
                let newFiles = this.selectedItems.map(item => item.path);
                localStorage.setItem('newFiles', JSON.stringify(newFiles));
                
                // Save streamingResponses to IndexDB for caching
                console.log("this is the streaming responses", this.streamingResponses)
                if (this.streamingResponses.length > 0) {
                    try {
                        await indexDbService.saveTableData('audit_content_cached_docs', {
                            timestamp: new Date().toISOString(),
                            result: this.streamingResponses.length === 1 ? this.streamingResponses[0] : this.streamingResponses,
                            context: {
                                branch: this.branch,
                                owner: this.documentationRepo?.owner,
                                repo: this.documentationRepo?.repo,
                            },
                        });
                        console.log("Saved parallel processing results to IndexDB cache");
                        this.hasCachedContent = true;
                    } catch (e) {
                        console.error("Error saving parallel processing results to cache:", e);
                    }
                }
                
            }
            this.generationLoading = false;
        },
        
        async updateYourDocParallel(selected, index, options = {}) {
            try {
                // Update the stream status to processing
                let unfinishedContent = options.unfinishedContent;
                if (this.parallelStreams[index]) {
                    this.parallelStreams[index].status = 'processing';
                }
                
                let data = { 
                    filePath: selected.path, 
                    reason: selected.reason, 
                    codeContext: selected.codeContext 
                };
                
                let web = { 
                    fileName: selected.path, 
                    codeContext: selected.codeContext, 
                    reason: selected.reason, 
                    action: selected.action, 
                    fileName: selected.path, 
                    ownerName: this.documentationRepo.owner, 
                    repoName: this.documentationRepo.repo, 
                    branchName: this.branch 
                };
                
                data.web = web;
                
                // Prepare for streaming content
                let publishedBranch = localStorage.getItem('publishedBranch') || 'main';
                let org = await this.$authInstance.getOrg();
                let token = await this.$authInstance.getToken();
                let myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }
                
                // Set up code objects for context
                let codeObjects = {};
                if(selected.codeContext?.codefiles) {
                    codeObjects = {};
                    codeObjects[selected.codeContext?.full_repo_name] = selected.codeContext?.codefiles;
                }

                if(web?.fileName) {
                    if(codeObjects) {
                        codeObjects[`${this.documentationRepo.owner}/${this.documentationRepo.repo}`] = [web?.fileName];
                    } else {
                        codeObjects = {};
                        codeObjects[`${this.documentationRepo.owner}/${this.documentationRepo.repo}`] = [web?.fileName];
                    }
                }
                
                // Prepare the message for the API
                let audits = await localStorage.getItem('audit_prompts');
                let message = `Please update this documentation file: ${selected.path} because of this reason: ${selected.reason}. Please respond with the updated documentation in <response> </response> tags.  Additionally please keep the content, tone, and structure of the original documentation while only changinges necessary to fix the issue.  Also here is original audit tasks or prompts for context: ${audits}`;

                if(unfinishedContent) {
                    message = `We only finished modifying a portion of the documentation.  Here is what we have so far: ${unfinishedContent}.
                    
                    Can you respond with the missing portion of the documentation for this file: ${selected.path} and modify accordingly with this reason in mind: ${selected.reason}. Please respond with the missing modified portion of the documentation in <response> </response> tags.  Additionally please keep the content, tone, and structure of the original documentation while only changinges necessary to fix the issue.  Also here is original audit tasks or prompts for context: ${audits}`;
                }

                const requestBody = {
                    message,
                    githubRepository: `${this.documentationRepo.owner}/${this.documentationRepo.repo}`,
                    ...web,
                    codeObjects,
                    returnStream: true
                };

                requestBody.branch = publishedBranch;
                
                // Create streaming response object
                let streamingResponse = {
                    id: Date.now(),
                    content: "",
                    fileName: web.fileName,
                    complete: false
                };

                // Create fetch function for AI messages
                const fetchFn = streamServices.createAiMessagesFetchFn(this.$authInstance, myHeaders);
                let completedContent;
                // Use the completeDocumentModification function to handle the stream
                const result = await streamServices.completeDocumentModification(fetchFn, requestBody, {
                    maxRetries: 3,
                    onChunk: (chunk, accumulated) => {
                        // Update the parallel stream content in our carousel
                        if (this.parallelStreams[index]) {
                            this.parallelStreams[index].content = accumulated;
                        }
                    },
                    onRetry: (retryCount, updatedRequestBody) => {
                        updatedRequestBody.completedContent = this.parallelStreams[index].content
                        completedContent += this.parallelStreams[index].content
                    },
                    onComplete: (content, complete, extractedContent, retryCount) => {
                        if (complete) {
                            // Use extractedContent if available, otherwise use the accumulated content from the parallel stream
                            streamingResponse.content = extractedContent || (this.parallelStreams[index] ? this.parallelStreams[index].content : content);
                            streamingResponse.complete = true;
                            if(completedContent) {
                                let contentWithResponseBlocks = completedContent + this.parallelStreams[index].content
                                console.log("what is the add content", contentWithResponseBlocks)
                                let extractedContent = streamServices.extractResponseContent(contentWithResponseBlocks)
                                console.log("what is the extracted content", extractedContent)
                                streamingResponse.content = extractedContent
                            }
                            
                            // Directly add to streamingResponses array
                            this.streamingResponses.push({...streamingResponse});
                            console.log(`Added document ${web.fileName} to streamingResponses from onComplete. Current count: ${this.streamingResponses.length}`);
                            
                            // Update the status in the parallel stream
                            if (this.parallelStreams[index]) {
                                this.parallelStreams[index].status = 'complete';
                            }
                        }
                    }
                });
                console.log("this is the result", result);
                console.log("Result properties:", Object.keys(result));
                console.log("Result content:", result.content);
                
                // Handle case where onComplete didn't add to streamingResponses
                if (!streamingResponse.complete) {
                    // Try to use the content from the parallel stream as a fallback
                    if (this.parallelStreams[index] && this.parallelStreams[index].content) {
                        streamingResponse.content = this.parallelStreams[index].content;
                        streamingResponse.complete = true;
                        this.streamingResponses.push({...streamingResponse});
                        console.log(`Added document ${web.fileName} to streamingResponses as fallback. Current count: ${this.streamingResponses.length}`);
                        
                        // Update the status in the parallel stream
                        this.parallelStreams[index].status = 'complete';
                    } else {
                        // Handle case where we couldn't complete the document
                        if (this.parallelStreams[index]) {
                            this.parallelStreams[index].status = 'error';
                            this.parallelStreams[index].content += "\n\n⚠️ Could not complete document modification. The document may be too large or complex.";
                        }
                    }
                }
                
                // Increment the processed items counter
                this.processedItems += 1;
                
            } catch (e) {
                console.error("Error in updateYourDocParallel:", e);
                
                // Update the parallel stream status to error
                if (this.parallelStreams[index]) {
                    this.parallelStreams[index].status = 'error';
                    this.parallelStreams[index].content += `\n\nError: ${e.message || "Unknown error occurred"}`;
                }
                
                // Still increment the counter to maintain progress
                this.processedItems += 1;
            }
        },
        
        clearStreams() {
            this.parallelStreams = [];
            this.streamingContent = '';
            this.streamingResponses = [];
        },

        async generateIssues(data) {
            this.loading = true
            var myHeaders = new Headers();
            try {
                let { content, title } = data
                let org = await this.$authInstance.getOrg()
                let token = await this.$authInstance.getToken()
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }

                const raw = JSON.stringify({ content, title });
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'  // 'as const' ensures TypeScript understands this as a literal type
                };

                let url = await this.$authInstance.getBaseUrl()

                const saveResponseTwo = await fetch(`${url}/issues`, requestOptions);
                this.loading = false
                let result = await saveResponseTwo.json();
                console.log(result)
                let tableInfo = JSON.parse(result.content)
                console.log(tableInfo)
                this.auditInfo = tableInfo
                this.items = tableInfo.files
            } catch (e) {
                console.log(e)
                return {}
            }

        },
        async updateYourDoc(data) {
            this.loading = true;
            var myHeaders = new Headers();
            try {
                let publishedBranch = localStorage.getItem('publishedBranch') || 'main';
                let org = await this.$authInstance.getOrg();
                let token = await this.$authInstance.getToken();
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }
                
                let { filePath, reason, codeContext, web } = data;
                let codeObjects = {}
                if(codeContext?.codefiles) {
                    codeObjects = {}
                    codeObjects[codeContext?.full_repo_name] = codeContext?.codefiles
                }

                if(web?.fileName) {
                    if(codeObjects) codeObjects[`${this.documentationRepo.owner}/${this.documentationRepo.repo}`] = [web?.fileName]
                    else {
                        codeObjects = {}
                        codeObjects[`${this.documentationRepo.owner}/${this.documentationRepo.repo}`] = [web?.fileName]
                    }
                }
                let audits = await localStorage.getItem('audit_prompts')
                let message = `Please update this documentation file: ${filePath} because of this reason: ${reason}. Please respond with the updated documentation in <response> </response> tags.  Additionally please keep the content, tone, and structure of the original documentation while only changinges necessary to fix the issue.  Also here is original audit tasks or prompts for context: ${audits}`;

                const requestBody = {
                    message,
                    githubRepository: `${this.documentationRepo.owner}/${this.documentationRepo.repo}`,
                    ...web,
                    codeObjects,
                    returnStream: true
                };

                requestBody.branch = publishedBranch;

                // Create a streaming response object
                let streamingResponse = {
                    id: Date.now(),
                    content: "",
                    fileName: web.fileName,
                    complete: false
                };

                // Create fetch function for AI messages
                const fetchFn = streamServices.createAiMessagesFetchFn(this.$authInstance, myHeaders);

                // Reset streaming content
                this.streamingContent = "";

                // Use the completeDocumentModification function to handle the stream
                const result = await streamServices.completeDocumentModification(fetchFn, requestBody, {
                    maxRetries: 3,
                    onChunk: (chunk, accumulated) => {
                        // Update streaming content for UI
                        this.streamingContent = accumulated;
                    },
                    onRetry: (retryCount, updatedRequestBody) => {
                        console.log(`Retry attempt ${retryCount} for ${web.fileName}`);
                        this.streamingContent += `\n\n[Continuing document modification - attempt ${retryCount}]\n\n`;
                    },
                    onComplete: (content, complete, extractedContent, retryCount) => {
                        console.log(`Stream processing complete for ${web.fileName}. Complete: ${complete}, Retry count: ${retryCount}`);
                        
                        if (complete && extractedContent) {
                            streamingResponse.content = extractedContent;
                            streamingResponse.complete = true;
                        }
                    }
                });

                // Clear streaming content before adding to carousel
                this.streamingContent = "";
                
                // Add to streamingResponses if we have content
                if (result.complete && result.responseContent) {
                    streamingResponse.content = result.responseContent;
                    streamingResponse.complete = true;
                    this.streamingResponses.push(streamingResponse);
                } else {
                    // Handle case where we couldn't complete the document even after retries
                    console.error(`Could not complete document modification for ${web.fileName} after ${result.retryCount} retries`);
                    streamingResponse.content = "⚠️ Could not complete document modification. The document may be too large or complex.";
                    streamingResponse.complete = false;
                    this.streamingResponses.push(streamingResponse);
                }

            } catch (e) {
                console.error("Error in updateYourDoc:", e);
            } finally {
                this.loading = false;
                this.hasCachedContent = true;
            }
        },
        processSelectedFolders(folderObj) {
            try {
                let checkedFolders = Object.entries(folderObj)
                    .filter(([key, value]) => value.checked)
                    .map(([key]) => key);

                checkedFolders = checkedFolders.filter(folder => folder !== "0");
                checkedFolders.sort((a, b) => a.split('/').length - b.split('/').length);

                const result = [];

                for (let folder of checkedFolders) {
                    const isParent = result.some(existingFolder =>
                        existingFolder.startsWith(folder + '/'));
                    const isChild = result.some(existingFolder =>
                        folder.startsWith(existingFolder + '/'));

                    if (!isParent && !isChild) {
                        let parsedPath = folder.split('::parent::')[1]
                        result.push(parsedPath);
                    }
                }

                return result;
            } catch (e) {
                console.log(e)
                return [];
            }
        },
        async auditYourDocs() {
            this.loading = true;
            var myHeaders = new Headers();
            try {
                let org = await this.$authInstance.getOrg();
                let token = await this.$authInstance.getToken();
                myHeaders.append("Content-Type", "application/json");

                if (token) {
                    myHeaders.append("Authorization", `Bearer ${token}`);
                }
                this.audits = this.newAuditValue.split(',');
                let folders = this.codeFiles["Documentation"];
                folders = findParentPaths(folders);
                let codeObjects = this.codeFiles;
                delete codeObjects["Documentation"];
                await localStorage.setItem('audit_prompts', JSON.stringify(this.audits));
                const raw = JSON.stringify({ folders, codeObjects, audits: this.audits, returnStream: true });
                const requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                let url = await this.$authInstance.getBaseUrl();
                const response = await fetch(`${url}/audit`, requestOptions);
                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let collectedAuditResults = [];
                let codeAuditResults = [];
                let buffer = '';

                this.streamingContent = "";
                let accumulatedContent = [];

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) {
                        const lastChunk = decoder.decode();
                        buffer += lastChunk;
                        break;
                    }

                    const decoded = decoder.decode(value, { stream: true });
                    this.streamingContent += decoded;
                    buffer += decoded;

                    // Split by newlines and process each complete JSON object
                    const lines = buffer.split('\n');
                    buffer = lines.pop() || ''; // Keep the last incomplete line in the buffer

                    for (const line of lines) {
                        if (line.trim()) {
                            try {
                                const data = JSON.parse(line);

                                switch (data.type) {
                                    case 'status':
                                        accumulatedContent.push(JSON.stringify({ type: 'status', message: data.message }, null, 2));
                                        break;
                                    case 'progress':
                                        accumulatedContent.push(JSON.stringify({ type: 'progress', current: data.current, total: data.total }, null, 2));
                                        break;
                                    case 'audit_stream':
                                        console.log("here in the audit");
                                        console.log("this is the data", data)
                                        if (data.data && data.data.type === 'Buffer') {
                                            const bufferString = new TextDecoder().decode(new Uint8Array(data.data.data));
                                            try {
                                                const parsedData = JSON.parse(bufferString);
                                                console.log("Parsed data:", parsedData);
                                                if (parsedData.files) {
                                                    this.auditInfo = parsedData;
                                                    this.items = [...parsedData.files, ...this.items]
                                                }
                                                collectedAuditResults.push(parsedData);
                                                accumulatedContent.push(JSON.stringify({
                                                    type: 'audit_stream',
                                                    snippetIndex: data.snippetIndex,
                                                    data: parsedData
                                                }, null, 2));
                                            } catch (e) {
                                                console.log("Error parsing buffer data:", e, bufferString);
                                            }
                                        } else {
                                            if (data.data.files) {
                                                this.auditInfo = data.data;
                                                this.items = [...data.data.files, ...this.items]
                                            }
                                            collectedAuditResults.push(data.data);
                                            accumulatedContent.push(JSON.stringify({
                                                type: 'audit_stream',
                                                snippetIndex: data.snippetIndex,
                                                data: data.data
                                            }, null, 2));
                                        }
                                        break;
                                    case 'code_audit_stream':
                                        // Handle empty type responses (similar to audit_stream)
                                        console.log("code audit data:", data);
                                        if (data.data && data.data.type === 'Buffer') {
                                            const bufferString = new TextDecoder().decode(new Uint8Array(data.data.data));
                                            console.log("code audit bufferString:", bufferString);
                                            try {
                                                const parsedData = JSON.parse(bufferString);
                                                console.log("code audit parsedData:", parsedData);
                                                if (parsedData.files) {
                                                    //this.auditInfo = parsedData;
                                                    this.codeItems = [...parsedData.files, ...parsedData.missing_files]
                                                }
                                                codeAuditResults.push(parsedData);
                                                accumulatedContent.push(JSON.stringify({
                                                    type: 'code_audit_stream',
                                                    snippetIndex: data.snippetIndex,
                                                    data: parsedData
                                                }, null, 2));
                                            } catch (e) {
                                                console.log("Error parsing buffer data:", e, bufferString);
                                            }
                                        }
                                        break;
                                    case 'analysis_stream':
                                        if (data.data && data.data.type === 'Buffer') {
                                            const bufferString = new TextDecoder().decode(new Uint8Array(data.data.data));
                                            try {
                                                const parsedData = JSON.parse(bufferString);
                                                if (parsedData.personas_addressed_in_docs) {
                                                    this.auditInfo = {
                                                        personas: parsedData.personas_addressed_in_docs
                                                    };
                                                } else if (parsedData.seo_keywords_analysis) {
                                                    this.auditInfo = {
                                                        ...this.auditInfo,
                                                        seo: parsedData.seo_keywords_analysis
                                                    };
                                                }
                                                const newItems = [];
                                                if (parsedData.personas_addressed_in_docs) {
                                                    for (const persona of parsedData.personas_addressed_in_docs) {
                                                        if (persona.missing_files) {
                                                            newItems.push(...persona.missing_files);
                                                        }
                                                    }
                                                }
                                                if (parsedData.seo_keywords_analysis) {
                                                    for (const keyword of parsedData.seo_keywords_analysis) {
                                                        if (keyword.missing_files) {
                                                            newItems.push(...keyword.missing_files);
                                                        }
                                                    }
                                                }
                                                if (newItems.length) {
                                                    this.items = [...this.items, ...newItems];
                                                }
                                                collectedAuditResults.push(parsedData);
                                                accumulatedContent.push(JSON.stringify({
                                                    type: 'analysis_stream',
                                                    snippetIndex: data.snippetIndex,
                                                    data: parsedData
                                                }, null, 2));
                                            } catch (e) {
                                                console.log("Error parsing buffer data:", e, bufferString);
                                            }
                                        }
                                        break;
                                    case 'completed':
                                        console.log("Completed audit:", this.items);
                                        console.log("Completed audit:", this.codeItems);
                                        let currentItems = this.items
                                        let codeItems = this.codeItems
                                        let allItems = [...currentItems, ...codeItems]
                                        this.items = allItems
                                        // this.auditInfo = { files: allResults }
                                        // Final processing of results if needed
                                        this.isLoadedFromCache = false;
                                        accumulatedContent.push(JSON.stringify({ type: 'completed', message: data.message }, null, 2));

                                        // Save to IndexDB
                                        await indexDbService.saveTableData('auditData', {
                                            auditInfo: this.auditInfo,
                                            items: this.items
                                        });
                                        this.auditTableCollected = true;
                                        break;
                                    case 'error':
                                        accumulatedContent.push(JSON.stringify({ type: 'error', snippetIndex: data.snippetIndex, message: data.message }, null, 2));
                                        console.error(`Error in snippet ${data.snippetIndex}:`, data.message);
                                        break;
                                    default:
                                        accumulatedContent.push(JSON.stringify(data, null, 2));
                                }
                            } catch (e) {
                                console.log("Error parsing JSON line:", e, "Line:", line);
                            }
                        }
                    }
                }

                this.loading = false;
            } catch (e) {
                // Try to load from IndexDB if network fails
                // const cachedData = await indexDbService.getTableData('auditData');
                // if (cachedData) {
                //     this.auditInfo = cachedData.auditInfo;
                //     this.items = cachedData.items;
                //     this.isLoadedFromCache = true;
                // }
                // console.log(e);
            }
            this.loading = false;
        },
        async handleAccept(streamingResponse) {
            try {
        // Update localStorage with new files
        const existingFiles = JSON.parse(
          localStorage.getItem("newFiles") || "[]"
        );
   
        this.$emit("draft", existingFiles);
      } catch (e) {
        console.error("Error accepting content:", e);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to save the content",
          life: 3000,
        });
      }
        },

        handleRevert(streamingResponse) {
            // Remove this response from the carousel
            this.streamingResponses = this.streamingResponses.filter(r => r.id !== streamingResponse.id);

            // If all responses are handled, reset loading state
            if (this.streamingResponses.length === 0) {
                this.loading = false;
            }
        },

        handleDiscard(streamingResponse) {
            // Remove this response from the carousel
            this.streamingResponses = this.streamingResponses.filter(r => r.id !== streamingResponse.id);

            // If all responses are handled, reset loading state
            if (this.streamingResponses.length === 0) {
                this.loading = false;
            }
        },
        async checkCachedContent() {
            try {
                const cachedData = await indexDbService.getTableData('audit_content_cached_docs');
                console.log('what is the cached data', cachedData);
                if (cachedData && cachedData.result) {
                    this.hasCachedContent = true;
                    // Set streamingResponses directly since that's what the UI uses to display content
                    this.streamingResponses = Array.isArray(cachedData.result) ? cachedData.result : [cachedData.result];
                    this.isLoadedFromCache = true;
                    
                    // If the cached data includes audit info, set it
                    if (cachedData.result.auditInfo) {
                        this.auditInfo = cachedData.result.auditInfo;
                    }
                    
                    console.log('Loaded streaming responses:', this.streamingResponses);
                }
            } catch (e) {
                console.warn('Error checking cached content:', e);
                this.hasCachedContent = false;
                this.streamingResponses = [];
            }
        },

        async handleLoadCached(cache) {
            try {
                // Set the content from the cached data
                this.streamingResponses = Array.isArray(cache.result) ? cache.result : [cache.result];
                this.loading = false; // Set to false since we're not actively loading
                this.isLoadedFromCache = true;
                this.hasCachedContent = true;
                
                // If the cached data includes audit info, set it
                if (cache.result.auditInfo) {
                    this.auditInfo = cache.result.auditInfo;
                }
                
                // Update the UI to show the loaded content
                this.$nextTick(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Loaded',
                        detail: 'Successfully loaded cached content',
                        life: 3000
                    });
                });
            } catch (e) {
                console.error('Error loading cached content:', e);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to load cached content',
                    life: 3000
                });
            }
        },
    }
};
</script>
<style>
.p-paginator {
    flex-direction: row;
}

.p-paginator-content-start {
    max-width: 350px;
}
</style>
<template>




  <el-row style="margin: 0px" v-if="!isHero">
    <el-col class="navbar-container" :span="24">
      <div class="navbar" style="display: block;">
        <div style="padding: 1em; justify-content: space-between;" class="flex">
          <div style="display: flex; flex-direction: row; align-items: center">
            <router-link :to="{ path: '/users_docs' }">
            <img alt="Tailwind CSS Navbar component" :src="logo" />
          </router-link>
            <!-- <input style="width: 25vw; justify-self: flex-start;" type="text" placeholder="Search" class="ml-8 input search-bar" /> -->
          </div>




          <div class="dropdown dropdown-end" style="z-index: 400 !important">
            <div tabindex="0" role="button" class="mr-8 btn btn-ghost btn-circle avatar">
              <div class="w-10 rounded-full">
                <img alt="Tailwind CSS Navbar component" :src="avatar" />
              </div>
            </div>
            <ul tabindex="0" style="z-index: 3000 !important"
              class="p-2 mt-3 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52">
              <li>
                <router-link :to="{ path: '/settings' }">
                  <a>Settings</a>
                </router-link>
              </li>
              <li>
                <router-link :to="{ path: '/personal_api_keys' }">
                  <a>Personal API Keys</a>
                </router-link>
              </li>
              <li><a @click="logout()">Logout</a></li>
            </ul>
          </div>

        </div>



      </div>
    </el-col>
  </el-row>

  <el-row :data-theme="theme" v-if="isHero">
    <el-col :span="24">
      <router-view />
    </el-col>
  </el-row>




  <div class="main-dash-container" :data-theme="theme"
    style="max-height: 100vh; min-height: 100vh; margin: 0px !important; max-width: 100vw; overflow: hidden" v-else>
    <el-row>
    <el-col v-if="!isExternal" class="sidebar" :span="closeStyling()">
      <el-menu text-color="white" class="el-menu-vertical-demo sidebar-menu" active-text-color="black"
        style="margin-bottom: 20px; padding-top: 0em !important; margin-top: 0em !important; min-height: 100vh; overflow-y: scroll;"
        v-if="!isCollapse">
        <el-menu-item class="mt-8 mb-8" index="0">
          <el-icon @click="isCollapse = false" v-if="isCollapse">
            <img alt="Tailwind CSS Navbar component" :src="rightSidebar" />

          </el-icon>

          <template v-if="!isCollapse" #title>
            <div id="org-card">
              <div class="w-[100%] h-[50px] left-[16px] top-[10px] justify-start items-center gap-2 inline-flex">
                <img class="w-5 h-5 rounded" :src="teamIcon" />
                <div v-tooltip.bottom="org" class="text-sm font-medium leading-tight text-white truncate">{{ org }}</div>
                <div class="flex justify-center card">

                  <Button class="droppy" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu"
                    text>
                    <template #icon>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g opacity="0.5">
                          <rect width="24" height="24" rx="4" fill="#030711" />
                          <path
                            d="M11.9999 18.0889L11.6464 17.7353L8.55552 14.6444C8.55551 14.6444 8.55551 14.6444 8.55551 14.6444C8.46958 14.5585 8.33028 14.5585 8.24436 14.6444C8.15845 14.7304 8.15845 14.8697 8.24437 14.9556L11.8443 18.5555C11.8443 18.5556 11.8444 18.5556 11.8444 18.5556C11.9303 18.6414 12.0696 18.6414 12.1555 18.5556L12.1555 18.5555M11.9999 18.0889L12.5091 18.9091L12.1555 18.5555M11.9999 18.0889L12.3535 17.7353L15.4444 14.6444M11.9999 18.0889L15.4444 14.6444M12.1555 18.5555L15.7555 14.9556C15.7555 14.9556 15.7555 14.9556 15.7555 14.9556C15.8414 14.8697 15.8414 14.7304 15.7555 14.6445C15.7555 14.6444 15.7555 14.6444 15.7555 14.6444M12.1555 18.5555L15.0908 14.2909C15.372 14.0097 15.8279 14.0097 16.1091 14.2909L15.7555 14.6444M15.7555 14.6444C15.6695 14.5585 15.5303 14.5586 15.4444 14.6444C15.4444 14.6444 15.4444 14.6444 15.4444 14.6444M15.7555 14.6444L15.4444 14.6444M12.3535 6.26468L11.9999 5.91112L11.6464 6.26468L8.55552 9.35556C8.55551 9.35557 8.55551 9.35557 8.55551 9.35557C8.46958 9.44148 8.33029 9.44148 8.24437 9.35557C8.15845 9.26965 8.15845 9.13036 8.24437 9.04444L11.8444 5.44444C11.8856 5.40319 11.9416 5.38 11.9999 5.38C12.0583 5.38 12.1142 5.40317 12.1555 5.44444C12.1555 5.44445 12.1555 5.44445 12.1555 5.44446L15.7555 9.0444C15.8414 9.13031 15.8414 9.26962 15.7555 9.35555C15.6696 9.44149 15.5303 9.44148 15.4444 9.35557C15.4444 9.35557 15.4444 9.35557 15.4444 9.35556L12.3535 6.26468Z"
                            fill="#1D1E23" stroke="#E1E7EF" />
                        </g>
                      </svg>
                    </template>
                  </Button>
                  <Menu append-to=".sidebar" ref="menu" id="overlay_menu" class="mt-8" :model="orgs" :popup="true">
                    <template #item="{ item, props }">
                      <a v-ripple class="flex items-center" v-bind="props.action">
                        <span />
                        <span @click="setOrg({ org: item })">{{ item }}</span>
                      </a>
                    </template>
                  </Menu>
                </div>


                <div style="display: flex;flex-direction: row;align-items: flex-end !important; flex-grow: 1;/* background: red; */justify-content: flex-end;">
                  <Button style="flex-grow: 0 !important; flex-shrink: 0 !important;" plain text @click="isCollapse = true">
                  <img alt="Tailwind CSS Navbar component" :src="leftSidebar" />
                </Button>
                </div>


              </div>

              <div v-if="orgs?.length > 0" class="left-[43px] top-[32px] text-slate-400 text-xs font-medium leading-[18px]">{{ orgs?.length }} workspaces</div>

            </div>
          </template>
        </el-menu-item>
        <!-- <el-menu-item index="2">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/users_docs' }">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M20.8936 9.47429L11.9576 1.35029C11.6253 1.04445 11.1902 0.874695 10.7386 0.874695C10.287 0.874695 9.85188 1.04445 9.51959 1.35029L0.660591 9.40129C0.404108 9.62279 0.211282 9.90863 0.101957 10.2294C-0.00736699 10.5502 -0.0292395 10.8943 0.0385909 11.2263C0.129605 11.6256 0.354097 11.982 0.674995 12.2365C0.995893 12.4911 1.39401 12.6286 1.80359 12.6263H2.23859C2.32143 12.6266 2.40079 12.6597 2.45927 12.7183C2.51775 12.777 2.55059 12.8565 2.55059 12.9393V19.3113C2.55059 19.5492 2.59746 19.7849 2.68852 20.0047C2.77958 20.2246 2.91305 20.4243 3.08131 20.5926C3.24957 20.7608 3.44933 20.8943 3.66917 20.9854C3.88901 21.0764 4.12464 21.1233 4.36259 21.1233H7.48859C7.75381 21.1233 8.00816 21.0179 8.1957 20.8304C8.38323 20.6429 8.48859 20.3885 8.48859 20.1233V16.4593C8.47583 15.886 8.67379 15.3278 9.04497 14.8907C9.41615 14.4535 9.93477 14.1677 10.5026 14.0873C10.8162 14.0547 11.1331 14.0883 11.4329 14.1858C11.7327 14.2834 12.0087 14.4428 12.243 14.6538C12.4773 14.8647 12.6647 15.1225 12.7932 15.4104C12.9216 15.6983 12.9882 16.01 12.9886 16.3253V20.1253C12.9886 20.3905 13.0939 20.6449 13.2815 20.8324C13.469 21.0199 13.7234 21.1253 13.9886 21.1253H17.1106C17.5913 21.1253 18.0522 20.9344 18.3922 20.5946C18.7322 20.2548 18.9233 19.794 18.9236 19.3133V12.9393C18.9236 12.8565 18.9564 12.777 19.0149 12.7183C19.0734 12.6597 19.1528 12.6266 19.2356 12.6263H19.6746C20.0402 12.6261 20.3971 12.5154 20.6986 12.3087C21.0001 12.1019 21.232 11.8088 21.3639 11.4679C21.4957 11.1269 21.5214 10.7541 21.4375 10.3983C21.3535 10.0425 21.1639 9.72037 20.8936 9.47429Z"
                fill="#94A3B8" />
            </svg>
          </router-link>
          </el-icon>
          <router-link class="ml-2 router-link" :to="{ path: '/users_docs' }">
            <span v-if="!isCollapse">
              Home
            </span>
          </router-link>
        </el-menu-item> -->
        <el-menu-item index="1">
          <el-icon @click="isCollapse = false">
            <router-link class="ml-2 router-link" :to="{ path: '/users_docs' }">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M20.8936 9.47429L11.9576 1.35029C11.6253 1.04445 11.1902 0.874695 10.7386 0.874695C10.287 0.874695 9.85188 1.04445 9.51959 1.35029L0.660591 9.40129C0.404108 9.62279 0.211282 9.90863 0.101957 10.2294C-0.00736699 10.5502 -0.0292395 10.8943 0.0385909 11.2263C0.129605 11.6256 0.354097 11.982 0.674995 12.2365C0.995893 12.4911 1.39401 12.6286 1.80359 12.6263H2.23859C2.32143 12.6266 2.40079 12.6597 2.45927 12.7183C2.51775 12.777 2.55059 12.8565 2.55059 12.9393V19.3113C2.55059 19.5492 2.59746 19.7849 2.68852 20.0047C2.77958 20.2246 2.91305 20.4243 3.08131 20.5926C3.24957 20.7608 3.44933 20.8943 3.66917 20.9854C3.88901 21.0764 4.12464 21.1233 4.36259 21.1233H7.48859C7.75381 21.1233 8.00816 21.0179 8.1957 20.8304C8.38323 20.6429 8.48859 20.3885 8.48859 20.1233V16.4593C8.47583 15.886 8.67379 15.3278 9.04497 14.8907C9.41615 14.4535 9.93477 14.1677 10.5026 14.0873C10.8162 14.0547 11.1331 14.0883 11.4329 14.1858C11.7327 14.2834 12.0087 14.4428 12.243 14.6538C12.4773 14.8647 12.6647 15.1225 12.7932 15.4104C12.9216 15.6983 12.9882 16.01 12.9886 16.3253V20.1253C12.9886 20.3905 13.0939 20.6449 13.2815 20.8324C13.469 21.0199 13.7234 21.1253 13.9886 21.1253H17.1106C17.5913 21.1253 18.0522 20.9344 18.3922 20.5946C18.7322 20.2548 18.9233 19.794 18.9236 19.3133V12.9393C18.9236 12.8565 18.9564 12.777 19.0149 12.7183C19.0734 12.6597 19.1528 12.6266 19.2356 12.6263H19.6746C20.0402 12.6261 20.3971 12.5154 20.6986 12.3087C21.0001 12.1019 21.232 11.8088 21.3639 11.4679C21.4957 11.1269 21.5214 10.7541 21.4375 10.3983C21.3535 10.0425 21.1639 9.72037 20.8936 9.47429Z"
                fill="#94A3B8" />
            </svg>
            </router-link>
          </el-icon>

          <router-link class="ml-2 router-link" :to="{ path: '/users_docs' }">
            <span v-if="!isCollapse">
              Home
            </span>
          </router-link>

        </el-menu-item>

        <el-menu-item class="section-menu-item" index="8">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/intergrations' }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_167_340)">
                  <path
                    d="M20.25 11.25H18.75V7.5C18.75 6.90326 18.5129 6.33097 18.091 5.90901C17.669 5.48705 17.0967 5.25 16.5 5.25H12.75V3.75C12.75 2.95435 12.4339 2.19129 11.8713 1.62868C11.3087 1.06607 10.5456 0.75 9.75 0.75C8.95435 0.75 8.19129 1.06607 7.62868 1.62868C7.06607 2.19129 6.75 2.95435 6.75 3.75V5.25H3C2.40326 5.25 1.83097 5.48705 1.40901 5.90901C0.987053 6.33097 0.75 6.90326 0.75 7.5V12C0.75 12.1989 0.829018 12.3897 0.96967 12.5303C1.11032 12.671 1.30109 12.75 1.5 12.75H3.75C4.14782 12.75 4.52936 12.908 4.81066 13.1893C5.09196 13.4706 5.25 13.8522 5.25 14.25C5.25 14.6478 5.09196 15.0294 4.81066 15.3107C4.52936 15.592 4.14782 15.75 3.75 15.75H1.5C1.30109 15.75 1.11032 15.829 0.96967 15.9697C0.829018 16.1103 0.75 16.3011 0.75 16.5V21C0.75 21.5967 0.987053 22.169 1.40901 22.591C1.83097 23.0129 2.40326 23.25 3 23.25H7.5C7.69891 23.25 7.88968 23.171 8.03033 23.0303C8.17098 22.8897 8.25 22.6989 8.25 22.5V20.25C8.25 19.8522 8.40804 19.4706 8.68934 19.1893C8.97064 18.908 9.35218 18.75 9.75 18.75C10.1478 18.75 10.5294 18.908 10.8107 19.1893C11.092 19.4706 11.25 19.8522 11.25 20.25V22.5C11.25 22.6989 11.329 22.8897 11.4697 23.0303C11.6103 23.171 11.8011 23.25 12 23.25H16.5C17.0967 23.25 17.669 23.0129 18.091 22.591C18.5129 22.169 18.75 21.5967 18.75 21V17.25H20.25C21.0456 17.25 21.8087 16.9339 22.3713 16.3713C22.9339 15.8087 23.25 15.0456 23.25 14.25C23.25 13.4544 22.9339 12.6913 22.3713 12.1287C21.8087 11.5661 21.0456 11.25 20.25 11.25Z"
                    fill="#94A3B8" />
                </g>
                <defs>
                  <clipPath id="clip0_167_340">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </router-link>
          </el-icon>


          <router-link class="ml-2 router-link" :to="{ path: '/intergrations' }">
            <span v-if="!isCollapse">
              Integrations
            </span>
          </router-link>

        </el-menu-item>

        <el-menu-item class="section-menu-item" index="4">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/openapi' }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="#94A3B8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-file-json-2">
                <path d="M4 22h14a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v4" />
                <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                <path d="M4 12a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1 1 1 0 0 1 1 1v1a1 1 0 0 0 1 1" />
                <path d="M8 18a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1 1 1 0 0 1-1-1v-1a1 1 0 0 0-1-1" />
              </svg>
            </router-link>
          </el-icon>


          <router-link class="ml-2 router-link" :to="{ path: '/openapi' }">
            <span v-if="!isCollapse">
              OpenAPI
            </span>
          </router-link>

        </el-menu-item>

        <!-- <el-menu-item class="section-menu-item" index="3">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/theme' }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M14 16.25H10C9.80109 16.25 9.61032 16.329 9.46967 16.4697C9.32902 16.6103 9.25 16.8011 9.25 17V20C9.25 20.7293 9.53973 21.4288 10.0555 21.9445C10.5712 22.4603 11.2707 22.75 12 22.75C12.7293 22.75 13.4288 22.4603 13.9445 21.9445C14.4603 21.4288 14.75 20.7293 14.75 20V17C14.75 16.8011 14.671 16.6103 14.5303 16.4697C14.3897 16.329 14.1989 16.25 14 16.25Z"
                  fill="#94A3B8" />
                <path
                  d="M19.75 4V12.51C19.677 12.5017 19.6035 12.4983 19.53 12.5H4.47C4.39653 12.4983 4.32302 12.5017 4.25 12.51V4C4.24974 3.63879 4.32069 3.28107 4.4588 2.94731C4.5969 2.61355 4.79946 2.31028 5.05487 2.05487C5.31028 1.79946 5.61355 1.5969 5.94731 1.4588C6.28107 1.32069 6.63879 1.24974 7 1.25H7.76C8.29132 1.25201 8.81001 1.41215 9.25 1.71V5C9.25 5.19891 9.32902 5.38968 9.46967 5.53033C9.61032 5.67098 9.80109 5.75 10 5.75C10.1989 5.75 10.3897 5.67098 10.5303 5.53033C10.671 5.38968 10.75 5.19891 10.75 5V1.71C11.19 1.41215 11.7087 1.25201 12.24 1.25H17C17.3612 1.24974 17.7189 1.32069 18.0527 1.4588C18.3865 1.5969 18.6897 1.79946 18.9451 2.05487C19.2005 2.31028 19.4031 2.61355 19.5412 2.94731C19.6793 3.28107 19.7503 3.63879 19.75 4Z"
                  fill="#94A3B8" />
                <path
                  d="M18.5 13.5H5.5C4.39543 13.5 3.5 14.3954 3.5 15.5C3.5 16.6046 4.39543 17.5 5.5 17.5H18.5C19.6046 17.5 20.5 16.6046 20.5 15.5C20.5 14.3954 19.6046 13.5 18.5 13.5Z"
                  fill="#94A3B8" />
              </svg>
            </router-link>
          </el-icon>


          <router-link class="ml-2 router-link" :to="{ path: '/theme' }">
            <span v-if="!isCollapse">
              Theme
            </span>
          </router-link>

        </el-menu-item> -->

        <el-menu-item class="section-menu-item" index="9">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/users' }">
              <img :src="members" />
            </router-link>
          </el-icon>
          <router-link class="ml-2 router-link" :to="{ path: '/users' }">
            <span v-if="!isCollapse">
              Members
            </span>
          </router-link>
        </el-menu-item>
        <el-menu-item class="section-menu-item" index="10">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/settings' }">
              <img :src="settings" />
            </router-link>
          </el-icon>
          <router-link class="ml-2 router-link" :to="{ path: '/settings' }">
            <span v-if="!isCollapse" class="router-link">Settings</span>
          </router-link>
        </el-menu-item>
      </el-menu>
      <el-menu v-if="isCollapse" text-color="white" class="el-menu-vertical-demo sidebar-menu" active-text-color="black"
        style="margin-bottom: 20px; padding-top: 0em !important; margin-top: 0em !important; min-height: 100vh; overflow-y: scroll;"
        collapse="true">
        <el-menu-item class="mt-8 mb-8" index="0">
          <el-icon @click="isCollapse = false" v-if="isCollapse">
            <img alt="Tailwind CSS Navbar component" :src="rightSidebar" />

          </el-icon>

          <template v-if="!isCollapse" #title>
            <el-card id="org-card">
              <div class="w-[100%] h-[50px] left-[16px] top-[10px] justify-start items-center gap-2 inline-flex">
                <img class="w-5 h-5 rounded" :src="teamIcon" />
                <div class="text-sm font-medium leading-tight text-white truncate">{{ org }}</div>
                <el-dropdown>
                  <span class="el-dropdown-link">


                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <g opacity="0.5">
                        <rect width="24" height="24" rx="4" fill="#030711" />
                        <path
                          d="M11.9999 18.0889L11.6464 17.7353L8.55552 14.6444C8.55551 14.6444 8.55551 14.6444 8.55551 14.6444C8.46958 14.5585 8.33028 14.5585 8.24436 14.6444C8.15845 14.7304 8.15845 14.8697 8.24437 14.9556L11.8443 18.5555C11.8443 18.5556 11.8444 18.5556 11.8444 18.5556C11.9303 18.6414 12.0696 18.6414 12.1555 18.5556L12.1555 18.5555M11.9999 18.0889L12.5091 18.9091L12.1555 18.5555M11.9999 18.0889L12.3535 17.7353L15.4444 14.6444M11.9999 18.0889L15.4444 14.6444M12.1555 18.5555L15.7555 14.9556C15.7555 14.9556 15.7555 14.9556 15.7555 14.9556C15.8414 14.8697 15.8414 14.7304 15.7555 14.6445C15.7555 14.6444 15.7555 14.6444 15.7555 14.6444M12.1555 18.5555L15.0908 14.2909C15.372 14.0097 15.8279 14.0097 16.1091 14.2909L15.7555 14.6444M15.7555 14.6444C15.6695 14.5585 15.5303 14.5586 15.4444 14.6444C15.4444 14.6444 15.4444 14.6444 15.4444 14.6444M15.7555 14.6444L15.4444 14.6444M12.3535 6.26468L11.9999 5.91112L11.6464 6.26468L8.55552 9.35556C8.55551 9.35557 8.55551 9.35557 8.55551 9.35557C8.46958 9.44148 8.33029 9.44148 8.24437 9.35557C8.15845 9.26965 8.15845 9.13036 8.24437 9.04444L11.8444 5.44444C11.8856 5.40319 11.9416 5.38 11.9999 5.38C12.0583 5.38 12.1142 5.40317 12.1555 5.44444C12.1555 5.44445 12.1555 5.44445 12.1555 5.44446L15.7555 9.0444C15.8414 9.13031 15.8414 9.26962 15.7555 9.35555C15.6696 9.44149 15.5303 9.44148 15.4444 9.35557C15.4444 9.35557 15.4444 9.35557 15.4444 9.35556L12.3535 6.26468Z"
                          fill="#1D1E23" stroke="#E1E7EF" />
                      </g>
                    </svg>

                  </span>
                  <template #dropdown>
                    <el-dropdown-menu v-if="orgs?.length > 0">
                      <el-dropdown-item @click="setOrg({ org })" v-for="org in orgs" :key="org">{{ org
                        }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
                <Button v-if="isCollapse" plain text @click="isCollapse = false">
                  <img alt="Tailwind CSS Navbar component" :src="rightSidebar" />
                </Button>
                <Button v-else plain text @click="isCollapse = true">
                  <img alt="Tailwind CSS Navbar component" :src="leftSidebar" />
                </Button>

              </div>

              <div class="left-[43px] top-[32px] text-slate-400 text-xs font-medium leading-[18px]">your workspace</div>
              <template style="display: flex; align-items: center; background-color: red !important;"
                class="w-[226px] h-10 pl-11 pr-[62px] pt-2 pb-[11px] left-0 top-[60px] absolute bg-[#1D1E23] rounded-bl-md rounded-br-md justify-start items-center gap-1.5 inline-flex"
                #footer>
                <router-link :to="{ path: '/users' }">
                  <el-button text>
                    <el-icon class="mr-2">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="Vector"
                          d="M11.1429 4.71429H7.28571V0.857143C7.28571 0.383839 6.90188 0 6.42857 0H5.57143C5.09812 0 4.71429 0.383839 4.71429 0.857143V4.71429H0.857143C0.383839 4.71429 0 5.09812 0 5.57143V6.42857C0 6.90188 0.383839 7.28571 0.857143 7.28571H4.71429V11.1429C4.71429 11.6162 5.09812 12 5.57143 12H6.42857C6.90188 12 7.28571 11.6162 7.28571 11.1429V7.28571H11.1429C11.6162 7.28571 12 6.90188 12 6.42857V5.57143C12 5.09812 11.6162 4.71429 11.1429 4.71429Z"
                          fill="#FAFAFA" />
                      </svg>

                    </el-icon>
                    Invite members</el-button>
                </router-link>

              </template>
            </el-card>
          </template>
        </el-menu-item>
        <el-menu-item index="1">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/users_docs' }">
              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path
                d="M20.8936 9.47429L11.9576 1.35029C11.6253 1.04445 11.1902 0.874695 10.7386 0.874695C10.287 0.874695 9.85188 1.04445 9.51959 1.35029L0.660591 9.40129C0.404108 9.62279 0.211282 9.90863 0.101957 10.2294C-0.00736699 10.5502 -0.0292395 10.8943 0.0385909 11.2263C0.129605 11.6256 0.354097 11.982 0.674995 12.2365C0.995893 12.4911 1.39401 12.6286 1.80359 12.6263H2.23859C2.32143 12.6266 2.40079 12.6597 2.45927 12.7183C2.51775 12.777 2.55059 12.8565 2.55059 12.9393V19.3113C2.55059 19.5492 2.59746 19.7849 2.68852 20.0047C2.77958 20.2246 2.91305 20.4243 3.08131 20.5926C3.24957 20.7608 3.44933 20.8943 3.66917 20.9854C3.88901 21.0764 4.12464 21.1233 4.36259 21.1233H7.48859C7.75381 21.1233 8.00816 21.0179 8.1957 20.8304C8.38323 20.6429 8.48859 20.3885 8.48859 20.1233V16.4593C8.47583 15.886 8.67379 15.3278 9.04497 14.8907C9.41615 14.4535 9.93477 14.1677 10.5026 14.0873C10.8162 14.0547 11.1331 14.0883 11.4329 14.1858C11.7327 14.2834 12.0087 14.4428 12.243 14.6538C12.4773 14.8647 12.6647 15.1225 12.7932 15.4104C12.9216 15.6983 12.9882 16.01 12.9886 16.3253V20.1253C12.9886 20.3905 13.0939 20.6449 13.2815 20.8324C13.469 21.0199 13.7234 21.1253 13.9886 21.1253H17.1106C17.5913 21.1253 18.0522 20.9344 18.3922 20.5946C18.7322 20.2548 18.9233 19.794 18.9236 19.3133V12.9393C18.9236 12.8565 18.9564 12.777 19.0149 12.7183C19.0734 12.6597 19.1528 12.6266 19.2356 12.6263H19.6746C20.0402 12.6261 20.3971 12.5154 20.6986 12.3087C21.0001 12.1019 21.232 11.8088 21.3639 11.4679C21.4957 11.1269 21.5214 10.7541 21.4375 10.3983C21.3535 10.0425 21.1639 9.72037 20.8936 9.47429Z"
                fill="#94A3B8" />
            </svg>
            </router-link>
          </el-icon>
        </el-menu-item>
        <!-- <el-menu-item class="section-menu-item" index="3">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/theme' }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M14 16.25H10C9.80109 16.25 9.61032 16.329 9.46967 16.4697C9.32902 16.6103 9.25 16.8011 9.25 17V20C9.25 20.7293 9.53973 21.4288 10.0555 21.9445C10.5712 22.4603 11.2707 22.75 12 22.75C12.7293 22.75 13.4288 22.4603 13.9445 21.9445C14.4603 21.4288 14.75 20.7293 14.75 20V17C14.75 16.8011 14.671 16.6103 14.5303 16.4697C14.3897 16.329 14.1989 16.25 14 16.25Z"
                  fill="#94A3B8" />
                <path
                  d="M19.75 4V12.51C19.677 12.5017 19.6035 12.4983 19.53 12.5H4.47C4.39653 12.4983 4.32302 12.5017 4.25 12.51V4C4.24974 3.63879 4.32069 3.28107 4.4588 2.94731C4.5969 2.61355 4.79946 2.31028 5.05487 2.05487C5.31028 1.79946 5.61355 1.5969 5.94731 1.4588C6.28107 1.32069 6.63879 1.24974 7 1.25H7.76C8.29132 1.25201 8.81001 1.41215 9.25 1.71V5C9.25 5.19891 9.32902 5.38968 9.46967 5.53033C9.61032 5.67098 9.80109 5.75 10 5.75C10.1989 5.75 10.3897 5.67098 10.5303 5.53033C10.671 5.38968 10.75 5.19891 10.75 5V1.71C11.19 1.41215 11.7087 1.25201 12.24 1.25H17C17.3612 1.24974 17.7189 1.32069 18.0527 1.4588C18.3865 1.5969 18.6897 1.79946 18.9451 2.05487C19.2005 2.31028 19.4031 2.61355 19.5412 2.94731C19.6793 3.28107 19.7503 3.63879 19.75 4Z"
                  fill="#94A3B8" />
                <path
                  d="M18.5 13.5H5.5C4.39543 13.5 3.5 14.3954 3.5 15.5C3.5 16.6046 4.39543 17.5 5.5 17.5H18.5C19.6046 17.5 20.5 16.6046 20.5 15.5C20.5 14.3954 19.6046 13.5 18.5 13.5Z"
                  fill="#94A3B8" />
              </svg>
            </router-link>
          </el-icon>
        </el-menu-item> -->
        <el-menu-item class="section-menu-item" index="4">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/openapi' }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="#94A3B8" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-file-json-2">
                <path d="M4 22h14a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v4" />
                <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                <path d="M4 12a1 1 0 0 0-1 1v1a1 1 0 0 1-1 1 1 1 0 0 1 1 1v1a1 1 0 0 0 1 1" />
                <path d="M8 18a1 1 0 0 0 1-1v-1a1 1 0 0 1 1-1 1 1 0 0 1-1-1v-1a1 1 0 0 0-1-1" />
              </svg>
            </router-link>
          </el-icon>

        </el-menu-item>
        <el-menu-item class="section-menu-item" index="8">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/intergrations' }">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clip-path="url(#clip0_167_340)">
                  <path
                    d="M20.25 11.25H18.75V7.5C18.75 6.90326 18.5129 6.33097 18.091 5.90901C17.669 5.48705 17.0967 5.25 16.5 5.25H12.75V3.75C12.75 2.95435 12.4339 2.19129 11.8713 1.62868C11.3087 1.06607 10.5456 0.75 9.75 0.75C8.95435 0.75 8.19129 1.06607 7.62868 1.62868C7.06607 2.19129 6.75 2.95435 6.75 3.75V5.25H3C2.40326 5.25 1.83097 5.48705 1.40901 5.90901C0.987053 6.33097 0.75 6.90326 0.75 7.5V12C0.75 12.1989 0.829018 12.3897 0.96967 12.5303C1.11032 12.671 1.30109 12.75 1.5 12.75H3.75C4.14782 12.75 4.52936 12.908 4.81066 13.1893C5.09196 13.4706 5.25 13.8522 5.25 14.25C5.25 14.6478 5.09196 15.0294 4.81066 15.3107C4.52936 15.592 4.14782 15.75 3.75 15.75H1.5C1.30109 15.75 1.11032 15.829 0.96967 15.9697C0.829018 16.1103 0.75 16.3011 0.75 16.5V21C0.75 21.5967 0.987053 22.169 1.40901 22.591C1.83097 23.0129 2.40326 23.25 3 23.25H7.5C7.69891 23.25 7.88968 23.171 8.03033 23.0303C8.17098 22.8897 8.25 22.6989 8.25 22.5V20.25C8.25 19.8522 8.40804 19.4706 8.68934 19.1893C8.97064 18.908 9.35218 18.75 9.75 18.75C10.1478 18.75 10.5294 18.908 10.8107 19.1893C11.092 19.4706 11.25 19.8522 11.25 20.25V22.5C11.25 22.6989 11.329 22.8897 11.4697 23.0303C11.6103 23.171 11.8011 23.25 12 23.25H16.5C17.0967 23.25 17.669 23.0129 18.091 22.591C18.5129 22.169 18.75 21.5967 18.75 21V17.25H20.25C21.0456 17.25 21.8087 16.9339 22.3713 16.3713C22.9339 15.8087 23.25 15.0456 23.25 14.25C23.25 13.4544 22.9339 12.6913 22.3713 12.1287C21.8087 11.5661 21.0456 11.25 20.25 11.25Z"
                    fill="#94A3B8" />
                </g>
                <defs>
                  <clipPath id="clip0_167_340">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </router-link>
          </el-icon>
        </el-menu-item>
        <el-menu-item class="section-menu-item" index="9">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/users' }">
              <img :src="members" />
            </router-link>
          </el-icon>
        </el-menu-item>
        <el-menu-item class="section-menu-item" index="10">
          <el-icon>
            <router-link class="ml-2 router-link" :to="{ path: '/settings' }">
              <img :src="settings" />
            </router-link>
          </el-icon>
        </el-menu-item>
      </el-menu>
    </el-col>

    <el-col v-if="!isExternal" style="max-height: 100vh; min-height: 100%; overflow: scroll !important;" :span="20">


      <main style="overflow: scroll !important; min-width: 1000px;" class="pl-4">
        <router-view :key="$route.path" />
      </main>

    </el-col>
    <el-col v-if="isExternal" style="max-height: 100vh; min-height: 100%;" :span="24">
      <router-view :key="$route.path" />
    </el-col>

  </el-row>

  </div>

  <el-dialog v-model="dialogFormVisible" title="Set Org">
    <el-row>
      <el-col :span="14">
        <el-select v-if="orgs.length > 0" v-model="selectedOrg" placeholder="Select Org">
          <el-option v-for="org in orgs" :key="org" :label="org" :value="org" />
        </el-select>
      </el-col>
      <el-col :span="4">

        <el-link href="/start" type="primary">Create Org</el-link>
      </el-col>
      <el-col :span="10">

      </el-col>
    </el-row>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button @click="setOrg()">set org</el-button>
      </span>
    </template>
  </el-dialog>
  <style-editor-dialog @closestyle="editorDialog = false" v-if="editorDialog" :show="editorDialog" />
  <open-api-dialog @close="apiDialog = false" v-if="apiDialog" :show="apiDialog" />
  <intergrations-dialog @close="integrationDialog = false" v-if="integrationDialog" :show="integrationDialog" />
  <!-- <router-view/> -->
</template>

<script>

import Sidebar from '@/components/Sidebar.vue'
import Navbar from '@/components/Navbar.vue'
import DocSubMenu from './components/DocSubMenu.vue'
import { HomeFilled } from '@element-plus/icons-vue'
import { ref } from 'vue'
import SearchVue from './components/Search.vue'
import StyleEditorDialog from './components/StyleEditorDialog.vue'
import OpenApiDialog from './components/OpenApiDialog.vue'
import IntergrationsDialog from './components/IntergrationsDialog.vue'
import GithubDrawer from './components/GithubDrawer.vue'
import { useRoute } from 'vue-router';

import Button from 'primevue/button';



export default {
  components: {
    Sidebar,
    SearchVue,
    HomeFilled,
    DocSubMenu,
    StyleEditorDialog,
    OpenApiDialog,
    Navbar,
    IntergrationsDialog,
    Button
  },
  data: () => ({
    dialogFormVisible: false,
    orgs: null,
    logo: require('@/assets/logo.svg'),
    teamIcon: require('@/assets/Org.png'),
    avatar: require('@/assets/Avatar.svg'),
    settings: require('@/assets/Settings.svg'),
    integrations: require('@/assets/Integrations.svg'),
    members: require('@/assets/Members.svg'),
    leftSidebar: require('@/assets/Left_collapse.svg'),
    rightSidebar: require('@/assets/Right_collapse.svg'),
    selectedOrg: null,
    docDashOptions: ["Internal", "External"],
    org: null,
    isCollapse: false,
    showWelcome: false,
    dashSelection: "Internal",
    apiDialog: false,
    editorDialog: false,
    theme: null,
    integrationDialog: false,
    items: [
      {
        separator: true
      },
      {
        label: 'Documents',
        items: [
          {
            label: 'New',
            icon: 'pi pi-plus',
            shortcut: '⌘+N'
          },
          {
            label: 'Search',
            icon: 'pi pi-search',
            shortcut: '⌘+S'
          }
        ]
      },
      {
        label: 'Profile',
        items: [
          {
            label: 'Settings',
            icon: 'pi pi-cog',
            shortcut: '⌘+O'
          },
          {
            label: 'Messages',
            icon: 'pi pi-inbox',
            badge: 2
          },
          {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            shortcut: '⌘+Q'
          }
        ]
      },
      {
        separator: true
      }
    ],
    themeOptions:
      ["light", "dark", "cupcake", "bumblebee", "emerald", "corporate", "synthwave", "retro", "cyberpunk", "valentine", "halloween", "garden", "forest", "aqua", "lofi", "pastel", "fantasy", "wireframe", "black", "luxury", "dracula", "cmyk", "autumn", "business", "acid", "lemonade", "night", "coffee", "winter"]
  }),
  watch: {
    dashSelection(val) {
      //localStorage.setItem("dash_selection", val)
      if (val == "External") this.$router.push('/external_docs')
      if (val == "Internal") this.$router.push('/dash')
    },
    theme(val) {
      if (this.themeOptions.includes(val)) this.$theme.setTheme(val)
    }
  },
  computed: {
    collapseSidebarSpan() {
      if (this.isCollapse) return 2
      else return 4
    }
  },
  async mounted() {
    // const s = document.createElement('script');

    //     window.jimo = [];
    //     s.type = 'text/javascript';
    //     s.async = true;
    //     s.src = "https://undercity.usejimo.com/jimo-invader.js";
    //     window['JIMO_PROJECT_ID'] = "210bb6f3-b7c3-4dd7-b1be-6ce48a8d1ed2"; // Update this

    // document.getElementsByTagName('head')[0].appendChild(s);

    if (this.$route.name == "external") this.dashSelection = "External"
    else if (this.$route.name == "dashboard") this.dashSelection = "Internal"
    // else if(localStorage.getItem("dash_selection")) this.dashSelection = localStorage.getItem("dash_selection")
    try {
      this.theme = await this.$theme.getTheme()
      await this.$mermaidInstance.initialize()
      this.orgs = await this.$authInstance.getOrgs()
      this.org = await this.$authInstance.getOrg()
      if (!this.$search.db) {
        const db = await this.$search.initSearchDb()
        this.$search.db = db
      }

      // await this.$search.seedData(this.$search.sampleCollection)


      window.addEventListener('resize', this.handleResize);
      this.handleResize(); // Call the handler initially
      this.bootIntercom()
    } catch (e) {

    }
  },
  methods: {
    bootIntercom() {
      this.$intercom('boot', {
        app_id: 'YOUR_APP_ID',
        user_id: this.userId,
        name: this.name,
        email: this.email,
        created_at: Math.floor(Date.now() / 1000)
      })
    },
    showNewMessage() {
      this.$intercom('showNewMessage')
    },
    trackEvent(eventName, metadata) {
      this.$intercom('trackEvent', eventName, metadata)
    },
    beforeDestroy() {
    this.$intercom('shutdown')
    },
    handleResize() {
      const width = window.innerWidth;
      // Add your code here to handle the resize event
      // For example, you can log the width to the console
      console.log("Viewport width:", width);
      if(width < 1000) {
        this.isCollapse = true
      } else {
        this.isCollapse = false
      }
    },
    async logout() {
      try {
        await this.$authInstance.logout()
      } catch (e) {


      }
    },
    async setOrg(options = {}) {
      let org = options.org || this.selectedOrg
      this.$authInstance.setOrg(org)

    },
    closeStyling() {
      if (this.isCollapse) return 2
      return 4
    },
    async checkSearchIndex() {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var token = await this.$authInstance.getToken()

      myHeaders.append("Content-Type", "application/json");
      if (token) {
        myHeaders.append("Authorization", `Bearer ${token}`)
      }
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      var url = await this.$authInstance.getBaseUrl()
      var existingSearchIndex = await fetch(`${url}/internal_collection`, requestOptions)
      var existingJson = await existingSearchIndex.json()

      if (existingJson?.message.includes("404")) {
        await this.createSearchIndex()
      }
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    async createSearchIndex() {
      // var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      // var token = await this.$authInstance.getToken()

      // myHeaders.append("Content-Type", "application/json");
      // if (token) {
      //   myHeaders.append("Authorization", `Bearer ${token}`)
      // }
      // var raw = JSON.stringify({ index_type: "internal" })
      // var requestOptions = {
      //   method: 'POST',
      //   headers: myHeaders,
      //   body: raw,
      //   redirect: 'follow'
      // };
      // var url = await this.$authInstance.getBaseUrl()
      // var existingSearchIndex = await fetch(`${url}/internal_collection`, requestOptions)
      // var existingJson = await existingSearchIndex.json()

    }
  },
  computed: {
    isHero() {
      return ['login', 'getting started', 'editor', 'generate from pr', 'generate docs instantly', 'github-callback'].includes(this.$route.name);
      // return this.$route.name == 'login' || this.$route.name == 'getting started'
    },
    isExternal() {

      if (this.$route.name == "external") this.dashSelection = "External"
      return this.$route.name == "external section" || this.$route.name == "external page" || this.$route.name == "published documentation" || this.$route.name == "published section" || this.$route.name == "editor" || this.$route.name == "style editor"
    },
    isInternal() {

      if (this.$route.name == "dashboard") this.dashSelection = "Internal"
    },
    isDoc() {
      return this.$route.name == 'doc' || this.$route.name == 'internal doc'
    }
  }
}


</script>

<style lang="scss">
@use "./custom.scss" as *;

#org-card {
  border: none !important;
  background-color: transparent !important;
  width: 100% !important;
  .el-card__footer {
    // background: #1D1E23 !important;
  }
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.team-container {
  width: 14.125rem;
  min-height: 6.25rem;
  border-radius: var(--radius-rounded-md, 0.375rem);
  background: #15161A;
}

.invite-members {
  display: flex;
  // width: 14.125rem;
  height: 40%;
  // padding: py-2 3.875rem py-2 2.75rem;
  align-items: center;
  justify-content: center;
  gap: 0.375rem;
  flex-shrink: 0;
  border-radius: 0rem 0rem var(--radius-rounded-md, 0.375rem) var(--radius-rounded-md, 0.375rem);
  background: #1D1E23;

  h3 {
    color: var(--base-white, #FFF);
    // font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 1.3125rem */
  }
}

.team-name {
  // background-color: red;
  display: flex;
  width: 90%;
  height: 40%;
  align-items: center;
  gap: py-2;

  img {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
  }

  h2 {
    color: var(--Text, var(--base-white, #FFF));
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
    /* 142.857% */
  }
}

#doc-container {}

.nav-drop {
  background: #03001e;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #bb9cff, #ec38bc, #7303c0, #03001e);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #bb9cff, #ec38bc, #7303c0, #03001e);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  z-index: 1000;
}


.navbar {
  z-index: 999;
  // background: transparent;
  border-bottom: 0.5px solid rgba(162, 161, 165, 0.75);
  background: rgb(2 4 7) !important;
  display: flex;
  width: 94.5rem;
  padding: 1.25rem;
  align-items: center;
  gap: 53.5625rem;
  // backdrop-filter: blur(15px);
  // background: rgba(1, 4, 7, 0.6);
  // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  // border: 0.5px solid rgba(255, 255, 255, 0.18);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white !important;
}


.main-dash-container {
  // overflow-y: scroll;
  background-color: #010407 !important;


  p {
    color: white !important;
  }


  // .el-menu {
  //   background: #101319 !important;
  //   border: 0px !important;
  // }

  .el-main {
    background-color: #121220;
  }

  .el-container {
    background-color: #121220;
  }

  .el-card {
    --el-card-border-color: var(--el-border-color-light) !important;
    --el-card-border-radius: 4px !important;
    --el-card-padding: 20px !important;
    --el-card-bg-color: #0D1118 !important;
    border-radius: var(--el-card-border-radius) !important;
    border: 0.5px solid rgba(255, 255, 255, 0.18);
    background-color: #0D1118 !important;
    overflow: hidden;
    color: var(--el-text-color-primary) !important;
    transition: var(--el-transition-duration) !important;
  }


}

nav {
  padding: 30px;
}

.sidebar {
  padding: 0px !important;
  display: flex;
  flex-direction: column;
  background: transparent !important;



  .el-input__wrapper {
    // box-shadow: 0 0 0 0px var(--el-input-border-color,var(--el-border-color)) inset !important
    box-shadow: 0 0px 0px 0px rgb(253, 251, 251);
    background: #0E1117;
    border-bottom: 2px solid #2F3643;
  }

  .sidebar-menu {
    // background: #030711 !important;
    width: 100% !important;
    border-right: 0.5px solid rgba(162, 161, 165, 0.75);
  }

  .sidebar-menu.el-menu--collapse {
    background: #030711 !important;
    width: calc(var(--el-menu-icon-width) + var(--el-menu-base-level-padding) * 2) !important;
    border-right: 0.5px solid rgba(162, 161, 165, 0.75);
  }
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.sub-doc-menu {
  box-shadow: 0 0px 0px 0px rgb(253 251 251);
  background: #0E1117;
  border-bottom: 2px solid #2F3643;
}

.context-sub-menu {
  padding-left: 0px !important;
  padding: 0px !important;
}

.search-bar {
  border-radius: 0.625rem !important;
  border: 0.5px solid rgba(162, 161, 165, 0.75) !important;
}

.el-dropdown-menu {
  background-color: #030711 !important;
}

#overlay_menu {
  top: 2rem !important;
  max-height: 40vh !important;
  overflow-y: scroll !important;
}
</style>

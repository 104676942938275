<template>
    <el-breadcrumb v-if="repo" :separator-icon="ArrowRight">
        <el-breadcrumb-item :to="{ path: '/users_docs' }">
            <el-icon>
                <home-filled />
            </el-icon>
        </el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/docs/' + $route?.params?.id }">{{ repo }}</el-breadcrumb-item>
        <el-breadcrumb-item>general</el-breadcrumb-item>
    </el-breadcrumb>
    <content-vue :repo="getQueryFromId()" />
</template>

<script>

import ContentVue from "@/components/Content.vue";
import { HomeFilled } from '@element-plus/icons-vue'
export default {
    data: () => ({
        repo: ""
    }),
    components: {
        ContentVue,
        HomeFilled
    },
    async mounted() {
        var path = this.getQueryFromId()

        
    },
    methods: {
        getQueryFromId() {
            var repo = atob(this.$route.params.id)
            this.$subMenu.repo = repo
            this.repo = repo
            return atob(this.$route.params.id)
        }
    }
}

</script>

<style>
.el-breadcrumb {
    background: #121220;
    min-height: 8em;
    padding: 4.5em;
}
</style>

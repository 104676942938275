<template>
  <div class="file-explorer-sidebar bg-[#121212] h-full overflow-y-auto">
    <!-- Drafts Header -->
    <div class="flex items-center justify-between px-4 py-2 border-b border-[rgba(122,123,128,0.2)]">
      <div class="flex items-center gap-2">
        <Button as="a" target="_blank" :href="repoUrl" rel="noopener" text>
            <img class="github-icon" :src="githubIcon" alt="github" />
        </Button>
        <span class="text-[0.875rem] font-semibold text-white">Files</span>
      </div>

      <div class="flex items-center gap-2">
        <Button @click="handleCreateFile" text>
          <img class="file-add-icon" src="@/assets/file-add.svg" alt="file-add" />
        </Button>
      </div>
    </div>
    <div class="px-1 py-2">
      <SearchDraftsComponent
          :items="drafts"
          title="Search your drafts"
          :branch="draftBranch"
          :owner="owner"
          :repo="repo"
          :repoUrl="repoUrl"
      />
    </div>
    <!-- Multi-select Actions -->
    <div
      v-if="isMultiSelectMode && selectedItems.length > 0"
      class="px-4 mb-2 flex gap-2"
    >
      <Button
        @click="handleBulkDelete"
        icon="pi pi-trash"
        severity="danger"
        :badge="selectedItems.length.toString()"
        text
      />
      <Button @click="$emit('select-all')" class="p-0" text>
        <i
          :class="['pi', isAllSelected ? 'pi-delete-left' : 'pi-check-square']"
          style="color: #888888"
        />
      </Button>
      <Button as="a" target="_blank" rel="noopener" text>
        <img class="w-[1.25rem] h-[1.25rem]" :src="githubIcon" alt="github" />
      </Button>
    </div>

    <!-- New File Input -->
    <div v-if="localShowNewFileInput" class="px-4 mb-2">
      <input
        v-model="newFileName"
        @keyup.enter="createNewFile"
        @keyup.esc="cancelNewFile"
        placeholder="Enter file name"
        ref="fileNameInput"
        class="w-full p-2 bg-[#1e1e1e] text-white border border-gray-700 rounded"
      />
    </div>

    <!-- Loading Skeleton -->
    <div v-if="!hasContent && isLoading" class="px-4">
      <Skeleton width="100%" height="10rem" borderRadius="16px"></Skeleton>
    </div>

    <!-- File Explorer Content -->
    <ul
      v-else-if="hasContent"
      class="mt-[1rem] mb-[2rem] flex flex-col gap-[0.5rem] px-4"
    >
      <!-- Recursive Folder Component -->
      <template v-for="folder in folders" :key="folder.path">
        <div class="folder-item">
          <div
            @click="toggleFolder(folder)"
            class="flex items-center gap-2 p-2 hover:bg-[#1e1e1e] cursor-pointer"
          >
            <i
              :class="[
                'pi pi-chevron-right transition-transform duration-200',
                folder.expanded ? 'rotate-90' : '',
              ]"
            ></i>
            <div class="icon-container">
              <img src="@/assets/folder.svg" class="folder-icon" />
            </div>
            <span class="text-gray-300 text-[0.875rem] font-normal">{{
              folder.name
            }}</span>
          </div>

          <!-- Documents and Subfolders -->
          <div v-if="folder.expanded" class="pl-6">
            <!-- Documents in current folder -->
            <div
              v-for="doc in folder.documents"
              :key="doc.path"
              class="flex items-center justify-between"
            >
              <div class="flex items-center gap-2 flex-grow">
                <Checkbox
                  v-if="isMultiSelectMode"
                  v-model="selectedItems"
                  :value="doc.path"
                  :binary="false"
                  class="ml-2"
                />
                <router-link
                  v-if="isNewFile(doc.path)"
                  @click="removeNewTag(doc.path)"
                  @contextmenu="onRightClick($event, doc)"
                  :to="{
                    path: '/editor',
                    query: {
                      file: doc.path,
                      branch: draftBranch,
                      repo: `${owner}%2F${repo}`,
                    },
                  }"
                  :class="[
                    'text-[0.875rem] hover:text-white cursor-pointer expand-file-name flex-grow p-2',
                    selectedDraft === doc.path ? 'active-draft' : '',
                  ]"
                  style="
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                  v-tooltip.left="doc.path"
                >
                  <template v-slot:default>
                    <div class="icon-container">
                    <img
                      src="@/assets/file.svg"
                      alt="file"
                      class="w-4 h-4 mr-2 file-icon"
                    />
                    </div>
                    <Tag
                      v-if="isNewFile(doc.path)"
                      class="pr-2"
                      severity="success"
                      value="new"
                    ></Tag>
                    {{ removeFileExtension(doc.name) }}
                  </template>
                </router-link>
                <router-link
                  v-else
                  @contextmenu="onRightClick($event, doc)"
                  :to="{
                    path: '/editor',
                    query: {
                      file: doc.path,
                      branch: draftBranch,
                      repo: `${owner}%2F${repo}`,
                    },
                  }"
                  :class="[
                    'text-[0.875rem] hover:text-white cursor-pointer expand-file-name flex-grow p-2',
                    selectedDraft === doc.path ? 'active-draft' : '',
                  ]"
                  style="
                    max-width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                  v-tooltip.left="doc.path"
                >
                  <template v-slot:default>
                    <div class="icon-container">
                    <img
                      src="@/assets/file.svg"
                      alt="file"
                      class="w-4 h-4 mr-2 file-icon"
                    />
                    </div>
                    {{ removeFileExtension(doc.name) }}
                  </template>
                </router-link>
              </div>
            </div>

            <!-- Nested Subfolders -->
            <template
              v-for="subfolder in folder.subFolders"
              :key="subfolder.path"
            >
              <div class="folder-item">
                <div
                  @click="toggleFolder(subfolder)"
                  class="flex items-center gap-2 p-2 hover:bg-[#1e1e1e] cursor-pointer"
                >
                  <div class="icon-container">
                  <i
                    :class="[
                      'pi pi-chevron-right arrow-icon transition-transform duration-200',
                      subfolder.expanded ? 'rotate-90' : '',
                    ]"
                  ></i>
                  </div>
                  <div class="icon-container">
                    <img src="@/assets/folder.svg" class="folder-icon" />
                  </div>
                  <span class="text-gray-300 text-[0.875rem] font-normal">{{
                    subfolder.name
                  }}</span>
                </div>

                <!-- Recursive subfolders content -->
                <div v-if="subfolder.expanded" class="pl-6">
                  <!-- Documents in subfolder -->
                  <div
                    v-for="doc in subfolder.documents"
                    :key="doc.path"
                    class="flex items-center justify-between"
                  >
                    <div class="flex items-center gap-2 flex-grow">
                      <Checkbox
                        v-if="isMultiSelectMode"
                        v-model="selectedItems"
                        :value="doc.path"
                        :binary="false"
                        class="ml-2"
                      />
                      <router-link
                        v-if="isNewFile(doc.path)"
                        @click="removeNewTag(doc.path)"
                        @contextmenu="onRightClick($event, doc)"
                        :to="{
                          path: '/editor',
                          query: {
                            file: doc.path,
                            branch: draftBranch,
                            repo: `${owner}%2F${repo}`,
                          },
                        }"
                        :class="[
                          'text-[0.875rem] hover:text-white cursor-pointer flex-grow p-2',
                          selectedDraft === doc.path ? 'active-draft' : '',
                        ]"
                        style="
                          max-width: 100%;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          border-left: 1px solid rgba(122, 123, 128, 0.1);
                        "
                        v-tooltip.left="doc.path"
                      >
                        <template v-slot:default>
                          <div class="icon-container">
                            <img
                              src="@/assets/file.svg"
                              alt="file"
                              class="w-4 h-4 mr-2 file-icon"
                          />
                          </div>
                          <Tag
                            v-if="isNewFile(doc.path)"
                            class="pr-2"
                            severity="success"
                            value="new"
                          ></Tag>
                          {{ removeFileExtension(doc.name) }}
                        </template>
                      </router-link>
                      <router-link
                        v-else
                        @contextmenu="onRightClick($event, doc)"
                        :to="{
                          path: '/editor',
                          query: {
                            file: doc.path,
                            branch: draftBranch,
                            repo: `${owner}%2F${repo}`,
                          },
                        }"
                        :class="[
                          'text-[0.875rem] hover:text-white cursor-pointer flex-grow p-2',
                          selectedDraft === doc.path ? 'active-draft' : '',
                        ]"
                        style="
                          max-width: 100%;
                          overflow: hidden;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                        "
                        v-tooltip.left="doc.path"
                      >
                        <template v-slot:default>
                          <div class="icon-container">
                            <img
                              src="@/assets/file.svg"
                              alt="file"
                              class="w-4 h-4 mr-2 file-icon"
                          />
                          </div>  
                          {{ removeFileExtension(doc.name) }}
                        </template>
                      </router-link>
                    </div>
                  </div>

                  <!-- Further nested subfolders -->
                  <template
                    v-for="nestedFolder in subfolder.subFolders"
                    :key="nestedFolder.path"
                  >
                    <!-- Recursively render nested folders -->
                    <div class="folder-item">
                      <div
                        @click="toggleFolder(nestedFolder)"
                        class="flex items-center gap-2 p-2 hover:bg-[#1e1e1e] cursor-pointer"
                      >
                        <i
                          :class="[
                            'pi pi-chevron-right transition-transform duration-200',
                            nestedFolder.expanded ? 'rotate-90' : '',
                          ]"
                        ></i>
                        <img src="@/assets/folder.svg" class="folder-icon" />
                        <span
                          class="text-gray-300 text-[0.875rem] font-normal"
                          >{{ nestedFolder.name }}</span
                        >
                      </div>

                      <!-- Recursive content for deeper levels -->
                      <div v-if="nestedFolder.expanded" class="pl-6">
                        <!-- Similar structure for documents and further nesting -->
                        <!-- This pattern can continue up to 5 levels -->
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

      <!-- Root level documents -->
      <div
        v-for="doc in rootDocuments"
        :key="doc.name"
        class="flex items-center justify-between"
      >
        <div class="flex items-center gap-2 flex-grow">
          <Checkbox
            v-if="isMultiSelectMode"
            v-model="selectedItems"
            :value="doc.path"
            :binary="false"
            class="ml-2"
          />
          <router-link
            v-if="isNewFile(doc.path)"
            @click="removeNewTag(doc.path)"
            @contextmenu="onRightClick($event, doc)"
            :to="{
              path: '/editor',
              query: {
                file: doc.path,
                branch: draftBranch,
                repo: `${owner}%2F${repo}`,
              },
            }"
            :class="[
              'text-[0.875rem] hover:text-white cursor-pointer flex-grow p-2',
              selectedDraft === doc.path ? 'active-draft' : '',
            ]"
            style="
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            v-tooltip.left="doc.path"
          >
            <template v-slot:default>
              <div class="icon-container">
              <img
                src="@/assets/file.svg"
                alt="file"
                class="w-4 h-4 mr-2 file-icon"
              />
              </div>
              <Tag
                v-if="isNewFile(doc.path)"
                class="pr-2"
                severity="success"
                value="new"
              ></Tag>
              {{ removeFileExtension(doc.name) }}
            </template>
          </router-link>
          <router-link
            v-else
            @contextmenu="onRightClick($event, doc)"
            :to="{
              path: '/editor',
              query: {
                file: doc.path,
                branch: draftBranch,
                repo: `${owner}%2F${repo}`,
              },
            }"
            :class="[
              'text-[0.875rem] hover:text-white cursor-pointer flex-grow p-2',
              selectedDraft === doc.path ? 'active-draft' : '',
            ]"
            style="
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            v-tooltip.left="doc.path"
          >
            <template v-slot:default>
              <div class="icon-container">
              <img
                src="@/assets/file.svg"
                alt="file"
                class="w-4 h-4 mr-2 file-icon"
              />
              </div>
              {{ removeFileExtension(doc.name) }}
            </template>
          </router-link>
        </div>
      </div>

      <!-- Non-MDX files -->
      <div
        v-for="file in nonMdxFiles"
        :key="file.name"
        class="flex items-center justify-between"
      >
        <div class="flex items-center gap-2 flex-grow">
          <Checkbox
            v-if="isMultiSelectMode"
            v-model="selectedItems"
            :value="file.path"
            :binary="false"
            class="ml-2"
          />
          <router-link
            @contextmenu="onRightClick($event, file)"
            :to="{
              path: '/editor',
              query: {
                file: file.path,
                branch: draftBranch,
                repo: `${owner}%2F${repo}`,
              },
            }"
            :class="[
              'text-[0.875rem] hover:text-white cursor-pointer flex-grow p-2',
              selectedDraft === file.path ? 'active-draft' : '',
            ]"
            style="
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            v-tooltip.left="file.path"
          >
            <template v-slot:default>
              <div class="icon-container">
              <img
                src="@/assets/file.svg"
                alt="file"
                class="w-4 h-4 mr-2 file-icon"
              />
              </div>
              {{ removeFileExtension(file.name) }}
            </template>
          </router-link>
        </div>
      </div>
    </ul>

    <!-- Empty State -->
    <div v-else class="px-4 py-8 text-center text-gray-400">
      <img src="@/assets/folder.svg" class="folder-icon" />
      <p>No files found</p>
      <Button @click="handleCreateFile" label="Create a file" class="mt-4" />
    </div>

    <!-- Published Drafts Section -->
    <div class="mt-6 px-4">
      <slot name="published-drafts"></slot>
    </div>
  </div>

  <!-- Context Menu -->
  <Teleport to="body">
    <div
      v-if="showContextMenu"
      class="context-menu"
      :style="{
        top: contextMenuPosition.y + 'px',
        left: contextMenuPosition.x + 'px',
      }"
    >
      <div class="flex flex-col bg-[#1c1c1c] rounded shadow-lg">
        <button
          @click="renameItem"
          class="context-menu-button p-2 hover:bg-[#2c2c2c] text-left flex items-center gap-2 text-white"
        >
          <i class="pi pi-pencil"></i> Rename
        </button>
        <button
          @click="deleteItem"
          class="context-menu-button p-2 hover:bg-[#2c2c2c] text-left flex items-center gap-2 text-red-400"
        >
          <i class="pi pi-trash"></i> Delete
        </button>
      </div>
    </div>
  </Teleport>
</template>

<script setup>
import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
  nextTick,
} from "vue";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Skeleton from "primevue/skeleton";
import Tag from "primevue/tag";
import { useRoute } from "vue-router";
import {
  createFileContent,
  getCustomMarkdownFiles,
  deleteFile,
  renameFileContent,
  getCustomFiles,
  getOwnerAndRepo,
  getFiles,
} from "../../plugins/devdocsBackendService.js";
import SearchDraftsComponent from "./SearchDraftsComponent.vue";
import githubIcon from "@/assets/icon-github.svg";

const route = useRoute();

const props = defineProps({
  drafts: {
    type: Array,
    default: () => [],
  },
  selectedDraft: {
    type: String,
    default: null,
  },
  nonMdxFiles: {
    type: Array,
    default: () => [],
  },
  currentPath: {
    type: String,
    default: "/",
  },
  isMultiSelectMode: {
    type: Boolean,
    default: false,
  },
  showNewFileInput: {
    type: Boolean,
    default: false,
  },
  newFiles: {
    type: Array,
    default: () => [],
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  draftBranch: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  "select-document",
  "select-file",
  "toggle-folder",
  "navigate",
  "create-folder",
  "toggle-multi-select",
  "select-all",
  "remove-new-tag",
  "files-updated",
]);

// State
const folders = ref([]);
const rootDocuments = ref([]);
const nonMdxFiles = ref([]);
const selectedItems = ref([]);
const newFileName = ref("");
const localShowNewFileInput = ref(false);
const localNewFiles = ref([]);
const isLocalLoading = ref(false);
let owner = ref(null);
let repo = ref(null);
const drafts = ref([]);
const fileNameInput = ref(null);
// Context menu
const showContextMenu = ref(false);
const contextMenuPosition = ref({ x: 0, y: 0 });
const selectedContextItem = ref(null);

// Computed properties
const hasContent = computed(() => {
  return (
    folders.value.length > 0 ||
    rootDocuments.value.length > 0 ||
    nonMdxFiles.value.length > 0
  );
});

const isAllSelected = computed(() => {
  const allItems = [
    ...rootDocuments.value.map((doc) => doc.path),
    ...folders.value.flatMap((folder) =>
      folder.documents.map((doc) => doc.path)
    ),
    ...nonMdxFiles.value.map((file) => file.path),
  ];

  return allItems.length > 0 && selectedItems.value.length === allItems.length;
});

// Add except computed property for localShowNewFileInput
const except = computed(() => ({
  localShowNewFileInput: localShowNewFileInput.value,
}));

// Methods
const toggleFolder = (folder) => {
  folder.expanded = !folder.expanded;
  emit("toggle-folder", folder);
};

const handleCreateFile = () => {
  localShowNewFileInput.value = true;
  nextTick(() => {
    fileNameInput.value?.focus();
  });
};

const handleCreateFolder = () => {
  emit("create-folder");
};

const cancelNewFile = () => {
  localShowNewFileInput.value = false;
  newFileName.value = "";
};

const createNewFile = async () => {
  if (!newFileName.value.trim()) return;

  try {
    isLocalLoading.value = true;

    // Add .mdx extension if not provided
    let fileName = newFileName.value;
    if (!fileName.endsWith(".mdx") && !fileName.endsWith(".md")) {
      fileName += ".mdx";
    }

    // Create the file
    await createFileContent({
      branch: props.draftBranch,
      fileName,
      owner: owner.value,
      repo: repo.value,
      content: "# New Document\n\nStart writing here...",
    });

    // Add to new files list with full path if in a folder
    const fullPath = fileName;
    localNewFiles.value.push(fullPath);

    // Reset input
    newFileName.value = "";
    localShowNewFileInput.value = false;

    // Fetch updated files
    await fetchDrafts();

    // Notify parent component that files have been updated
    emit("files-updated");
  } catch (error) {
    console.error("Error creating file:", error);
  } finally {
    isLocalLoading.value = false;
  }
};

// Add this function before fetchDrafts
const folderContainsNewFile = (folder) => {
  // Check documents in current folder
  const hasNewDocument = folder.documents.some((doc) => isNewFile(doc.path));
  if (hasNewDocument) return true;

  // Check subfolders recursively
  return folder.subFolders.some((subfolder) =>
    folderContainsNewFile(subfolder)
  );
};

const fetchDrafts = async () => {
  try {
    let ownerAndRepo = await getOwnerAndRepo();
    owner.value = ownerAndRepo.owner;
    repo.value = ownerAndRepo.repo;
    // Fetch markdown files
    const mdFiles = await getCustomMarkdownFiles({
      branch: props.draftBranch,
      owner: owner.value,
      repo: repo.value,
    });
    drafts.value = mdFiles.files;
    if (!mdFiles || !mdFiles.files) {
      folders.value = [];
      rootDocuments.value = [];
      return;
    }

    const files = mdFiles.files;
    const rootDocs = [];
    const folderStructure = [];

    // First pass: collect root documents
    files.forEach((path) => {
      if (!path.includes("/")) {
        rootDocs.push({
          name: path,
          path: path,
        });
      }
    });

    // Second pass: organize folders and their contents
    const folderPaths = files
      .filter((path) => path.includes("/"))
      .reduce((acc, path) => {
        const parts = path.split("/");
        const fileName = parts.pop();
        const folderPath = parts.join("/");

        if (!acc[folderPath]) {
          acc[folderPath] = {
            name: parts[parts.length - 1],
            path: folderPath,
            documents: [],
            subFolders: [],
            expanded: false,
          };
        }

        acc[folderPath].documents.push({
          name: fileName,
          path: path,
        });

        return acc;
      }, {});

    // Build folder hierarchy (up to 5 levels)
    Object.entries(folderPaths).forEach(([path, folder]) => {
      const depth = path.split("/").length;
      if (depth <= 5) {
        const parentPath = path.split("/").slice(0, -1).join("/");
        if (depth === 1) {
          folderStructure.push(folder);
        } else if (folderPaths[parentPath]) {
          folderPaths[parentPath].subFolders.push(folder);
        }
      }
    });

    // Update the reactive refs
    folders.value = folderStructure;
    rootDocuments.value = rootDocs;

    // Expand folders based on both new files and current route
    folders.value.forEach((folder) => {
      // Check if folder contains new files
      const hasNewFile = folderContainsNewFile(folder);
      // Check if folder is part of current route path
      const isInCurrentPath =
        route.query.file && route.query.file.startsWith(folder.path + "/");

      if (hasNewFile || isInCurrentPath) {
        folder.expanded = true;

        // Recursively expand parent folders
        const expandParentFolders = (subfolders) => {
          subfolders.forEach((subfolder) => {
            const hasNewFileInSubfolder = folderContainsNewFile(subfolder);
            const isInCurrentSubPath =
              route.query.file &&
              route.query.file.startsWith(subfolder.path + "/");

            if (hasNewFileInSubfolder || isInCurrentSubPath) {
              subfolder.expanded = true;
              expandParentFolders(subfolder.subFolders);
            }
          });
        };

        expandParentFolders(folder.subFolders);
      }
    });
  } catch (error) {
    console.error("Error fetching drafts:", error);
    folders.value = [];
    rootDocuments.value = [];
  }
};

const handleBulkDelete = async () => {
  if (selectedItems.value.length === 0) return;

  try {
    isLocalLoading.value = true;

    // Delete each selected file
    for (const filePath of selectedItems.value) {
      await deleteFile({
        branch: props.draftBranch,
        fileName: filePath,
        owner: props.owner,
        repo: props.repo,
      });
    }

    // Clear selection
    selectedItems.value = [];

    // Fetch updated files
    await fetchDrafts();

    // Notify parent component that files have been updated
    emit("files-updated");
  } catch (error) {
    console.error("Error deleting files:", error);
  } finally {
    isLocalLoading.value = false;
  }
};

const isNewFile = (path) => {
  return (
    localNewFiles.value.includes(path) ||
    (props.newFiles && props.newFiles.includes(path))
  );
};

const removeNewTag = (label) => {
  localNewFiles.value = localNewFiles.value.filter((file) => file !== label);
  let newFiles = localStorage.getItem("newFiles");
  newFiles = newFiles ? JSON.parse(newFiles) : [];
  localStorage.setItem(
    "newFiles",
    JSON.stringify(newFiles.filter((file) => file !== label))
  );
};

const removeFileExtension = (filename) => {
  return filename ? filename.replace(/\.[^/.]+$/, "") : filename;
};

// Context menu methods
const onRightClick = (event, item) => {
  event.preventDefault();
  selectedContextItem.value = item;
  contextMenuPosition.value = { x: event.clientX, y: event.clientY };
  showContextMenu.value = true;
};

const openMoreOptions = (item) => {
  event.preventDefault();
  selectedContextItem.value = item;
  contextMenuPosition.value = { x: event.clientX, y: event.clientY };
  showContextMenu.value = true;
};

const closeContextMenu = () => {
  showContextMenu.value = false;
};

const renameItem = async () => {
  try {
    const item = selectedContextItem.value;
    if (!item) return;

    // Prompt for new name
    const newName = prompt("Enter new name:", removeFileExtension(item.name));
    if (!newName || newName.trim() === "") return;

    isLocalLoading.value = true;

    // Add extension if needed
    let finalNewName = newName;
    if (item.path.endsWith(".mdx") && !finalNewName.endsWith(".mdx")) {
      finalNewName += ".mdx";
    } else if (item.path.endsWith(".md") && !finalNewName.endsWith(".md")) {
      finalNewName += ".md";
    }

    // Get folder path if any
    const pathParts = item.path.split("/");
    const folderPath =
      pathParts.length > 1 ? pathParts.slice(0, -1).join("/") + "/" : "";

    // Rename the file
    await renameFileContent({
      branch: props.draftBranch,
      fileName: item.path,
      owner: owner.value,
      repo: repo.value,
      newName: folderPath + finalNewName,
    });

    // Fetch updated files
    await fetchDrafts();

    // Notify parent component that files have been updated
    emit("files-updated");
  } catch (error) {
    console.error("Error renaming file:", error);
  } finally {
    isLocalLoading.value = false;
    closeContextMenu();
  }
};

const deleteItem = async () => {
  try {
    const item = selectedContextItem.value;
    if (!item) return;

    // Confirm deletion
    if (!confirm(`Are you sure you want to delete ${item.name}?`)) return;

    isLocalLoading.value = true;

    // Delete the file
    await deleteFile({
      branch: props.draftBranch,
      fileName: item.path,
      owner: owner.value,
      repo: repo.value,
    });

    // Fetch updated files
    await fetchDrafts();

    // Notify parent component that files have been updated
    emit("files-updated");
  } catch (error) {
    console.error("Error deleting file:", error);
  } finally {
    isLocalLoading.value = false;
    closeContextMenu();
  }
};

// Process drafts into folder structure
const processDrafts = async () => {
  let getOwnerAndRepoData = await getOwnerAndRepo();
  let owner = getOwnerAndRepoData.owner;
  let repo = getOwnerAndRepoData.repo;

  const folderMap = new Map();
  const rootDocs = [];

  let markdownDocs = await getFiles({
    branch: props.draftBranch,
    owner: props.owner,
    repo: props.repo,
  });

  if (markdownDocs && markdownDocs.files) {
    const files = markdownDocs.files;

    // Process each file
    files.forEach((path) => {
      const parts = path.split("/");
      const fileName = parts.pop();

      if (parts.length === 0) {
        // Root level document
        rootDocs.push({
          name: fileName,
          path: path,
        });
      } else {
        // Nested document
        let currentLevel = folderMap;
        let currentPath = "";

        // Process each folder level (up to 5 levels)
        for (let i = 0; i < Math.min(parts.length, 5); i++) {
          const folderName = parts[i];
          currentPath = currentPath
            ? `${currentPath}/${folderName}`
            : folderName;

          if (!currentLevel.has(currentPath)) {
            currentLevel.set(currentPath, {
              name: folderName,
              path: currentPath,
              documents: [],
              subFolders: new Map(),
              expanded: false,
            });
          }

          const folder = currentLevel.get(currentPath);

          if (i === parts.length - 1) {
            // Add document to this folder
            folder.documents.push({
              name: fileName,
              path: path,
            });
          } else {
            // Move to next folder level
            currentLevel = folder.subFolders;
          }
        }
      }
    });
  }

  // Convert nested Maps to arrays for Vue rendering
  const convertFolderMapToArray = (folderMap) => {
    return Array.from(folderMap.values()).map((folder) => ({
      ...folder,
      subFolders: convertFolderMapToArray(folder.subFolders),
    }));
  };

  folders.value = convertFolderMapToArray(folderMap);
  rootDocuments.value = rootDocs;
};

// Process non-MDX files
const processNonMdxFiles = (filesArray = props.nonMdxFiles) => {
  if (!filesArray || filesArray.length === 0) {
    nonMdxFiles.value = [];
    return;
  }

  nonMdxFiles.value = filesArray.map((file) => {
    const path = typeof file === "string" ? file : file.label || "";
    return {
      name: path.split("/").pop(),
      path: path,
    };
  });
};

const repoUrl = computed(() => {
  return `https://github.com/${owner.value}/${repo.value}`;
});

// Close context menu when clicking outside
const handleClickOutside = (event) => {
  if (showContextMenu.value) {
    const contextMenuElement = document.querySelector(".context-menu");
    const menuButton = event.target.closest("button");
    // Don't close if clicking inside menu or if clicking a menu button
    if (
      contextMenuElement &&
      !contextMenuElement.contains(event.target) &&
      !menuButton?.classList.contains("context-menu-button")
    ) {
      closeContextMenu();
    }
  }
};

// Add this after the props definition
watch(
  () => props.newFiles,
  (newValue) => {
    if (newValue) {
      // Update local new files list
      localNewFiles.value = [...new Set([...localNewFiles.value, ...newValue])];

      // Trigger re-fetch of drafts to update folder structure and auto-expand
      fetchDrafts();
    }
  },
  { deep: true }
);

// Add this new function
const expandFoldersForPath = (filePath) => {
  if (!filePath) return;

  const pathParts = filePath.split("/");
  if (pathParts.length <= 1) return; // No folders to expand

  // Remove the file name to get just the folder path parts
  pathParts.pop();

  // Expand each folder level
  let currentPath = "";
  folders.value.forEach((folder) => {
    if (pathParts[0] === folder.name) {
      folder.expanded = true;
      currentPath = folder.name;

      // Handle nested folders
      const expandNestedFolders = (subfolders, depth) => {
        subfolders.forEach((subfolder) => {
          if (pathParts[depth] === subfolder.name) {
            subfolder.expanded = true;
            expandNestedFolders(subfolder.subFolders, depth + 1);
          }
        });
      };

      expandNestedFolders(folder.subFolders, 1);
    }
  });
};

// Add a watch for route changes
watch(
  () => route.query.file,
  (newFile) => {
    if (newFile) {
      expandFoldersForPath(newFile);
    }
  }
);

// Modify onMounted to include the initial expansion
onMounted(async () => {
  await fetchDrafts();
  if (route.query.file) {
    expandFoldersForPath(route.query.file);
  }
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>

<style lang="scss" scoped>
.file-explorer-sidebar {
  width: 100%;
  border-right: 1px solid rgba(122, 123, 128, 0.2);
}

.folder-item {
  margin-bottom: 4px;
}

/* Nested folder indentation */
.folder-item .pl-6 {
  //border-left: 1px solid rgba(122, 123, 128, 0.1);
  margin-left: 0.75rem; /* Half of pl-6 (1.5rem) */

}

/* Hover effect for folder items */
.folder-item > div:first-child:hover {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

/* Custom styling to match the design */
:deep(.p-button.p-button-text) {
  color: #d4d4d4;
}

:deep(.p-button.p-button-text:hover) {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}

.pi-file-o {
  color: #4a6bef !important;
}

.pi-folder {
  color: #a0a0a0 !important;
}

.context-menu {
  position: fixed;
  z-index: 1000;
  color: white;
}

.context-menu button {
  width: 100%;
  text-align: left;
}

.active-draft {
  border-radius: 0.5rem;
  background: #525151;
}

/* Router link alignment */
a[class*="router-link"] {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  gap: 0.5rem;
  font-size: 0.875rem;
  font-style: normal;
}

.pi-chevron-right {
  font-size: 0.75rem;
  color: #a0a0a0;
}

.rotate-90 {
  transform: rotate(90deg);
}

.transition-transform {
  transition: transform 0.2s ease;
}

/* Add consistent text styling for all file and folder names */
.text-\[0\.875rem\] {
  font-size: 0.875rem;
  font-style: normal;
}

/* all icons below here */
.folder-icon {
  width: 100%;
  height: 100%;
  max-width: 1.5rem;
  max-height: 1.5rem;
  min-width: 1.5rem;
  min-height: 1.5rem;
  flex-shrink: 0;
}

.file-icon {
  width: 0.8rem;
  height: 1rem;
  max-width: 0.8rem;
  max-height: 1rem;
  min-width: 0.8rem;
  min-height: 1rem;
  flex-shrink: 0;
}

.arrow-icon {
  width: 0.75rem;
  height: 0.75rem;
  flex-shrink: 0;
}

.icon-container {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.github-icon {
  width: 0.87006rem;
  height: 0.91694rem;
}

</style>

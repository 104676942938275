<template>
  <div>
    <div v-if="listInfo">
      <div class="flex items-center justify-between mb-4">
        <span class="text-sm text-gray-400" v-if="isLoadedFromCache">
          Using cached data
        </span>
        <div class="flex gap-2">
          <Button
            icon="pi pi-refresh"
            @click="refreshData"
            label="Run Again"
            class="text-white p-button-sm"
          />
          <Button
            icon="pi pi-trash"
            @click="clearData"
            label="Clear"
            class="text-white p-button-sm"
          />
        </div>
      </div>
      <ScrollPanel style="width: 100%; height: 100vh">
        <Accordion v-model:activeIndex="activeAccordionIndex" class="mb-4">
          <AccordionTab header="Select Items to Document">
            <div>
              <SelectButton
                class="mt-2 mb-2"
                v-model="value"
                :options="options"
                aria-labelledby="basic"
              />
              <DataTable
                :value="items"
                v-model:selection="selectedItems"
                v-model:filters="filters"
                :paginator="true"
                :rows="5"
                filterDisplay="menu"
                :globalFilterFields="['name', 'type', 'documented', 'path']"
                currentPageReportTemplate="Displaying {first} to {last} of {totalRecords}"
              >
                <template #header>
                  <div class="flex items-center justify-between w-full">
                    <div>Select the items you want document</div>
                    <InputText
                      v-model="filters['global'].value"
                      placeholder="Keyword Search"
                    />
                  </div>
                </template>
                <template #paginatorstart>
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      flex-direction: row;
                      min-width: 500px;
                    "
                  >
                    <Button @click="handleGeneration">
                      Copy Pages to Drafts
                    </Button>
                  </div>
                </template>
                <Column
                  selectionMode="multiple"
                  headerStyle="width: 3rem"
                ></Column>
                <Column field="name" header="name" filterField="name">
                  <template #filter="{ filterModel, filterCallback }">
                    <InputText
                      v-model="filterModel.value"
                      @input="filterCallback()"
                      placeholder="Search by name"
                    />
                  </template>
                </Column>
                <Column field="type" header="type" filterField="type">
                  <template #filter="{ filterModel, filterCallback }">
                    <InputText
                      v-model="filterModel.value"
                      @input="filterCallback()"
                      placeholder="Search by type"
                    />
                  </template>
                </Column>
                <Column field="documented" header="documented">
                  <template #filter="{ filterModel, filterCallback }">
                    <Checkbox
                      v-model="filterModel.value"
                      @change="filterCallback()"
                      :binary="true"
                    />
                  </template>
                </Column>
                <Column field="path" header="path">
                  <template #filter="{ filterModel, filterCallback }">
                    <InputText
                      v-model="filterModel.value"
                      @input="filterCallback()"
                      placeholder="Search by path"
                    />
                  </template>
                </Column>
              </DataTable>
            </div>

            <div class="flex flex-col gap-[0.62rem] mt-4">
              <div style="display: flex; position: relative">
                <Textarea
                  placeholder="Enter prompt here for what you want Dev-Docs to document on the selected items above."
                  class="w-full px-[0.63rem] py-[0.44rem] rounded-[0.5rem] bg-[#1C1C1C] min-h-[9.375rem] !border-none"
                  autoResize
                  v-model="codeObjectPrompt"
                  variant="filled"
                  autofocus
                  fluid
                  rows="2"
                />
              </div>
            </div>
          </AccordionTab>

          <AccordionTab header="Documentation Generation">
            <div class="flex flex-col gap-4">
              <ProgressItemsBar
                :items="selectedItems"
                :processedItems="processedItems"
                itemType="drafts"
              />

              <!-- Show document carousel for both streaming and cached content -->
              <div v-if="streamingResponses.length" class="flex flex-col gap-4 mt-4">
                <DocumentCarousel
                  v-model:documents="streamingResponses"
                  :branch="branch"
                  :owner="documentationRepo?.owner"
                  :repo="documentationRepo?.repo"
                  action="code_objects_docs"
                  @accept="handleAccept"
                  @revert="handleRevert"
                  @clear-cached="streamingResponses = []"
                  @discard="handleDiscard"
                />
              </div>

              <!-- Show streaming preview only during active generation -->
              <div v-else-if="loading && streamingContent" class="flex flex-col gap-4 mt-4">
                <StreamingPreview
                  :content="streamingContent"
                />
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </ScrollPanel>
    </div>
    <div v-else>
      <div v-if="hasCachedContent" class="mb-4">
        <DocumentCarousel
          v-model:documents="cachedDocument"
          :branch="branch"
          :owner="documentationRepo?.owner"
          :repo="documentationRepo?.repo"
          action="code_objects_docs"
          @accept="handleAccept"
          @revert="handleRevert"
          @discard="handleDiscard"
          @clear-cached="cachedDocument = []"
          @load-cached="handleLoadCached"
        />
      </div>
      <div
        v-if="loading"
        class="flex flex-col p-fluid gap-[1.25rem] mt-[1.25rem]"
      >
        <StreamingPreview v-if="streamingContent" :content="streamingContent" />
        <StreamingPreview
          v-else="'Generating Documentation...'"
          :content="streamingContent"
        />
      </div>
      <div v-else class="flex flex-col p-fluid gap-[1.25rem] mt-[1.25rem]">
        <div class="mt-4">
          <label class="mt-4 mb-4" for="folders"
            >Which files are these code objects typically defined in?</label
          >
          <ContextSelectTree
            v-if="repoContextItems"
            :data="folderObject"
            :additional-items="repoContextItems"
            v-model="selectedFolders"
            :selected-folder-items="selectedFolders"
            @selection-change="handleSelectionItemChange"
            :showGithubAppLink="false"
            class="mt-[0.62rem] !bg-[#1C1C1C]"
          />
        </div>
        <div class="mt-4">
          <label class="mt-4" for="folders"
            >Which files are relevant as context to the code objects? This can
            be any code file that has relevant supporting code but might not be
            where the actual code object is defined.</label
          >
          <ContextSelectTree
            v-if="repoContextItems"
            :data="folderObject"
            :additional-items="repoContextItems"
            v-model="selectedContextFolders"
            :selected-folder-items="selectedContextFolders"
            @selection-change="handleContextSelectionItemChange"
            class="mt-[0.62rem] !bg-[#1C1C1C]"
          />
        </div>

        <div class="flex flex-col gap-[0.62rem]">
          <div>Select the type of items you want Dev-Docs to document.</div>
          <div style="display: flex; position: relative">
            <Textarea
              placeholder="Code objects you want documented, separated by commas"
              class="w-full px-[0.63rem] py-[0.44rem] rounded-[0.5rem] bg-[#1C1C1C] min-h-[9.375rem] !border-none"
              autoResize
              v-model="newListValue"
              variant="filled"
              autofocus
              fluid
              rows="2"
            />
          </div>
        </div>

        <div class="flex flex-col gap-[0.62rem]">
          <div class="">
            <PopularPrompts
              title="Popular code objects to document:"
              :items="[
                'functions',
                'classes',
                'methods',
                'cli commands',
                'vscode extension commands',
                'API routes',
              ]"
              v-model="newListValue"
              :appendMode="true"
            />
          </div>
        </div>

        <div class="flex self-end justify-end w-fit">
          <Button
            label="Submit"
            @click="listYourDocs"
            class="mb-8 mr-16 w-fit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "@primevue/core/api";
import ContextSelectTree from "./ContextSelectTree.vue";
import PopularPrompts from "./PopularPrompts.vue";
import ProgressItemsBar from "./EditorComponents/ProgressItemsBar.vue";
import indexDbService from "../plugins/indexDbService.js";
import {
  getFileContent,
  getCustomFiles,
  getOwnerAndRepo,
} from "../plugins/devdocsBackendService.js";
import StreamingPreview from "./EditorComponents/StreamingPreview.vue";
import DocumentCarousel from "./EditorComponents/DocumentCarousel.vue";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";

function findParentPaths(items) {
  const paths = Array.isArray(items) ? items : [];
  return paths.filter((path, index) => {
    return !paths.some((otherPath, otherIndex) => {
      if (index === otherIndex) return false;
      return path.startsWith(otherPath + "/");
    });
  });
}

export default {
  props: {
    branch: {
      type: String,
    },
    repoContextItems: {
      type: Array,
      default: () => [],
    },
    folderObject: {
      type: Object,
      default: () => ({ category: "Documentation", files: [] }),
    },
  },
  data() {
    return {
      activeAccordionIndex: null,
      list: [],
      newListValue: "",
      selectedFolders: [],
      selectedContextFolders: [],
      currentDraftCount: null,
      processedItems: 0,
      checkCode: false,
      folderOptions: [],
      loading: false,
      listInfo: null,
      selectedItems: [],
      documentationRepo: null,
      repos: [],
      codeFiles: [],
      contextCodeFiles: [],
      reason: "",
      codeObjectPrompt: "",
      updatedContextSource: false,
      value: "Generate Issues",
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        documented: { value: null, matchMode: FilterMatchMode.EQUALS },
        path: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      isLoadedFromCache: false,
      streamingContent: "",
      streamingResponses: [],
      hasCachedContent: false,
      cachedDocument: null,
    };
  },
  computed: {
    progressPercentage() {
      if (!this.selectedItems.length) return 0;
      return (this.processedItems / this.selectedItems.length) * 100;
    },
  },
  components: {
    ContextSelectTree,
    PopularPrompts,
    ProgressItemsBar,
    StreamingPreview,
    DocumentCarousel,
    Accordion,
    AccordionTab,
  },
  async mounted() {
    this.documentationRepo = await getOwnerAndRepo();
    await this.checkExistingData();
    await this.loadStoredSelections();
    await this.checkCachedContent();
  },
  methods: {
    async loadStoredSelections() {
      const storedSelections = await indexDbService.getTableData(
        "codeObjectsSelections"
      );
      if (storedSelections) {
        this.selectedFolders = storedSelections.selectedFolders;
        this.selectedContextFolders = storedSelections.selectedContextFolders;
        this.codeFiles = storedSelections.codeFiles;
        this.contextCodeFiles = storedSelections.contextCodeFiles;
      }
    },
    async checkExistingData() {
      const cachedData = await indexDbService.getTableData("codeObjects");
      console.log("what is the cached data", cachedData);
      if (cachedData) {
        this.listInfo = cachedData.listInfo;
        this.items = cachedData.items;
        this.isLoadedFromCache = true;
      }
    },
    async checkCachedContent() {
      try {
        const cachedData = await indexDbService.getTableData('code_objects_docs_cached_docs');
        console.log('what is the cached data', cachedData);
        if (cachedData) {
          this.hasCachedContent = true;
          // Set streamingResponses directly since that's what the UI uses to display content
          this.streamingResponses = Array.isArray(cachedData.result) ? cachedData.result : [cachedData.result];
          this.activeAccordionIndex = 1; // Open Documentation Generation tab
          this.isLoadedFromCache = true;
          console.log('Loaded streaming responses:', this.streamingResponses);
        }
      } catch (e) {
        console.warn('Error checking cached content:', e);
      }
    },
    async handleLoadCached(cache) {
      try {
        // Set the content from the cached data
        this.streamingResponses = Array.isArray(cache.result) ? cache.result : [cache.result];
        this.loading = true;
        this.isLoadedFromCache = true;
        this.activeAccordionIndex = 1; // Switch to the Documentation Generation tab
        
        // Update the UI to show the loaded content
        this.$nextTick(() => {
          this.$toast.add({
            severity: 'success',
            summary: 'Loaded',
            detail: 'Successfully loaded cached content',
            life: 3000
          });
        });
      } catch (e) {
        console.error('Error loading cached content:', e);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load cached content',
          life: 3000
        });
      }
    },
    async getCodeSources() {
      try {
        let repos = await this.getRepos();
        let newRepoContextItems = [];
        console.log("what is repos", repos);
        let i = 0;
        for (let repo of repos) {
          console.log("what is repo here", repo);
          console.log(
            "what is this documentation repo",
            this.documentationRepo
          );
          if (
            this.documentationRepo.repo == repo.name &&
            this.documentationRepo.owner == repo.owner
          ) {
            continue;
          }
          let files = await getCustomFiles({
            branch: repo.default_branch,
            owner: repo.owner,
            repo: repo.name,
          });
          let repoContextItem = {
            label: repo.full_name,
            files: files?.files || [],
            category: repo.full_name,
          };
          if (i == 0) {
            this.folderObject = repoContextItem;
          } else {
            newRepoContextItems.push(repoContextItem);
          }
          i += 1;
        }
        this.repoContextItems = newRepoContextItems;
        this.updatedContextSource = true;
      } catch (e) {
        console.log(e);
      }
    },
    addlist() {
      let newListValue = this.newListValue.trim();
      this.list.push(newListValue);
      this.newListValue = "";
    },
    async getFolders() {
      var myHeaders = new Headers();
      try {
        var token = await this.$authInstance.getToken();

        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        var url = await this.$authInstance.getBaseUrl();
        var response = await fetch(`${url}/folders`, requestOptions);
        var jsonResponse = await response.json();
        this.folderOptions = jsonResponse;
        this.folderObject = { category: "Documentation", files: jsonResponse };
      } catch (e) {
        this.folderOptions = [];
      }
    },
    handleSelectionItemChange(value) {
      // Handle the selections here
      console.log("Selected folders:", value);
      this.selectedFolders = value.selections;
      let byCategory = value.byCategory;
      this.codeFiles = byCategory;

      // Store selections
      indexDbService.saveTableData("codeObjectsSelections", {
        selectedFolders: this.selectedFolders,
        selectedContextFolders: this.selectedContextFolders,
        codeFiles: this.codeFiles,
        contextCodeFiles: this.contextCodeFiles,
      });
    },
    handleContextSelectionItemChange(value) {
      // Handle the selections here
      console.log("Selected folders:", value);
      this.selectedContextFolders = value.selections;
      let byCategory = value.byCategory;
      this.contextCodeFiles = byCategory;

      // Store selections
      indexDbService.saveTableData("codeObjectsSelections", {
        selectedFolders: this.selectedFolders,
        selectedContextFolders: this.selectedContextFolders,
        codeFiles: this.codeFiles,
        contextCodeFiles: this.contextCodeFiles,
      });
    },
    async getRepos() {
      var myHeaders = new Headers();
      try {
        var token = await this.$authInstance.getToken();

        myHeaders.append("Content-Type", "application/json");
        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        let url = await this.$authInstance.getBaseUrl();
        let response = await fetch(`${url}/github_app_repos`, requestOptions);
        let jsonResponse = await response.json();
        //this.repos = jsonResponse?.repos
        return jsonResponse?.repos;
      } catch (e) {
        //this.folderOptions = []
      }
    },
    removeList(index) {
      console.log(index);
      this.list.splice(index, 1);
    },
    async handleGeneration() {
      this.processedItems = 0;
      this.currentDraftCount = 0;
      this.streamingContent = '';
      this.streamingResponses = [];
      this.activeAccordionIndex = 1;
      this.loading = true;

      try {
        const [org, token, url] = await Promise.all([
          this.$authInstance.getOrg(),
          this.$authInstance.getToken(),
          this.$authInstance.getBaseUrl()
        ]);

        if (!token || !url) {
          throw new Error("Missing authentication information");
        }

        // Process items sequentially to maintain order
        for (const [index, selected] of this.selectedItems.entries()) {
          try {
            const message = `Generate documentation for ${selected.name} with the following requirements: ${selected.reason}. Please respond with the documentation in <response> </response> tags.`;
            
            // Create codeObjects map with full repo name as key
            let codeObjects = {};
            codeObjects[selected.codeContext.full_repo_name] = selected.codeContext.codefiles;

            const requestBody = {
              message,
              codeObjects,
              name: selected.name,
              type: 'code_objects',
              reason: selected.reason,
              instructions: this.codeObjectPrompt,
              githubRepository: selected.codeContext.full_repo_name,
              returnStream: true
            };

            if (this.codeObjectPrompt) {
              requestBody.message += `\n\nAdditional requirements: ${this.codeObjectPrompt}`;
            }

            const response = await fetch(
              `${url}/ai/messages`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(requestBody)
              }
            );

            if (!response.ok) {
              throw new Error(`Failed to generate documentation for ${selected.name}`);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let accumulatedText = '';

            let streamingResponse = {
              id: Date.now() + index,
              content: '',
              fileName: `docs/${selected.name}.md`,
              complete: false,
              branch: this.branch,
              owner: this.documentationRepo?.owner,
              repo: this.documentationRepo?.repo
            };

            while (true) {
              const { done, value } = await reader.read();
              if (done) break;

              const decoded = decoder.decode(value);
              accumulatedText += decoded;
              
              // Update streaming content in real-time
              this.streamingContent = accumulatedText;

              // Try to find response tags in accumulated text
              const responseMatch = accumulatedText.match(/<response>(.*?)<\/response>/s);
              if (responseMatch) {
                const cleanContent = responseMatch[1].trim();
                
                // Only update the streaming responses once we have complete content
                if (!this.streamingResponses.some(r => r.id === streamingResponse.id)) {
                  // Create a new response object to avoid reference issues
                  const newResponse = {
                    ...streamingResponse,
                    content: cleanContent,
                    complete: true
                  };
                  this.streamingResponses = [...this.streamingResponses, newResponse];
                  this.processedItems++;
                  
                  // Clear streaming content after adding to carousel
                  this.streamingContent = '';
                }
              }
            }

          } catch (error) {
            console.error(`Error processing ${selected.name}:`, error);
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: `Failed to generate documentation for ${selected.name}: ${error.message}`,
              life: 3000
            });
          }
        }

      } catch (error) {
        console.error('Error in handleGeneration:', error);
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error.message,
          life: 3000
        });
      } finally {
        this.loading = false;
      }
    },
    async generateIssues(data) {
      this.loading = true;
      var myHeaders = new Headers();
      try {
        let { content, title } = data;
        let org = await this.$authInstance.getOrg();
        let token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");

        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }

        const raw = JSON.stringify({ content, title });
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow", // 'as const' ensures TypeScript understands this as a literal type
        };

        let url = await this.$authInstance.getBaseUrl();

        const saveResponseTwo = await fetch(`${url}/issues`, requestOptions);
        this.loading = false;
        let result = await saveResponseTwo.json();
        console.log(result);
        let tableInfo = JSON.parse(result.content);
        console.log(tableInfo);
        this.listInfo = tableInfo;
        this.items = tableInfo.files;
      } catch (e) {
        console.log(e);
        return {};
      }
    },
    async updateYourDoc(data, shouldStream = false) {
      this.loading = true;
      var myHeaders = new Headers();
      try {
        let org = await this.$authInstance.getOrg();
        let token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");

        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }
        const raw = JSON.stringify({ ...data, returnStream: true });
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        let url = await this.$authInstance.getBaseUrl();
        const { body } = await fetch(
          `${url}/code_objects/docs`,
          requestOptions
        );
        const reader = body.getReader();
        const decoder = new TextDecoder();
        let accumulatedText = "";

        // Create streaming response object but don't add to array yet
        let streamingResponse = {
          id: Date.now(),
          content: "",
          fileName: data.web.fileName,
          complete: false,
        };

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            // Process the complete accumulated text after stream ends
            const responseMatch = accumulatedText.match(
              /<response>(.*?)<\/response>/s
            );
            if (responseMatch) {
              const cleanContent = responseMatch[1].trim();
              streamingResponse.content = cleanContent;
              streamingResponse.complete = true;
              // Clear streaming content before adding to carousel
              if (shouldStream) {
                this.streamingContent = "";
              }
              // Add to streamingResponses once we have the complete content
              this.streamingResponses.push(streamingResponse);
            }
            break;
          }

          const decoded = decoder.decode(value);
          // Treat all decoded content as markdown text
          accumulatedText += decoded;
          // Only update streamingContent if this is the first item
          if (shouldStream) {
            this.streamingContent = accumulatedText;
          }
        }
      } catch (e) {
        console.error("Error in updateYourDoc:", e);
      }
    },
    processSelectedFolders(folderObj) {
      try {
        let checkedFolders = Object.entries(folderObj)
          .filter(([key, value]) => value.checked)
          .map(([key]) => key);

        checkedFolders = checkedFolders.filter((folder) => folder !== "0");
        checkedFolders.sort(
          (a, b) => a.split("/").length - b.split("/").length
        );

        const result = [];

        for (let folder of checkedFolders) {
          const isParent = result.some((existingFolder) =>
            existingFolder.startsWith(folder + "/")
          );
          const isChild = result.some((existingFolder) =>
            folder.startsWith(existingFolder + "/")
          );

          if (!isParent && !isChild) {
            let parsedPath = folder.split("::parent::")[1];
            result.push(parsedPath);
          }
        }

        return result;
      } catch (e) {
        console.log(e);
        return [];
      }
    },
    async listYourDocs() {
      this.loading = true;
      let myHeaders = new Headers();
      try {
        let org = await this.$authInstance.getOrg();
        let token = await this.$authInstance.getToken();
        myHeaders.append("Content-Type", "application/json");

        if (token) {
          myHeaders.append("Authorization", `Bearer ${token}`);
        }
        this.list = this.newListValue.split(",");
        console.log("this is the selected folders", this.selectedFolders);
        let folders = this.codeFiles["Documentation"];
        folders = findParentPaths(folders);
        let codeObjects = this.codeFiles;
        delete codeObjects["Documentation"];
        const raw = JSON.stringify({
          codeObjects,
          listOfCodeObjects: this.list,
          returnStream: true,
        });
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        let url = await this.$authInstance.getBaseUrl();
        const { body } = await fetch(`${url}/code_objects`, requestOptions);
        const reader = body.getReader();
        const decoder = new TextDecoder();
        let collectedCodeObjects = [];
        let buffer = "";

        this.loading = true;
        this.streamingContent = "";
        let accumulatedContent = [];

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            // Handle any final bytes
            const lastChunk = decoder.decode();
            buffer += lastChunk;
            break;
          }

          const decoded = decoder.decode(value, { stream: true });
          this.streamingContent += decoded;
          buffer += decoded;

          // Split by newlines and process each complete JSON object
          const lines = buffer.split("\n");
          buffer = lines.pop() || ""; // Keep the last incomplete line in the buffer

          for (const line of lines) {
            if (line.trim()) {
              try {
                const data = JSON.parse(line);

                switch (data.type) {
                  case "status":
                    accumulatedContent.push(
                      JSON.stringify(
                        { type: "status", message: data.message },
                        null,
                        2
                      )
                    );
                    break;
                  case "progress":
                    accumulatedContent.push(
                      JSON.stringify(
                        {
                          type: "progress",
                          current: data.current,
                          total: data.total,
                        },
                        null,
                        2
                      )
                    );
                    break;
                  case "code_object_stream":
                    console.log("what is the data", data);
                    // Convert Buffer data to string and parse as JSON
                    if (data.data && data.data.type === "Buffer") {
                      const bufferString = new TextDecoder().decode(
                        new Uint8Array(data.data.data)
                      );
                      try {
                        const parsedData = JSON.parse(bufferString);
                        collectedCodeObjects.push(parsedData);
                        accumulatedContent.push(
                          JSON.stringify(
                            {
                              type: "code_object_stream",
                              snippetIndex: data.snippetIndex,
                              data: parsedData,
                            },
                            null,
                            2
                          )
                        );
                      } catch (e) {
                        console.log(
                          "Error parsing buffer data:",
                          e,
                          bufferString
                        );
                      }
                    } else {
                      collectedCodeObjects.push(data.data);
                      accumulatedContent.push(
                        JSON.stringify(
                          {
                            type: "code_object_stream",
                            snippetIndex: data.snippetIndex,
                            data: data.data,
                          },
                          null,
                          2
                        )
                      );
                    }
                    break;
                  case "completed":
                    console.log(
                      "Final collected code objects:",
                      collectedCodeObjects
                    );
                    this.listInfo = {
                      files: collectedCodeObjects[0]?.code_objects,
                    };
                    this.items = collectedCodeObjects[0]?.code_objects || [];
                    this.isLoadedFromCache = false;
                    // Save to IndexDB
                    await indexDbService.saveTableData("codeObjects", {
                      listInfo: this.listInfo,
                      items: this.items,
                    });
                    accumulatedContent.push(
                      JSON.stringify(
                        { type: "completed", message: data.message },
                        null,
                        2
                      )
                    );
                    break;
                  case "error":
                    accumulatedContent.push(
                      JSON.stringify(
                        {
                          type: "error",
                          snippetIndex: data.snippetIndex,
                          message: data.message,
                        },
                        null,
                        2
                      )
                    );
                    console.error(
                      `Error in snippet ${data.snippetIndex}:`,
                      data.message
                    );
                    break;
                  default:
                    accumulatedContent.push(JSON.stringify(data, null, 2));
                }
              } catch (e) {
                console.log("Error parsing JSON line:", e, "Line:", line);
              }
            }
          }
        }

        this.loading = false;
      } catch (e) {
        // Try to load from IndexDB if network fails
        const cachedData = await indexDbService.getTableData("codeObjects");
        if (cachedData) {
          this.listInfo = cachedData.listInfo;
          this.items = cachedData.items;
          this.isLoadedFromCache = true;
        }
        console.log(e);
      }
    },
    async clearData() {
      this.listInfo = null;
      this.items = [];
      this.selectedItems = [];
      this.isLoadedFromCache = false;
      this.processedItems = 0;
      await indexDbService.clearStore();
      this.resetFormValues();
    },

    resetFormValues() {
      this.newListValue = "";
      this.codeObjectPrompt = "";
      this.selectedFolders = [];
      this.selectedContextFolders = [];
      this.codeFiles = {};
      this.contextCodeFiles = {};
      this.list = [];
    },

    async refreshData() {
      this.listInfo = null;
      console.log(this.selectedFolders);
    },

    async handleAccept(streamingResponse) {
      try {
        let publishedBranch = localStorage.getItem("publishedBranch") || "main";
        const result = {
          content: streamingResponse.content,
          path: streamingResponse.fileName,
          branch: publishedBranch,
        };

        // Update localStorage with new files
        const existingFiles = JSON.parse(
          localStorage.getItem("newFiles") || "[]"
        );
        existingFiles.push(streamingResponse.fileName);
        localStorage.setItem("newFiles", JSON.stringify(existingFiles));

        // Remove this response from the carousel
        this.streamingResponses = this.streamingResponses.filter(
          (r) => r.id !== streamingResponse.id
        );

        // If all responses are handled, reset loading state
        if (this.streamingResponses.length === 0) {
          this.loading = false;
        }

        this.$emit("draft", result);
      } catch (e) {
        console.error("Error accepting content:", e);
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to save the content",
          life: 3000,
        });
      }
    },

    handleRevert(streamingResponse) {
      // Remove this response from the carousel
      this.streamingResponses = this.streamingResponses.filter(
        (r) => r.id !== streamingResponse.id
      );

      // If all responses are handled, reset loading state
      if (this.streamingResponses.length === 0) {
        this.loading = false;
      }
    },

    handleDiscard(streamingResponse) {
      // Remove this response from the carousel
      this.streamingResponses = this.streamingResponses.filter(
        (r) => r.id !== streamingResponse.id
      );

      // If all responses are handled, reset loading state
      if (this.streamingResponses.length === 0) {
        this.loading = false;
      }
    },
  },
};
</script>
<style>
.p-paginator {
  flex-direction: row;
}

.p-paginator-content-start {
  max-width: 350px;
}
</style>

import { mergeAttributes, Node } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import Component from './DetailsBlock.vue'

export default Node.create({
  name: 'detailsBlock',

  inline: true,
  group: 'inline',
  
  draggable: true,

  addAttributes() {
    return {
      summary: {
        default: '',
        parseHTML: element => element.getAttribute('summary') || '',
        renderHTML: attributes => {
          return {
            summary: attributes.summary
          }
        }
      },
      content: {
        default: '',
        parseHTML: element => element.getAttribute('content') || '',
        renderHTML: attributes => {
          return {
            content: attributes.content
          }
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: 'details',
        getAttrs: element => ({
          summary: element.getAttribute('summary') || '',
          content: element.getAttribute('content') || ''
        })
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const { summary, content, ...attrs } = HTMLAttributes
    return ['details', mergeAttributes(attrs, { summary, content })]
  },

  addNodeView() {
    return VueNodeViewRenderer(Component)
  }
}) 
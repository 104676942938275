<template>
    <div class="branch-picker" v-bind="$attrs">
        <button class="branch-button" :class="['branch-button', customClass]" :style="customStyle" @click="toggle">
            <div class="icon-wrapper">
                <img class="branch-icon" :src="branchIcon" alt="branch" />
            </div>
            <span class="branch-text">{{ modelValue }}</span>
            <i class="pi pi-angle-down"></i>
        </button>
    </div>
    <Popover ref="op" unstyled>
        <div class="popover-container">
            <div class="current-branch-section">
                <div class="section-header">
                    <div class="section-title">Current branch</div>
                </div>
                <div class="branch-item">
                    <div class="check-icon">
                        <i class="pi pi-check" style="color: slateblue"></i>
                    </div>
                    <div class="branch-name branch-text">{{ modelValue }}</div>
                </div>
            </div>
            <div class="divider"></div>
            <div class="other-branches-section">
                <div class="section-header">
                    <div class="section-title">Other branches</div>
                </div>
                <div class="branches-list">
                    <Listbox :options="options" filter optionLabel="label" :virtualScrollerOptions="{ itemSize: 50 }"
                        listStyle="height:250px; max-width: 100%">
                        <template #option="slotProps">
                            <div @click="selectBranch(slotProps.option)" class="flex items-center">
                                <img class="branch-icon" :src="branchIcon" alt="branch" />
                                <div class="branch-name branch-text ml-2">{{ slotProps.option }}</div>
                            </div>
                        </template>
                    </Listbox>
                </div>
                <button @click="newBranchDialog = true" class="new-branch-button">
                    <div class="plus-icon">
                        <i class="pi pi-plus" style="color: slateblue"></i>
                    </div>
                    <div class="button-text">New branch</div>
                </button>
            </div>
        </div>
    </Popover>
    <Dialog v-model:visible="newBranchDialog" modal header="Create a new branch" :style="{ width: '25rem' }">
        <div class="flex flex-col gap-[0.625rem]">
            <div class="flex flex-col gap-2">
                <label class="p-card-subtitle subtitle" for="branch">Branch name</label>
                <InputText id="branch" v-model="newBranchName
                    " class="flex-auto" placeholder="new branch name" autocomplete="off" />
            </div>
            <div class="flex justify-end gap-2">
                <Button fluid type="button" label="Add Branch" @click="makeNewBranch"></Button>
            </div>
        </div>
    </Dialog>
</template>

<script setup>
import { ref } from "vue";
import branchIcon from '@/assets/icon-branch.svg';
import { createNewBranch } from "@/plugins/devdocsBackendService";

const op = ref();
const newBranchDialog = ref(false);

const toggle = (event) => {
    op.value.toggle(event);
}

const newBranchName = ref('');



const makeNewBranch = async () => {
    await createNewBranch({ newBranchName: newBranchName.value });
    emit('update:modelValue', newBranchName.value);
    emit('branch-created', newBranchName.value);
    newBranchName.value = '';
    newBranchDialog.value = false;
}


defineProps({
    modelValue: {
        type: String,
        required: true
    },
    options: {
        type: Array,
        default: () => []
    },
    customClass: {
        type: [String, Array, Object],
        default: ''
    },
    customStyle: {
        type: [String, Object],
        default: ''
    }
});



defineOptions({
    inheritAttrs: false
});

const emit = defineEmits(['update:modelValue', 'branch-created']);

const selectBranch = (branch) => {
    emit('update:modelValue', branch);
    op.value.hide();
};
</script>

<style lang="scss">
.branch-picker {
    display: flex;
    align-items: center;
    gap: 0.3125rem;
    align-self: stretch;
}

.branch-button {
    display: flex;
    padding: 0.4375rem 0.625rem;
    align-items: center;
    gap: 0.3125rem;
    align-self: stretch;
    border-radius: 0.5rem;
    border: 1px solid #A2A1A5;
    background: rgba(162, 161, 165, 0.20);
}

.icon-wrapper {
    width: 1rem;
    height: 1rem;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.branch-icon {
    width: 1.25rem;
    height: 1.25rem;
}

.branch-text {
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.popover-container {
    // Remove fixed width/height
    min-width: 200px;
    max-width: 400px; // Prevent too wide
    padding: 15px 0;
    border-radius: 0.5rem;
    border: 1px solid rgba(122, 123, 128, 0.10);
    background: #1C1C1C;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.current-branch-section,
.other-branches-section {
    align-self: stretch;
    padding: 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
}

.current-branch-section {
    height: 47px;
}

.other-branches-section {
    height: 250px;
}

.section-header {
    align-self: stretch;
    padding: 0 0.625rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.section-title {
    flex: 1;
    color: #a2a1a5;
    font-size: 0.75rem;
    font-family: 'Inter';
    font-weight: 500;
}

.branch-item,
.branch-option {
    align-self: stretch;
    padding: 5px 0.625rem;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.625rem;
}

.branch-name {
    color: #d3d3d3;
    font-size: 0.875rem;
    font-family: 'Inter';
    font-weight: normal;
}

.divider {
    align-self: stretch;
    // height: 0;
    // border-bottom: 1px solid rgba(162, 161, 165, 0.25);
}

.branches-list {
    max-height: 300px;
    overflow-y: hidden;
    width: 100%;
}

.branch-option {
    cursor: pointer;
    padding: 5px;

    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
}

.new-branch-button {
    align-self: stretch;
    padding: 5px 0.625rem;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background: rgba(255, 255, 255, 0.10);
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.625rem;
}

.plus-icon {
    padding: 1px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.button-text {
    color: #d3d3d3;
    font-size: 0.875rem;
    font-family: 'Inter';
    font-weight: normal;
}

.branches-list {
    .p-virtualscroller-content {
        width: 100%
    }
}
</style>
<template>
  <div class="card mt-2 mb-2">
    <Button as="a" :href="githubUrl" target="_blank" severity="secondary">
        <label class="p-card-subtitle mt-2 mb-2">View on GitHub</label>
      <img class="w-[1.25rem] h-[1.25rem]" :src="githubIcon" alt="github" />
    </Button>
  </div>
  <div class="card">
    <label class="p-card-subtitle mt-2 mb-2">View and edit frontmatter</label>
    <DataTable
      class="mt-4 mb-4"
      :value="products"
      editMode="cell"
      @cell-edit-complete="onCellEditComplete"
      :pt="{
        table: {},
        column: {
          bodycell: ({ state }) => ({
            class: [{ '!py-0': state['d_editing'] }],
          }),
        },
      }"
    >
      <Column
        v-for="col of columns"
        :key="col.field"
        :field="col.field"
        :header="col.header"
        style="width: 10rem"
      >
        <template #body="{ data, field }">
          <InputText
            v-if="isEditing"
            style="width: 9.25rem"
            v-model="data[field]"
            autofocus
          />
          <span v-else @click="isEditing = true">{{ data[field] }}</span>
        </template>
      </Column>
    </DataTable>
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 0.5rem;
      "
    >
      <Button @click="addItem" outlined>Add</Button>
      <Button @click="save" outlined>Save Changes</Button>
    </div>
  </div>
  <Toast />
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useToast } from "primevue/usetoast";
import { useRoute, useRouter } from "vue-router";
import githubIcon from "@/assets/icon-github.svg";

const router = useRouter();
const route = useRoute();

const toast = useToast();

const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
});
let url = `https://github.com/${decodeURIComponent(route.query.repo)}/blob/${decodeURIComponent(route.query.branch)}/${decodeURIComponent(route.query.file)}`;
let githubUrl = ref(url)
const isEditing = ref(false);
const emit = defineEmits(["update:modelValue"]);

const products = ref([]);
const columns = ref([
  { field: "parameter", header: "Parameter" },
  { field: "input", header: "Input" },
]);

const parseFrontmatter = (text) => {
  if (!text) return [];
  const pairs = text.split(/\s+(?=[a-zA-Z_]+:)/);
  return pairs.map((pair, index) => {
    const [key, ...values] = pair.split(":");
    let value = values.join(":").trim();

    if (value.includes("[") && value.includes("]")) {
      value = value
        .replace("[", "")
        .replace("]", "")
        .split(",")
        .map((item) => item.trim())
        .join(", ");
    }

    if (value.endsWith(".md")) {
      value = value.replace(".md", "");
    }

    return {
      id: String(index + 1),
      parameter: key.trim(),
      input: value,
    };
  });
};

const convertToFrontmatter = (items) => {
  return items
    .map((item) => {
      if (!item.parameter || !item.input) return null;
      let value = item.input;
      if (value.includes(",")) {
        value = `[${value}]`;
      }
      return `${item.parameter}: ${value}`;
    })
    .filter(Boolean)
    .join("\n");
};

watch(
  () => props.modelValue,
  (newValue) => {
    products.value = parseFrontmatter(newValue);
  },
  { immediate: true }
);

const addItem = () => {
  isEditing.value = true;
  products.value.push({
    id: String(products.value.length + 1),
    parameter: "",
    input: "",
  });
};

const save = () => {
  const frontmatter = convertToFrontmatter(products.value);
  isEditing.value = false;
  emit("update:modelValue", frontmatter);
  emit("frontmatter-update", frontmatter);
  toast.add({
    severity: "info",
    summary: "Info",
    detail: "Frontmatter Added",
    life: 3000,
  });
};

const onCellEditComplete = (event) => {
  let { data, newValue, field } = event;
  if (newValue.trim().length > 0) {
    data[field] = newValue;
  } else {
    event.preventDefault();
  }
};
</script>

/**
 * Stream Services - Utilities for handling streaming responses
 * 
 * This module provides functions to process streaming responses from AI services,
 * detect incomplete responses, and handle retries for completing document modifications.
 */

/**
 * Processes a stream and detects if it has a complete response
 * @param {ReadableStream} stream - The stream to process
 * @param {Object} options - Configuration options
 * @param {Function} options.onChunk - Callback for each chunk of data (optional)
 * @param {Function} options.onComplete - Callback when stream is complete (optional)
 * @returns {Promise<{complete: boolean, content: string, responseContent: string|null}>}
 */
export async function processStream(stream, options = {}) {
  const reader = stream.getReader();
  const decoder = new TextDecoder();
  let accumulatedText = "";
  
  try {
    while (true) {
      const { done, value } = await reader.read();
      
      if (done) {
        break;
      }
      
      const decoded = decoder.decode(value, { stream: true });
      accumulatedText += decoded;
      
      if (options.onChunk) {
        options.onChunk(decoded, accumulatedText);
      }
    }
    
    // Final chunk with stream: false to flush any remaining bytes
    const lastChunk = decoder.decode();
    if (lastChunk) {
      accumulatedText += lastChunk;
      if (options.onChunk) {
        options.onChunk(lastChunk, accumulatedText);
      }
    }
    
    // Check if response is complete (has closing tag)
    const isComplete = hasCompleteResponse(accumulatedText);
    const responseContent = extractResponseContent(accumulatedText);
    
    if (options.onComplete) {
      options.onComplete(accumulatedText, isComplete, responseContent);
    }
    
    return {
      complete: isComplete,
      content: accumulatedText,
      responseContent
    };
  } catch (error) {
    console.error("Error processing stream:", error);
    throw error;
  } finally {
    reader.releaseLock();
  }
}

/**
 * Checks if a response has a complete </response> tag
 * @param {string} content - The content to check
 * @returns {boolean} - True if the response is complete
 */
export function hasCompleteResponse(content) {
  return content.includes("</response>");
}

/**
 * Extracts the content between <response> and </response> tags
 * @param {string} content - The content to extract from
 * @returns {string|null} - The extracted content or null if not found
 */
export function extractResponseContent(content) {
  const responseMatch = content.match(/<response>(.*?)<\/response>/s);
  return responseMatch ? responseMatch[1].trim() : null;
}

/**
 * Completes a document modification by retrying the request if needed
 * @param {Function} fetchFn - Function that makes the API request
 * @param {Object} requestBody - The original request body
 * @param {Object} options - Configuration options
 * @param {number} options.maxRetries - Maximum number of retries (default: 3)
 * @param {Function} options.onChunk - Callback for each chunk of data
 * @param {Function} options.onRetry - Callback when a retry is attempted
 * @param {Function} options.onComplete - Callback when document is complete
 * @returns {Promise<{complete: boolean, content: string, responseContent: string|null}>}
 */
export async function completeDocumentModification(fetchFn, requestBody, options = {}) {
  const maxRetries = options.maxRetries || 3;
  let retryCount = 0;
  let accumulatedContent = "";
  let isComplete = false;
  let responseContent = null;
  
  // Clone the request body to avoid modifying the original
  let currentRequestBody = { ...requestBody };
  console.log("this is the current request body", currentRequestBody);
  console.log(options);
  
  while (!isComplete && retryCount <= maxRetries) {
    // If this is a retry, modify the request to continue from where we left off
    if (retryCount > 0) {
      // Add context about previous attempt
      currentRequestBody.message = `${currentRequestBody.message} 
      
The previous response was incomplete. Please continue from where you left off and complete the document modification. 
Previous partial response: ${responseContent || "No valid response content found"}.

Please respond with the COMPLETE updated documentation in <response> </response> tags.`;
      
      if (options.onRetry) {
        options.onRetry(retryCount, currentRequestBody);
      }
    }
    
    try {
      const response = await fetchFn(currentRequestBody);
      
      // Process the stream
      const result = await processStream(response.body, {
        onChunk: options.onChunk,
        onComplete: (content, complete, extractedContent) => {
          if (options.onComplete) {
            options.onComplete(content, complete, extractedContent, retryCount);
          }
        }
      });
      
      isComplete = result.complete;
      responseContent = result.responseContent;
      accumulatedContent += result.content;
      
      if (!isComplete) {
        retryCount++;
      }
    } catch (error) {
      console.error(`Error during attempt ${retryCount}:`, error);
      retryCount++;
      
      // Wait a bit before retrying to avoid overwhelming the server
      await new Promise(resolve => setTimeout(resolve, 1000));
    }
  }
  
  return {
    complete: isComplete,
    content: accumulatedContent,
    responseContent,
    retryCount
  };
}

/**
 * Creates a fetch function for the AI messages endpoint
 * @param {Object} authInstance - The authentication instance
 * @param {Headers} headers - The headers to use for the request
 * @returns {Function} - A function that makes the API request
 */
export function createAiMessagesFetchFn(authInstance, headers) {
  return async (requestBody) => {
    const url = await authInstance.getBaseUrl();
    const raw = JSON.stringify(requestBody);
    
    const requestOptions = {
      method: 'POST',
      headers,
      body: raw,
      redirect: 'follow'
    };
    
    return fetch(`${url}/ai/messages`, requestOptions);
  };
}

export default {
  processStream,
  hasCompleteResponse,
  extractResponseContent,
  completeDocumentModification,
  createAiMessagesFetchFn
}; 
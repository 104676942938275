<template>
    <div>
        <div class="flex flex-col p-fluid gap-[1.25rem] mt-[1.25rem]">
            <div class="flex items-center justify-between mb-4">
                <h2 class="text-xl font-medium">Select Repository</h2>
                <div class="flex gap-2">
                    <Button 
                        icon="pi pi-refresh" 
                        @click="refreshData" 
                        label="Run Again" 
                        class="p-button-secondary" 
                        :loading="loading"
                    />
                    <Button 
                        icon="pi pi-trash" 
                        @click="clearData" 
                        label="Clear" 
                        class="p-button-secondary" 
                    />
                </div>
            </div>
            <div v-if="hasCachedContent" class="mb-4">
                <DocumentCarousel
                    v-model:documents="cachedDocument"
                    :branch="branch"
                    :owner="documentationRepo?.owner"
                    :repo="documentationRepo?.repo"
                    action="generate_complete_docs"
                    @accept="handleAccept"
                    @revert="handleRevert"
                    @discard="handleDiscard"
                    @clear-cached="cachedDocument = []"
                    @load-cached="handleLoadCached"
                />
            </div>
            <div class="repo-select-container">
                <Dropdown v-model="selectedRepo" :options="repoOptions" optionLabel="label"
                    placeholder="Select a Repository" class="w-full !bg-[#1C1C1C]" @change="handleRepoChange" />
            </div>
            <div class="flex justify-end">
                <Button label="Generate Documentation" @click="generateCompleteDocs" class="p-button-primary" :loading="loading" />
            </div>

            <div v-if="loading" class="flex flex-col gap-4">
                <ProgressBar v-if="loading" mode="indeterminate" class="mb-4" />
                <StreamingPreview v-if="streamingContent" :content="streamingContent" />
            </div>

            <Accordion v-model:activeIndex="activeAccordionIndex" class="mb-4">
                <AccordionTab header="Select Documentation Files" :disabled="!loading && !tableItems?.length">
                    <div v-if="tableItems?.length">
                        <SelectButton class="mt-2 mb-4" v-model="selectedAudience" :options="audienceOptions"
                            aria-labelledby="audience-selector" />
                        <DataTable :value="tableItems" v-model:selection="selectedItems" :paginator="true" :rows="5"
                            currentPageReportTemplate="Displaying {first} to {last} of {totalRecords}">
                            <template #header>
                                <div class="flex items-center justify-between w-full">
                                    <div>
                                        Select the documentation files to generate
                                    </div>
                                    <InputText v-model="searchQuery" placeholder="Search..." @input="onSearch" />
                                </div>
                            </template>
                            <template #paginatorstart>
                                <div style="display: flex;align-items: center;flex-direction: row; min-width: 500px">
                                    <Button @click="handleGeneration" :disabled="!selectedItems.length">
                                        Generate Selected Docs
                                    </Button>
                                </div>
                            </template>
                            <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                            <Column field="file_name" header="File Name"></Column>
                            <Column field="generation_prompt" header="Generation Prompt"></Column>
                        </DataTable>
                    </div>
                </AccordionTab>

                <AccordionTab header="Documentation Generation" :disabled="!loading && !streamingResponses.length">
                    <div class="flex flex-col gap-4">
                        <ProgressItemsBar :items="selectedItems" :processedItems="processedItems" itemType="docs" />
                        
                        <!-- Show document carousel after generation -->
                        <div v-if="streamingResponses.length" class="flex flex-col gap-4 mt-4">
                            <DocumentCarousel v-model:documents="streamingResponses" :branch="branch"
                                :owner="documentationRepo?.owner" :repo="documentationRepo?.repo" 
                                action="generate_complete_docs"
                                @accept="handleAccept" @revert="handleRevert" @discard="handleDiscard" @clear-cached="streamingResponses = []" />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>

            <div v-if="streamingError" class="error-message mt-4 p-4 bg-red-100 text-red-700 rounded">
                {{ streamingError }}
            </div>
        </div>
    </div>
</template>

<script>
import { getOwnerAndRepo, getCodebaseRepos } from '../plugins/devdocsBackendService.js';
import { Skeleton } from 'primevue/skeleton';
import { Dropdown } from 'primevue/dropdown';
import { Button } from 'primevue/button';
import { DataTable } from 'primevue/datatable';
import { Column } from 'primevue/column';
import { SelectButton } from 'primevue/selectbutton';
import { ScrollPanel } from 'primevue/scrollpanel';
import { InputText } from 'primevue/inputtext';
import ProgressItemsBar from './EditorComponents/ProgressItemsBar.vue';
import StreamingPreview from './EditorComponents/StreamingPreview.vue';
import DocumentCarousel from './EditorComponents/DocumentCarousel.vue';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import indexDbService from '../plugins/indexDbService.js';

export default {
    components: {
        Dropdown,
        Button,
        DataTable,
        Column,
        SelectButton,
        ScrollPanel,
        InputText,
        ProgressItemsBar,
        Skeleton,
        StreamingPreview,
        DocumentCarousel,
        Accordion,
        AccordionTab,
    },
    data() {
        return {
            activeAccordionIndex: 0,
            selectedRepo: null,
            repoOptions: [],
            loading: false,
            docsInfo: null,
            selectedItems: [],
            processedItems: 0,
            selectedAudience: 'Internal Engineers',
            audienceOptions: ['Internal Engineers', 'External Users'],
            searchQuery: '',
            isLoadedFromCache: false,
            streamingContent: '',
            streamingResponses: [],
            documentationRepo: null,
            streamingError: null,
            hasCachedContent: false,
            cachedDocument: null,
        };
    },
    computed: {
        tableItems() {
            if (!this.docsInfo) return [];

            const items = this.selectedAudience === 'Internal Engineers'
                ? this.docsInfo.contributors_internal_engineers
                : this.docsInfo.external_users_customers;

            if (!this.searchQuery) return items;

            const query = this.searchQuery.toLowerCase();
            return items.filter(item =>
                item.file_name.toLowerCase().includes(query) ||
                item.generation_prompt.toLowerCase().includes(query)
            );
        }
    },
    async mounted() {
        this.documentationRepo = await getOwnerAndRepo();
        await this.loadRepos();
        await this.checkExistingData();
        await this.checkCachedContent();
    },
    methods: {
        async loadRepos() {
            try {
                let repos = await getCodebaseRepos()
                this.repoOptions = repos.map(repo => ({
                    label: repo.full_name,
                    value: { owner: repo.owner, repo: repo.name }
                }))
            } catch (error) {
                console.error('Error loading repos:', error);
            }
        },
        handleRepoChange(event) {
            if (this.selectedRepo) {
                const { owner, repo } = this.selectedRepo.value;
                this.$emit('repo-selected', { owner, repo });
                // Save to IndexDB when repo changes
                indexDbService.saveTableData('completeDocs', {
                    docsInfo: this.docsInfo,
                    selectedRepo: this.selectedRepo
                });
            }
        },
        async checkExistingData() {
            const cachedData = await indexDbService.getTableData('completeDocs');
            if (cachedData) {
                this.docsInfo = cachedData.docsInfo;
                this.isLoadedFromCache = true;
                if (cachedData.selectedRepo) {
                    this.selectedRepo = cachedData.selectedRepo;
                    // Emit repo-selected event to ensure proper initialization
                    const { owner, repo } = cachedData.selectedRepo.value;
                    this.$emit('repo-selected', { owner, repo });
                }
            }
        },
        async checkCachedContent() {
            try {
                const cachedData = await indexDbService.getTableData('generate_complete_docs_cached_docs');
                if (cachedData) {
                    this.hasCachedContent = true;
                    this.cachedDocument = cachedData.result
                }
            } catch (e) {
                console.warn('Error checking cached content:', e);
            }
        },
        async handleLoadCached(cache) {
            try {
                // Set the content from the cached data
                this.docsInfo = cache.result.docsInfo;
                this.selectedRepo = cache.result.selectedRepo;
                this.loading = true;
                this.isLoadedFromCache = true;
                
                // Update the UI to show the loaded content
                this.$nextTick(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Loaded',
                        detail: 'Successfully loaded cached content',
                        life: 3000
                    });
                });
            } catch (e) {
                console.error('Error loading cached content:', e);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to load cached content',
                    life: 3000
                });
            }
        },
        async clearData() {
            this.docsInfo = null;
            this.selectedItems = [];
            this.processedItems = 0;
            this.streamingContent = '';
            this.streamingResponses = [];
            this.streamingError = null;
            this.isLoadedFromCache = false;
            this.searchQuery = '';
            this.selectedRepo = null;
            await indexDbService.clearStore();
        },
        async refreshData() {
            this.docsInfo = null;
            this.isLoadedFromCache = false;
            this.searchQuery = '';
            await this.generateCompleteDocs();
        },
        onSearch(event) {
            this.searchQuery = event.target.value;
        },
        async handleGeneration() {
            this.activeAccordionIndex = 1;
            
            if (!this.selectedRepo) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Please select a repository first',
                    life: 3000
                });
                return;
            }

            this.loading = true;
            this.processedItems = 0;
            this.streamingContent = '';
            this.streamingResponses = [];

            try {
                const [org, token, url] = await Promise.all([
                    this.$authInstance.getOrg(),
                    this.$authInstance.getToken(),
                    this.$authInstance.getBaseUrl()
                ]);

                if (!token || !url) {
                    throw new Error("Missing authentication information");
                }

                // Process items sequentially to maintain order
                for (const [index, item] of this.selectedItems.entries()) {
                    try {
                        const message = `Generate documentation for ${item.file_name} with the following requirements: ${item.generation_prompt}. Please respond with the documentation in <response> </response> tags.`;
                        let githubRepository = `${this.selectedRepo.value.owner}/${this.selectedRepo.value.repo}`
                        let codeObjects = {}
                        codeObjects[githubRepository] = item.context_files
                        const requestBody = {
                            message,
                            codeObjects,
                            githubRepository: `${this.selectedRepo.value.owner}/${this.selectedRepo.value.repo}`,
                            codeFiles: item.context_files || [],
                            returnStream: true
                        };

                        const response = await fetch(
                            `${url}/ai/messages`,
                            {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`
                                },
                                body: JSON.stringify(requestBody)
                            }
                        );

                        if (!response.ok) {
                            throw new Error(`Failed to generate documentation for ${item.file_name}`);
                        }

                        const reader = response.body.getReader();
                        const decoder = new TextDecoder();
                        let accumulatedText = '';

                        let streamingResponse = {
                            id: Date.now() + index,
                            content: '',
                            fileName: `docs/${item.file_name}`,
                            complete: false
                        };

                        while (true) {
                            const { done, value } = await reader.read();
                            if (done) break;

                            const decoded = decoder.decode(value);
                            accumulatedText += decoded;
                            
                            // Update streaming content in real-time
                            this.streamingContent = accumulatedText;

                            try {
                                const data = JSON.parse(decoded);
                                if (data.type === 'status') {
                                    console.log(`Status update for ${item.file_name}:`, data.message);
                                } else if (data.type === 'error') {
                                    throw new Error(data.message);
                                }
                            } catch (e) {
                                // Not JSON, continue accumulating text
                            }
                        }

                        // Process final content
                        const responseMatch = accumulatedText.match(/<response>(.*?)<\/response>/s);
                        if (responseMatch) {
                            streamingResponse.content = responseMatch[1].trim();
                            streamingResponse.complete = true;
                            this.streamingResponses.push(streamingResponse);
                        }
                        
                        this.processedItems++;
                        this.streamingContent = ''; // Clear for next item
                        
                    } catch (error) {
                        console.error(`Error processing ${item.file_name}:`, error);
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: `Failed to generate documentation for ${item.file_name}: ${error.message}`,
                            life: 3000
                        });
                    }
                }

            } catch (error) {
                console.error('Error in handleGeneration:', error);
                this.$toast.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: error.message,
                    life: 3000
                });
            } finally {
                this.loading = false;
            }
        },
        handleStreamResponse(data, item) {
            switch (data.type) {
                case 'status':
                    console.log("Status update:", data.message);
                    break;

                case 'complete_docs_stream':
                    // const streamingResponse = {
                    //     id: Date.now(),
                    //     content: data.data,
                    //     fileName: `docs/${item.file_name}`,
                    //     complete: false
                    // };
                    console.log("complete_docs_stream:", data);
                    const streamingResponse = {
                        id: Date.now(),
                        content: data.data,
                        fileName: `docs/${item.file_name}`,
                        complete: false
                    };
                    this.streamingResponses.push(streamingResponse);
                    break;

                case 'completed':
                    if (this.streamingResponses.length > 0) {
                        const lastResponse = this.streamingResponses[this.streamingResponses.length - 1];
                        lastResponse.complete = true;
                    }
                    this.processedItems++;
                    break;

                case 'error':
                    console.error(`Error in documentation generation:`, data.message);
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: data.message,
                        life: 3000
                    });
                    break;
            }
        },
        async handleAccept(streamingResponse) {
            try {
                let publishedBranch = localStorage.getItem('draftBranch') || 'main';
                const result = {
                    content: streamingResponse.content,
                    path: streamingResponse.fileName,
                    branch: publishedBranch
                };

                // Update localStorage with new files
                const existingFiles = JSON.parse(localStorage.getItem('newFiles') || '[]');
                existingFiles.push(streamingResponse.fileName);
                localStorage.setItem('newFiles', JSON.stringify(existingFiles));

                // Remove this response from the carousel
                this.streamingResponses = this.streamingResponses.filter(r => r.id !== streamingResponse.id);

                this.$emit('draft', result);
            } catch (e) {
                console.error('Error accepting content:', e);
            }
        },
        handleRevert(streamingResponse) {
            this.streamingResponses = this.streamingResponses.filter(r => r.id !== streamingResponse.id);
        },
        handleDiscard(streamingResponse) {
            this.streamingResponses = this.streamingResponses.filter(r => r.id !== streamingResponse.id);
        },
        async generateCompleteDocs() {
            // Set activeAccordionIndex to show generation tab
            this.activeAccordionIndex = 0;
            
            this.loading = true;
            this.streamingContent = '';
            this.streamingResponses = [];

            try {
                let org = await this.$authInstance.getOrg();
                let token = await this.$authInstance.getToken();
                let url = await this.$authInstance.getUrl();

                if (!token || !url) {
                    throw new Error("Missing authentication information");
                }

                let selectedRepo = this.selectedRepo.value;
                let { owner, repo } = await getOwnerAndRepo();
                const [encodedOwner, encodedRepo] = [owner, repo].map(encodeURIComponent);

                const response = await fetch(
                    `${url}/companies/${org}/owners/${encodedOwner}/repos/${encodedRepo}/complete_user_docs`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({ codebase: selectedRepo, returnStream: true })
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to generate complete documentation');
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder();
                let buffer = '';

                while (true) {
                    const { done, value } = await reader.read();
                    
                    if (done) {
                        // Only process remaining buffer if it contains unprocessed data
                        if (buffer.trim()) {
                            try {
                                console.log("data:", data);
                                const data = JSON.parse(buffer);
                                // Check if this is new data that wasn't processed in the main loop
                                if (!this.docsInfo) {
                                    await this.handleCompleteDocsResponse(data);
                                }
                            } catch (e) {
                                console.error('Error parsing final buffer:', e);
                            }
                        }
                        break;
                    }

                    const decoded = decoder.decode(value, { stream: true });
                    console.log("decoded:", decoded);
                    this.streamingContent += decoded;
                    buffer += decoded;

                    const lines = buffer.split('\n');
                    buffer = lines.pop() || '';

                    for (const line of lines) {
                        if (line.trim()) {
                            try {
                                console.log("line:", line);
                                const data = JSON.parse(line);
                                
                                // Handle buffer data if present
                                if (data.type === 'complete_docs_stream' && data.data?.type === 'Buffer') {
                                    // Convert Buffer data to string and parse as JSON
                                    const bufferString = new TextDecoder().decode(new Uint8Array(data.data.data));
                                    try {
                                        const parsedData = JSON.parse(bufferString);
                                        await this.handleCompleteDocsResponse({
                                            type: 'complete_docs_stream',
                                            data: parsedData
                                        });
                                    } catch (e) {
                                        console.error('Error parsing buffer data:', e, bufferString);
                                        // If it's not valid JSON, just use the string as is
                                        await this.handleCompleteDocsResponse({
                                            type: 'complete_docs_stream',
                                            data: bufferString
                                        });
                                    }
                                } else {
                                    // Handle non-buffer data normally
                                    await this.handleCompleteDocsResponse(data);
                                }
                            } catch (e) {
                                console.error('Error parsing JSON line:', e);
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error generating complete docs:', error);
                this.streamingError = error.message;
            } finally {
                this.loading = false;
            }
        },
        async handleCompleteDocsResponse(data) {
            try {
                
                let completeDocsJSON;
                
                // Check if the response is an error
                if (data.error) {
                    this.streamingError = data.error;
                    return;
                }

                // Direct assignment of documentation data
                if(data?.data) completeDocsJSON = data?.data;
                this.docsInfo = completeDocsJSON;
                
                // Save to IndexDB with selectedRepo
                await indexDbService.saveTableData('completeDocs', {
                    docsInfo: completeDocsJSON,
                    selectedRepo: this.selectedRepo
                });
            } catch (error) {
                console.error('Error processing response:', error);
                this.streamingError = 'Failed to process documentation data';
            }
        },
    }
};
</script>

<style>
.repo-select-container {
    max-width: 600px;
    margin: 0 auto;
}

.p-menubar-root-list {
    padding: 0;
    display: flex
;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    align-content: flex-start;
    align-items: flex-start;
}

.repo-select-container label {
    color: #E0E0E0;
    font-weight: 500;
    display: block;
    margin-bottom: 0.5rem;
}

:deep(.p-dropdown) {
    background: #1C1C1C;
    border: 1px solid #333;
    border-radius: 8px;
}

:deep(.p-dropdown:hover) {
    border-color: #666;
}

:deep(.p-dropdown-label) {
    color: #E0E0E0;
}

:deep(.p-dropdown-trigger) {
    color: #E0E0E0;
}

:deep(.p-dropdown-panel) {
    background: #1C1C1C;
    border: 1px solid #333;
    border-radius: 8px;
}

:deep(.p-dropdown-item) {
    color: #E0E0E0;
}

:deep(.p-dropdown-item:hover) {
    background: #333;
}

:deep(.p-datatable) {
    background: #1C1C1C;
    border-radius: 8px;
}

:deep(.p-datatable .p-datatable-header) {
    background: #1C1C1C;
    border-color: #333;
}

:deep(.p-datatable .p-datatable-thead > tr > th) {
    background: #1C1C1C;
    color: #E0E0E0;
    border-color: #333;
}

:deep(.p-datatable .p-datatable-tbody > tr) {
    background: #1C1C1C;
    color: #E0E0E0;
    border-color: #333;
}

:deep(.p-datatable .p-datatable-tbody > tr:hover) {
    background: #333;
}

:deep(.p-selectbutton) {
    background: transparent;
    padding: 0;
    border: none;
    display: inline-flex;
    border-radius: 8px;
    overflow: hidden;
}

:deep(.p-selectbutton .p-button) {
    background: #1C1C1C;
    border: 1px solid #333;
    color: #999;
    padding: 0.75rem 1.5rem;
    font-weight: 500;
    transition: all 0.2s ease;
    margin: 0;
}

:deep(.p-selectbutton .p-button:first-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

:deep(.p-selectbutton .p-button:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
}

:deep(.p-selectbutton .p-button.p-highlight) {
    background: #2563eb;
    border-color: #2563eb;
    color: white;
    font-weight: 500;
}

:deep(.p-selectbutton .p-button:not(.p-highlight):hover) {
    background: #262626;
    color: #E0E0E0;
}

:deep(.p-selectbutton .p-button:focus) {
    box-shadow: none;
    outline: 2px solid #3b82f6;
    outline-offset: 2px;
}

.error-message {
    margin: 20px 0;
    padding: 10px;
    background-color: #ffebee;
    color: #d32f2f;
    border-radius: 4px;
}
</style>
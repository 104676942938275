<template>
    <div class="card flex justify-center">
        <Toast />

        <label class="mb-4 p-card-subtitle">Add the power of GitHub Copilot, interactive codeblocks, and in editing docs in your repo</label>
        <div class="flex flex-col gap-4 w-full sm:w-96">
            <div class="flex flex-col gap-1">
                <label class="mb-4 p-card-subtitle">Add a GitHub Client ID</label>
                <InputText 
                    v-model="credentials.clientId" 
                    :invalid="submitted && !credentials.clientId"
                    type="text" 
                    placeholder="GitHub Client ID" 
                />
                <small v-if="submitted && !credentials.clientId" class="text-red-500">Client ID is required</small>
            </div>
            <div class="flex flex-col gap-1">
                <label class="mb-4 p-card-subtitle">Add a GitHub Client Secret</label>
                <InputText 
                    v-model="credentials.clientSecret" 
                    :invalid="submitted && !credentials.clientSecret"
                    type="password" 
                    placeholder="GitHub Client Secret" 
                />
                <small v-if="submitted && !credentials.clientSecret" class="text-red-500">Client Secret is required</small>
            </div>
            <div class="flex gap-2">
                <Button type="button" severity="primary" label="Save Configuration" @click="saveConfig" />
                <Button type="button" severity="danger" label="Delete Configuration" @click="deleteConfig" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useToast } from 'primevue/usetoast';

const toast = useToast();
const submitted = ref(false);
const credentials = reactive({
    clientId: '',
    clientSecret: ''
});

const fetchConfig = async () => {
    try {
        const response = await fetch('/api/github-config');
        const data = await response.json();
        credentials.clientId = data.clientId;
        credentials.clientSecret = data.clientSecret;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Failed to fetch configuration',
            life: 3000
        });
    }
};

const saveConfig = async () => {
    submitted.value = true;
    
    if (!credentials.clientId || !credentials.clientSecret) {
        toast.add({
            severity: 'error',
            summary: 'Please fill in all required fields',
            life: 3000
        });
        return;
    }

    try {
        await fetch('/api/github-config', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(credentials)
        });
        
        toast.add({
            severity: 'success',
            summary: 'Configuration saved successfully',
            life: 3000
        });
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Failed to save configuration',
            life: 3000
        });
    }
};

const deleteConfig = async () => {
    try {
        await fetch('/api/github-config', {
            method: 'DELETE'
        });
        
        credentials.clientId = '';
        credentials.clientSecret = '';
        submitted.value = false;
        
        toast.add({
            severity: 'success',
            summary: 'Configuration deleted successfully',
            life: 3000
        });
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Failed to delete configuration',
            life: 3000
        });
    }
};

onMounted(() => {
    fetchConfig();
});
</script>
